import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import { useEffect, useRef, useState } from "react";
import ProfileTab from "./components/ProfileTab";
import SkillsMatchTab from "./components/SkillsMatchTab";
import TrackerTab from "./components/TrackerTab";
import ApplicationTab from "./components/ApplicationTab";
import MessagesTab from "./components/MessagesTab";
import {
  jobAddTracker,
  jobGetTracker,
  jobUpdateApplicant,
} from "../../../endpoints/job.service";
import { useSnackbar } from "notistack";

const tabs = [
  {
    title: "View Profile",
    value: 0,
  },
  {
    title: "View skills match",
    value: 1,
  },
  {
    title: "View Tracker",
    value: 2,
  },
  {
    title: "View Application",
    value: 3,
  },
  {
    title: "Messages",
    value: 4,
  },
];

const JobApplicantDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { item } = location.state || {};
  const [activeTab, setActiveTab] = useState(3);
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trackerList, setTrackerList] = useState();
  const hasFetched = useRef(false);

  useEffect(() => {
    if (item?._id && !hasFetched.current) {
      hasFetched.current = true;
      addTrackerView(item._id, "Application Viewed");
      fetchTracker();
    }
  }, [item]);

  const fetchTracker = async () => {
    try {
      const { data, success } = await jobGetTracker(item?._id);
      if (success && data) {
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setTrackerList(sortedData);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Failed to fetch tracker data", {
        variant: "error",
      });
    }
  };

  const addTrackerView = async (jobApplicationId, status) => {
    try {
      await jobAddTracker({ jobApplicationId, status });
      await jobUpdateApplicant({
        jobApplicantId: jobApplicationId,
      });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const handleJobAction = async (status) => {
    if (!item?._id) return;

    if (status === "Submit Offer") setIsSubmitting(true);
    try {
      const payload = {
        jobApplicationId: item._id,
        status,
      };
      const { success } = await jobAddTracker(payload);
      if (success) {
        enqueueSnackbar(`Tracker updated with "${status}" status!`, {
          variant: "success",
        });
        fetchTracker();
      } else if (!success) {
        enqueueSnackbar("Tracker already exists", {
          variant: "info",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Failed to update status", {
        variant: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitOffer = () => {
    handleJobAction("Submit Offer");
  };

  const handleDropdownChange = (e) => {
    const newStatus = e.target.value;
    setSelectedOption(newStatus);
    handleJobAction(newStatus);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <ProfileTab profileDetails={item} />;
      case 1:
        return <SkillsMatchTab applicationDetails={item} />;
      case 2:
        return (
          <TrackerTab applicationDetails={item} trackerList={trackerList} />
        );
      case 3:
        return <ApplicationTab applicationDetails={item} />;
      case 4:
        return <MessagesTab applicationDetails={item} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow px-6 py-2 overflow-hidden">
      <button
        onClick={() => navigate(`/job/${item.jobId}/applicants`)}
        className="flex items-center self-start my-3"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back
        </label>
      </button>

      {/* Tabs container */}
      <div className="flex items-center border-b border-gray-300 gap-16 mt-5 pb-2 pl-4">
        {tabs?.map((tab) => {
          const isActive = tab?.value === activeTab;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className={`flex items-center ${
                isActive ? "border-b border-primary" : ""
              }`}
            >
              <span
                className={`text-xs pb-0.5 ${
                  isActive ? "text-primary font-medium" : "text-gray-500"
                }`}
              >
                {tab?.title}
              </span>
            </button>
          );
        })}
      </div>

      <div className="bg-[#F1F5F9] my-3 rounded-md p-5 flex justify-between">
        <div className="flex items-center gap-20">
          <span className={"font-semibold text-sm"}>
            {item?.jobDetails?.title ?? "-"}
          </span>
          <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded flex">
            {item?.jobDetails?.refNo ?? "-"}
          </span>
        </div>
        <div className="flex items-center gap-5">
          <button
            onClick={handleSubmitOffer}
            className={`bg-white text-primary font-medium text-xs py-1.5 px-8 rounded flex border cursor-pointer ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit offer"}
          </button>
          <select
            value={selectedOption}
            onChange={handleDropdownChange}
            className="border py-1.5 px-3 rounded text-xs outline-none cursor-pointer"
          >
            <option value="" disabled>
              Actions
            </option>
            <option value="Shortlist">Shortlist</option>
            <option value="Interview Scheduled">Interview Scheduled</option>
            <option value="Decline">Decline</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col flex-1 w-full overflow-y-auto no-scrollbar">
        {renderContent()}
      </div>
    </div>
  );
};

export default JobApplicantDetail;

import { useEffect, useState } from "react";
import {
  jobMessage,
  jobMessageList,
  updateMessageSeen,
} from "../../../../../endpoints/job.service";
import LoadingAnimation from "../../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../../utils/theme";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import DoubleCheck from "../../../../../assets/svg/DoubleCheck";

const MessagesTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const [isComposing, setIsComposing] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const pollingInterval = 3000;

  const fetchMessages = async () => {
    try {
      const jobApplicationId = applicationDetails?._id;
      const receiverId = user._id;

      const { success, data } = await jobMessageList(
        receiverId,
        jobApplicationId
      );

      if (success) {
        setMessages(data);
      }
    } catch (error) {
      enqueueSnackbar(error + " Error fetching messages", { variant: "error" });
    }
  };

  useEffect(() => {
    if (!applicationDetails) return;

    fetchMessages();

    // const intervalId = setInterval(() => {
    //   fetchMessages();
    // }, pollingInterval);

    // return () => clearInterval(intervalId);
  }, [applicationDetails]);

  useEffect(() => {
    if (messages.length > 0) {
      markMessagesAsSeen();
    }
  }, [messages]);

  const handleComposeClick = () => {
    setIsComposing(true);
  };

  const handleCloseClick = () => {
    setIsComposing(false);
    setMessage("");
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    try {
      setIsLoading(true);
      await jobMessage({
        jobApplicationId: applicationDetails?._id,
        message,
        receivedBy: applicationDetails?.candidateId,
      });
      setMessage("");
      setIsComposing(false);
      enqueueSnackbar("Message sent successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error + "Error sending message", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const markMessagesAsSeen = async () => {
    if (!messages[0]?.messageData?.length) return;

    const unseenMessageIds = messages[0].messageData
      .filter((msg) => !msg.seen)
      .map((msg) => msg.messageId);

    if (unseenMessageIds.length === 0) return;

    try {
      await updateMessageSeen({ messagesIds: unseenMessageIds });
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-9">
        <button
          className={`text-xs text-primary font-medium py-2 px-9 border border-[#ECECEC] rounded ${
            isComposing ? "bg-[#F7F7F7]" : "bg-[#F1F5F9]"
          }`}
          onClick={handleComposeClick}
          disabled={isComposing}
        >
          Componse
        </button>
        <p className="text-sm font-semibold text-primary">
          To:{" "}
          {applicationDetails?.candidateDetails?.firstName +
            " " +
            applicationDetails?.candidateDetails?.lastName ?? "-"}
        </p>
      </div>

      {isComposing && (
        <button
          className="text-primary text-xs font-medium flex justify-self-end mr-36"
          onClick={handleCloseClick}
        >
          Close
        </button>
      )}
      <div
        className={`bg-[#FAFAFA] py-7 px-16 rounded ${
          isComposing ? "mt-2" : "mt-6"
        } space-y-8 ${isComposing && "mx-28 pt-8 px-6"}`}
      >
        {!isComposing ? (
          messages[0]?.messageData?.length ? (
            messages[0]?.messageData?.map((item) => {
              const isSentByCurrentUser = item.createdBy === user._id;
              return (
                <div className="flex flex-col gap-2 mb-5" key={item._id}>
                  <p className="text-[13px] font-semibold text-primary">
                    {item.createdName}
                  </p>
                  <div className="flex flex-col gap-0.5">
                    <div className="flex items-end gap-1.5">
                      <p className="text-xs font-medium text-light-black">
                        {moment(item?.createdAt).format("h:mm a DD MMMM YYYY")}
                      </p>
                      {isSentByCurrentUser && item.seen && <DoubleCheck />}
                    </div>
                    <p
                      className={`text-xs font-medium text-light-black py-3 px-5 bg-[#F1F5F9] rounded`}
                    >
                      {item.message}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-sm text-center">No messages available</p>
          )
        ) : (
          <div className="flex flex-col gap-4">
            <textarea
              className="p-4 bg-white rounded text-xs font-medium border border-[#ECECEC]"
              rows="18"
              placeholder="Type your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button
              className="text-xs text-primary font-medium py-2 px-4 border border-[#ECECEC] rounded bg-[#F1F5F9] self-end flex items-center disabled:cursor-not-allowed"
              onClick={handleSendMessage}
              disabled={isLoading || !message.trim()}
            >
              {isLoading && (
                <LoadingAnimation
                  color={colors.PrimaryBlack}
                  className={"w-4 h-4"}
                />
              )}
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesTab;

import EmailOpenIcon from "../../assets/svg/EmailOpenIcon";
import EmailSuccess from "../../assets/svg/EmailSuccess";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationCodeInput from "../../components/OtpInputBox/VerificationCodeInput";
import {
  resendOtpVerifyEmail,
  verifyEmail,
} from "../../endpoints/auth.service";
import { useSnackbar } from "notistack";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showInputBox, setShowInputBox] = useState(!!state?.isSignup);
  const [verifiedSuccess, setVerifiedSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmitButton = async () => {
    if (loading) return;

    setLoading(true);
    setError("");

    try {
      if (verifiedSuccess) {
        if (state?.user?.userType === 3) {
          navigate("/add-referral", { replace: true });
        } else {
          navigate(state?.userType ? "/login-ambassador" : "/login", {
            replace: true,
          });
        }
        return;
      }

      if (!showInputBox) {
        await resendOtpVerifyEmail(state?.email);
        setShowInputBox(true);
      } else {
        const response = await verifyEmail({
          email: state?.email,
          otpCode: code,
        });
        if (response.success) {
          enqueueSnackbar("User verified successfully", { variant: "success" });
          setVerifiedSuccess(true);
        } else {
          setError(
            response.message || "Verification failed. Please try again."
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.isSignup) {
      setShowInputBox(true);
    }
  }, [state]);

  return (
    <div className="flex flex-col items-center my-20 border rounded-xl px-10 w-max py-10 mx-auto bg-white">
      <span className="font-didact font-semibold text-3xl">
        {verifiedSuccess ? "Verified!!" : "Email Verification"}
      </span>
      <span className="font-montserrat text-light-text font-medium mt-3">
        {verifiedSuccess
          ? "Your account has been submitted for approval."
          : "Thanks for registering with us."}
      </span>
      {showInputBox && !verifiedSuccess ? null : verifiedSuccess ? (
        <EmailSuccess className={"w-14 h-14 my-4"} />
      ) : (
        <EmailOpenIcon className={"w-14 h-14 my-4"} />
      )}

      {showInputBox && !verifiedSuccess ? (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          A verification code has been sent to your email address
          <span className="font-semibold"> {state?.email}</span>, please check
          your inbox and insert the code to verify your email.
        </span>
      ) : verifiedSuccess ? (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          Thank you, your email has been verified. Your account is now active.
          Please use the link below to login to your account.
        </span>
      ) : (
        <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">
          You’re almost ready to get started. Please click on the button below
          to verify your email address.
        </span>
      )}

      {showInputBox && !verifiedSuccess && (
        <div className="mt-3">
          <VerificationCodeInput
            loading={loading}
            onChange={setCode}
            length={6}
          />
        </div>
      )}

      {error && (
        <span className="font-montserrat text-red-500 font-medium text-sm mt-2">
          {error}
        </span>
      )}

      <button
        disabled={(showInputBox && code?.length !== 6) || loading}
        onClick={handleSubmitButton}
        className={`font-montserrat font-medium text-sm text-white ${
          state?.userType ? "bg-primary" : "bg-secondary"
        } rounded-full py-3 w-[28rem] mt-3 ${
          (showInputBox && code?.length !== 6) || loading
            ? "opacity-50"
            : "opacity-100"
        }`}
      >
        {loading
          ? "Processing..."
          : showInputBox && !verifiedSuccess
          ? "Verify"
          : verifiedSuccess
          ? state?.user?.userType === 3
            ? "Proceed to your page"
            : "Login to your account"
          : "Verify your email address"}
      </button>

      {!verifiedSuccess && (
        <span className="font-montserrat mt-2.5 text-light-text font-medium text-xs">
          Didn't receive {showInputBox ? "the code" : "verification email"}?{" "}
          <span
            className="text-secondary cursor-pointer"
            onClick={handleSubmitButton}
          >
            Resend {showInputBox ? "code" : ""}
          </span>
        </span>
      )}
    </div>
  );
};

export default EmailVerification;

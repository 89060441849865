import { useEffect, useRef, useState } from "react";
import {
  jobMessage,
  jobMessageList,
  updateMessageSeen,
} from "../../../../endpoints/job.service";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import PaperSendIcon from "../../../../assets/svg/PaperSendIcon";
import { socket } from "../../../../App";

const MessagesTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const [isComposing, setIsComposing] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const pollingInterval = 3000;

  const messagesContainerRef = useRef(null);
  const lastMessageCountRef = useRef(0);

  const scrollToBottom = (smooth = true) => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };

  const fetchMessages = async (shouldScroll = false) => {
    try {
      if (!selectedContact) return;

      const receiverId = selectedContact.id;

      const params = { receiverId };
      if (applicationDetails.recruiterId === selectedContact.id) {
        params.jobApplicationId = applicationDetails._id;
      } else if (applicationDetails.referralId === selectedContact.id) {
        params.jobId = applicationDetails.job._id;
      }

      if (!params.jobApplicationId && !params.jobId) return;

      const { success, data } = await jobMessageList(
        params.receiverId,
        params.jobApplicationId,
        params.jobId
      );

      if (success) {
        setMessages(data);

        const currentMessageCount = data[0]?.messageData?.length || 0;
        if (
          shouldScroll ||
          currentMessageCount !== lastMessageCountRef.current
        ) {
          setTimeout(() => scrollToBottom(shouldScroll), 100);
          lastMessageCountRef.current = currentMessageCount;
        }
      }
    } catch (error) {
      enqueueSnackbar(error + " Error fetching messages", { variant: "error" });
    }
  };

  useEffect(() => {
    if (!selectedContact || !applicationDetails?.job?.user?.userType) return;

    fetchMessages(true);

    // const intervalId = setInterval(() => {
    //   fetchMessages();
    // }, pollingInterval);

    // return () => clearInterval(intervalId);
  }, [selectedContact, applicationDetails]);

  useEffect(() => {
    if (applicationDetails) {
      if (applicationDetails.recruiterId) {
        setSelectedContact({
          id: applicationDetails.recruiterId,
          name: applicationDetails.recruiterName || "Recruiter",
        });
      } else if (applicationDetails.referralId) {
        setSelectedContact({
          id: applicationDetails.referralId,
          name: applicationDetails.referralName || "Referral",
        });
      }
    }
  }, [applicationDetails]);

  const handleContactSelect = (contactId, contactName) => {
    setSelectedContact({
      id: contactId,
      name: contactName,
    });
  };

  useEffect(() => {
    if (messages.length > 0) {
      markMessagesAsSeen();
    }
  }, [messages]);

  useEffect(() => {
    socket?.off("newMessage");

    socket?.on("newMessage", (messageData) => {
      console.log("New Message", messageData);
      if (
        messageData?.jobApplicationId === applicationDetails?._id &&
        messageData?.createdBy === applicationDetails?.recruiterId
      ) {
        setMessages((prev) => {
          const newMessage = {
            messageId: messageData?._id,
            message: messageData?.message,
            createdName: applicationDetails?.recruiterName,
            createdAt: messageData?.createdAt,
            seen: messageData?.seen,
            createdBy: messageData?.createdBy,
          };

          return prev?.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                messageData: [...item?.messageData, newMessage],
              };
            } else {
              return item;
            }
          });
        });
        setTimeout(scrollToBottom, 100);
      }
    });
  }, []);

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedContact) return;
    try {
      setIsLoading(true);

      let payload = {};

      if (applicationDetails.recruiterId === selectedContact.id) {
        payload = {
          jobApplicationId: applicationDetails?._id,
          message,
          receivedBy: selectedContact.id,
        };
      } else if (applicationDetails.referralId === selectedContact.id) {
        payload = {
          jobId: applicationDetails?.job._id,
          message,
          receivedBy: selectedContact.id,
        };
      }

      await jobMessage(payload);
      setMessage("");
      setIsComposing(false);
      fetchMessages();
      setTimeout(scrollToBottom, 100);
    } catch (error) {
      enqueueSnackbar(error + "Error sending message", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const markMessagesAsSeen = async () => {
    if (!messages[0]?.messageData?.length) return;

    const unseenMessageIds = messages[0].messageData
      .filter((msg) => !msg.seen)
      .map((msg) => msg.messageId);

    if (unseenMessageIds.length === 0) return;

    try {
      await updateMessageSeen({ messagesIds: unseenMessageIds });
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };

  return (
    <div className="flex flex-1 gap-6 pr-20 overflow-hidden">
      <div className="border-r border-[#ECECEC] w-1/4">
        {applicationDetails?.recruiterName && (
          <div
            className={`flex items-center gap-3 py-3 px-4 border-b border-[#ECECEC] cursor-pointer ${
              selectedContact?.id === applicationDetails.recruiterId
                ? "bg-[#F1F5F9]"
                : ""
            }`}
            onClick={() =>
              handleContactSelect(
                applicationDetails.recruiterId,
                applicationDetails.recruiterName
              )
            }
          >
            <div className="font-montserrat text-white font-semibold uppercase w-9 h-9 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails.recruiterName.charAt(0)}
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-primary font-medium text-xs">
                {applicationDetails.recruiterName}
              </p>
              <p className="text-light-black text-xs">Recruiter</p>
            </div>
          </div>
        )}
        {applicationDetails?.referralName && (
          <div
            className={`flex items-center gap-3 py-3 px-4 border-b border-[#ECECEC] cursor-pointer ${
              selectedContact?.id === applicationDetails.referralId
                ? "bg-[#F1F5F9]"
                : ""
            }`}
            onClick={() =>
              handleContactSelect(
                applicationDetails.referralId,
                applicationDetails.referralName
              )
            }
          >
            <div className="font-montserrat text-white font-semibold uppercase w-9 h-9 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails.referralName.charAt(0)}
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-primary font-medium text-xs">
                {applicationDetails.referralName}
              </p>
              <p className="text-light-black text-xs">Referral</p>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col h-full w-full relative">
        <div
          ref={messagesContainerRef}
          className="flex-grow overflow-y-auto no-scrollbar pr-2 pb-4"
          style={{ maxHeight: "calc(100% - 100px)" }}
        >
          {messages[0]?.messageData?.length ? (
            messages[0]?.messageData?.map((item, index) => {
              const isSentByCurrentUser = item.createdBy === user._id;
              return (
                <div
                  key={index}
                  className="flex flex-col gap-2 rounded cursor-pointer text-xs border-b p-4"
                >
                  <div
                    className={`rounded text-primary flex justify-between font-bold`}
                  >
                    <p className="text-[13px] font-bold text-primary">
                      {item.createdName}
                    </p>
                    <p className="text-primary font-medium italic">
                      {moment(item?.createdAt).format("HH:mm DD/MM/YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-between items-end">
                    <p
                      className={`text-xs font-medium text-light-black py-3 px-5 rounded`}
                    >
                      {item.message}
                    </p>
                    {isSentByCurrentUser && item.seen && (
                      <p className="text-xss text-green-600">Seen</p>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-sm text-center flex h-full items-end justify-center pb-10">
              No messages available.
            </p>
          )}
        </div>

        {/* Fixed message input area */}
        <div className="absolute bottom-0 left-0 right-0 bg-white mx-5 mb-2">
          <div className="flex gap-4">
            <textarea
              className="p-4 w-full bg-white rounded text-xs font-medium border border-[#ECECEC] focus:border-gray-300 focus:outline-none"
              rows={4}
              placeholder="Type your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button
              className="text-xs text-primary font-medium flex items-center disabled:cursor-not-allowed"
              onClick={handleSendMessage}
              disabled={isLoading || !message.trim()}
            >
              {isLoading ? (
                <LoadingAnimation color="black" className={"w-4 h-4"} />
              ) : (
                <PaperSendIcon fill={message.trim() && "#FF9800"} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesTab;

import * as React from "react";
const DoubleCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    stroke="#00ff40"
    strokeWidth={0}
    {...props}
  >
    <path
      fill="#00ff4c"
      stroke="none"
      d="M5.03 11.47a.75.75 0 0 0-1.06 1.06l1.06-1.06ZM8.5 16l-.53.53a.75.75 0 0 0 1.06 0L8.5 16Zm8.53-7.47a.75.75 0 0 0-1.06-1.06l1.06 1.06Zm-8 2.94a.75.75 0 0 0-1.06 1.06l1.06-1.06ZM12.5 16l-.53.53a.75.75 0 0 0 1.06 0L12.5 16Zm8.53-7.47a.75.75 0 0 0-1.06-1.06l1.06 1.06Zm-17.06 4 4 4 1.06-1.06-4-4-1.06 1.06Zm5.06 4 8-8-1.06-1.06-8 8 1.06 1.06Zm-1.06-4 4 4 1.06-1.06-4-4-1.06 1.06Zm5.06 4 8-8-1.06-1.06-8 8 1.06 1.06Z"
    />
  </svg>
);
export default DoubleCheck;

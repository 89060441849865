import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ExperienceData } from "../experience.types";
import {
  addExperience,
  deleteExperience,
  updateExperience,
} from "../../../../../endpoints/experience.service";
import AddEditExperience from "./addEditExperience";
import { useForm } from "react-hook-form";
import { experienceFeilds } from "./experienceBlock.types";
import { isNullOrEmpty } from "../../../../../utils/helperFunction";
import moment from "moment";
import CloseIcon from "../../../../../assets/svg/CloseIcon";
import EditIcon from "../../../../../assets/svg/EditIcon";
import LoadingAnimation from "../../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../../utils/theme";
import { JobSkills } from "../../../../../utils/constants";
import { SkillData } from "../../skill/skillInput.types";

const ExperienceBlock: React.FC<{
  showOnly?: boolean;
  experiences?: ExperienceData[];
  popUpShow?: boolean;
  closeModal?: () => void;
  setExperiences?: React.Dispatch<React.SetStateAction<ExperienceData[]>>;
  isAddingNew?: boolean;
}> = ({
  showOnly,
  experiences,
  popUpShow = false,
  closeModal,
  setExperiences,
  isAddingNew = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [isNewAdded, setIsNewAdded] = useState(isAddingNew);

  const defaultValues = {
    startDate: undefined,
    startYear: undefined,
    startMonth: undefined,
    endYear: undefined,
    endMonth: undefined,
    endDate: undefined,
    jobTitle: undefined,
    companyName: undefined,
    location: undefined,
    jobDescription: undefined,
    isCurrent: false,
    isNewAdded: false,
    isLoadingDelete: false,
    skills: [],
  };
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, touchedFields },
    watch,
  } = useForm<experienceFeilds>({
    defaultValues: defaultValues,
  });
  const fields = watch();

  useEffect(() => {
    if (!isNullOrEmpty(experiences) && fields?._id) {
      const findExp = experiences?.find((x) => x._id === fields?._id);
      setValue("skills", findExp?.skills);
    }
  }, [experiences, fields?._id, Object?.keys(touchedFields)?.length]);

  const { enqueueSnackbar } = useSnackbar();

  const onSave = async (data: experienceFeilds) => {
    try {
      setLoading(true);

      const formatDate = (year?: string, month?: string) => {
        if (!year || !month) return undefined; // Ensure both values exist
        return moment
          .utc(`${year}-${month}-27`)
          .format("YYYY-MM-DDT00:00:00.000[Z]");
      };

      const payload = {
        ...data,
        startDate: formatDate(data.startYear, data.startMonth),
        endDate: data.isCurrent
          ? undefined
          : formatDate(data.endYear, data.endMonth),
        skills: data?.skills?.map((skill) => ({
          ...skill,
          lastUsed:
            typeof skill?.lastUsed === "string"
              ? JobSkills.find(
                  (j) =>
                    j.label?.toLowerCase() ===
                    String(skill?.lastUsed)?.toLowerCase()
                )?.value
              : skill?.lastUsed,
        })),
      };

      if (payload._id) {
        await updateExperience({ ...payload, id: payload?._id }).then(
          (response) => {
            if (response.success) {
              setExperiences &&
                setExperiences((prev) =>
                  prev?.map((exp) =>
                    exp._id === response.data._id
                      ? { ...exp, ...response.data }
                      : exp
                  )
                );
              setIsNewAdded(false);
              reset(defaultValues);
              enqueueSnackbar("Experience updated successfully.", {
                variant: "success",
              });
            }
          }
        );
      } else {
        await addExperience(payload).then((response) => {
          if (response.success) {
            setExperiences &&
              setExperiences(
                experiences ? [response.data, ...experiences] : [response.data]
              );
            closeModal && closeModal();
            enqueueSnackbar("Experience added successfully.", {
              variant: "success",
            });
          }
        });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleAddSkill = (skill: SkillData) => {
    let skillArray = [...fields?.skills];

    if (skill?._id !== undefined) {
      skillArray[skill?._id] = skill;
    } else {
      skillArray.unshift({ ...skill, _id: 0 });
    }
    setValue("skills", skillArray);
  };

  const handleDeleteSkill = (_id: number) => {
    if (fields?.skills) {
      const updatedSkills = fields.skills.filter((_, index) => index !== _id);

      setValue("skills", []);

      setTimeout(() => {
        setValue("skills", updatedSkills);
      }, 0);
    }
  };

  const deleteExperienceApiCall = async () => {
    try {
      setValue("isLoadingDelete", true);
      if (fields?._id) {
        const { success, data } = await deleteExperience(fields?._id);

        if (success) {
          const removedData = experiences?.filter((x) => x._id !== fields?._id);
          setExperiences && setExperiences(removedData ? [...removedData] : []);
          setIsNewAdded(false);
          reset({
            startDate: undefined,
            endDate: undefined,
            jobTitle: undefined,
            companyName: undefined,
            location: undefined,
            jobDescription: undefined,
            isCurrent: false,
            isNewAdded: false,
            isLoadingDelete: false,
          });
          enqueueSnackbar("Experience deleted successfully.", {
            variant: "success",
          });
        }
      }
    } catch (error: any) {
      closeModal();
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setValue("isLoadingDelete", false);
    }
  };

  const RenderExperience = ({
    experience,
    index,
  }: {
    experience: ExperienceData;
    index: number;
  }) => (
    <div
      className={`flex justify-between pb-4 ${
        popUpShow &&
        experiences &&
        index < experiences?.length - 1 &&
        "border-b"
      }`}
    >
      <div className="flex flex-col gap-1 flex-1">
        <span className="font-montserrat text-primary font-bold text-sm">
          {moment(experience?.startDate).format("MMM YYYY")} -{" "}
          {experience?.isCurrent
            ? "Present"
            : moment(experience?.endDate).format("MMM YYYY")}
        </span>
        <span className="font-montserrat text-primary font-semibold text-sm">
          {experience?.jobTitle}, {experience?.companyName}
        </span>
        <span className="font-montserrat text-primary text-xs">
          {experience?.location}
        </span>

        <span
          className="font-montserrat mt-1 text-primary font-medium text-xs text-justify  whitespace-pre-wrap leading-5 pr-10"
          dangerouslySetInnerHTML={{
            __html: experience?.jobDescription?.trim() ?? "",
          }}
        />
        {experience?.skills?.length > 0 && (
          <div className="flex flex-col px-10">
            <div className="grid flex-1 mt-7 mb-2 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr] pr-32">
              {["Skill", "Rating", "Years", "When", ""].map((label) => (
                <span
                  key={label}
                  className="text-xs font-semibold text-gray-700 flex-1"
                >
                  {label}
                </span>
              ))}
            </div>
            <div className="overflow-auto h-full pr-32">
              {experience?.skills?.map((skill) => (
                <div
                  key={skill?._id}
                  className="grid space-x-3 py-2.5 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr]"
                >
                  {[
                    skill.title,
                    `${skill.rating}/10`,
                    skill.years,
                    JobSkills.find((j) => j.value === skill.lastUsed)?.label ||
                      skill.lastUsed,
                  ].map((value, index) => (
                    <div
                      key={index}
                      className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1"
                    >
                      {value}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {popUpShow && (
        //@ts-ignore
        <div
          onClick={() => {
            setIsNewAdded(true);
            //@ts-ignore
            reset({ ...experience });
          }}
        >
          <EditIcon className={"w-6 h-6 cursor-pointer"} fill="#4d4d4d" />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-1 pb-6 flex-col overflow-hidden">
      <div
        className={`flex items-center justify-between pb-6 ${
          popUpShow && "py-6 px-6"
        }`}
      >
        <div className={`flex gap-3 items-center ${popUpShow && "pl-14"}`}>
          <span className="font-montserrat text-primary font-bold text-[14px]">
            Work Experience
          </span>
          {popUpShow && !fields?._id && (
            <button
              className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
              onClick={() => {
                setIsNewAdded(true);
              }}
            >
              + Add Experience
            </button>
          )}
        </div>
        {!showOnly && (
          <button
            onClick={() => {
              if (fields?._id) {
                setIsNewAdded(false);
                reset({ ...defaultValues });
              } else {
                closeModal && closeModal();
              }
            }}
            className="self-end text-black text-2xl font-semibold"
          >
            <CloseIcon className="w-6 h-6" />
          </button>
        )}
      </div>

      <div
        className={`flex flex-1 flex-col overflow-y-scroll pb-6 ${
          popUpShow ? "px-28" : "no-scrollbar"
        }`}
      >
        {isNewAdded ? (
          <AddEditExperience
            control={control}
            fields={fields}
            errors={errors}
            setValue={setValue}
            handleAddSkill={handleAddSkill}
            handleDeleteSkill={handleDeleteSkill}
            setExperiences={setExperiences}
          />
        ) : (
          <div className="flex flex-1 flex-col gap-4">
            {experiences?.map((exp, index) => (
              <RenderExperience experience={exp} key={index} index={index} />
            ))}
          </div>
        )}
      </div>
      {(!!fields?._id || isNewAdded) && (
        <div
          className={`flex border-t ${
            !!fields?._id ? "justify-between" : "justify-end"
          }  pt-6 px-20`}
        >
          {!!fields?._id && (
            <button
              className="flex items-center justify-center border rounded px-5 py-2 text-primary font-medium text-[15px]"
              onClick={deleteExperienceApiCall}
              disabled={fields?.isLoadingDelete}
            >
              {fields?.isLoadingDelete && (
                <LoadingAnimation color={colors.PrimaryBlack} />
              )}
              Delete Experience
            </button>
          )}

          <button
            className="flex items-center justify-center border bg-primary rounded px-5 py-2 text-white font-medium text-sm"
            onClick={handleSubmit(onSave)}
            disabled={loading}
          >
            {loading && <LoadingAnimation />}
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default ExperienceBlock;

import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../assets/svg/PlusIcon";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CrossBig from "../../../assets/svg/CrossBig";
import { useForm } from "react-hook-form";
import { inviteUserViaEmail } from "../../../endpoints/recruiter.service";
import { colors } from "../../../utils/theme";
import { useSnackbar } from "notistack";
import FileIcon from "../../../assets/images/fileIcon.png";
import { useEffect, useState } from "react";
import { getReferralContacts } from "../../../endpoints/user.service";
import { isNullOrEmpty } from "../../../utils/helperFunction";

const tabs = [
  {
    title: "One",
    value: 0,
  },
  {
    title: "Two",
    value: 1,
  },
  {
    title: "Three",
    value: 2,
  },
];

const CandidateRow = ({ member, navigate }) => {
  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case "AVAILABLE":
        return { bg: "bg-[#23BD33]", text: "text-white" };
      case "CONTRACTED":
        return { bg: "bg-[#FF8C00]", text: "text-white" };
      case "OPEN_TO_OPPORTUNITIES":
        return { bg: "bg-[#FFD42C]", text: "text-black" };
      case "UNAVAILABLE":
        return { bg: "bg-light-grey", text: "text-white" };
      default:
        return { bg: "bg-[#B0B0B0]", text: "text-black" };
    }
  };

  return (
    <div
      className="group flex gap-3 mb-2 overflow-hidden w-full"
      onClick={() => navigate("/network-detail", { state: member })}
    >
      <div className="w-2/3 border border-[#ECECEC] bg-[#FAFAFA] py-4 px-5 flex items-center rounded-lg gap-5 cursor-pointer max-lg:px-2 group-hover:bg-gray-100">
        <div className="flex flex-col gap-1 items-center">
          <div className="text-xss text-light-black">{member?.refNo}</div>
          <div className="h-12 w-12 rounded-full justify-center items-center flex bg-[#E1E0E0] bg-nwhited shadow-xl">
            {member?.profilePic ? (
              <img
                src={member?.profilePic}
                className="h-full w-full object-cover rounded-full"
                alt="profileImg"
              />
            ) : (
              <span className="font-montserrat text-white font-semibold text-xl">
                {member?.firstName?.charAt(0).toUpperCase()}
                {member?.lastName?.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3.5 w-2/5">
          <div className="text-xs text-light-black pl-5 flex gap-2 max-lg:gap-1 max-lg:text-xss">
            <span>Last active</span>
            <span>1:00pm</span>
            <span>20/12/2025</span>
          </div>
          <div className="text-xs font-medium text-primary bg-[#F1F5F9] py-2 px-5 rounded">
            {member?.firstName} {member?.lastName}
          </div>
        </div>
        <div className="flex flex-col gap-3.5 w-2/5">
          <div className="text-xs text-light-black pl-5 max-lg:text-xss">
            Information Technology
          </div>
          <div className="text-xs font-medium text-primary bg-white py-2 px-5 rounded">
            {isNullOrEmpty(member?.jobTitle) ? "N/A" : member?.jobTitle}
          </div>
        </div>
      </div>

      <div className="w-1/3 bg-white py-3 px-5 border border-[#ECECEC] rounded-lg cursor-pointer group-hover:bg-gray-100">
        <div className="flex flex-col h-full gap-2.5">
          <div className="flex items-center justify-between gap-2 max-2xl:gap-1">
            {(() => {
              let formattedStatus = member?.status
                ?.toLowerCase()
                .replace(/_/g, " ");
              formattedStatus =
                formattedStatus.charAt(0).toUpperCase() +
                formattedStatus.slice(1);

              if (member?.status?.toUpperCase() === "OPEN_TO_OPPORTUNITIES") {
                formattedStatus = "Open";
              }

              const { bg, text } = getStatusColor(member?.status);

              return (
                <span
                  className={`text-xs py-1.5 px-2 w-[120px] text-center rounded ${bg} ${text}`}
                >
                  {formattedStatus ?? "-"}
                </span>
              );
            })()}
            <span className="text-xs text-light-black flex gap-2 max-2xl:gap-1 max-2xl:text-xss">
              <span>Confirmed:</span>
              <span>1:00pm</span>
              <span>20/12/2025</span>
            </span>
          </div>
          <div className="bg-blue-50 rounded py-1.5 text-center">
            <div className="text-xs text-gray-600">Reffered: 14/2/2025</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NetworkPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      inviteModal: false,
      isSubmitting: false,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
  });
  const fields = watch();

  useEffect(() => {
    setLoading(true);
    getReferralContacts()
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setMembers(data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleFormSubmit = async () => {
    setValue("isSubmitting", true);
    try {
      await inviteUserViaEmail({
        firstName: fields?.firstName,
        lastName: fields?.lastName,
        email: fields?.email,
      });
      enqueueSnackbar("Member invited successfully", { variant: "success" });
      reset({
        inviteModal: false,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
      });
    } catch (error) {
      enqueueSnackbar("Error inviting member", { variant: "error" });
    } finally {
      setValue("isSubmitting", false);
    }
  };

  return (
    <div className="flex flex-col items-center rounded-lg bg-white w-full flex-grow p-4 gap-8">
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col w-full">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-full"></div>
                  <div className="h-3 rounded bg-gray-200 w-full"></div>
                  <div className="h-3 rounded bg-gray-200 w-full"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {members && members.length > 0 ? (
            <>
              <div className="flex flex-col w-full">
                <div className="flex  items-center justify-between w-full gap-8 bg-[#F1F5F9] rounded-md px-3 py-1">
                  <div className="flex flex-1 w-full items-center justify-between gap-8">
                    {/* Input container */}
                    <div className="flex w-[50%] rounded-lg gap-3 items-center justify-between bg-light-white-500 py-1.5">
                      <CustomInput
                        key={fields.searchBy}
                        name="search"
                        control={control}
                        className="border-none"
                        errors={errors}
                        placeholder="Search"
                      />
                    </div>
                    <button
                      onClick={() => setValue("inviteModal", true)}
                      className="flex items-center justify-center border bg-green-600 rounded-lg px-2 py-1 gap-1 text-white font-semibold text-sm"
                    >
                      <PlusIcon className={"w-4 h-4"} fill={"white"} />
                      Invite
                    </button>
                  </div>
                </div>

                <div className="flex border-b border-[#DDDDDD] gap-16 mt-5 pb-2 pl-4">
                  {tabs?.map((tab) => {
                    const isActive = tab?.value === activeTab;
                    return (
                      <button
                        key={tab?.value}
                        onClick={() => setActiveTab(tab?.value)}
                        className={`flex items-center ${
                          isActive ? "border-b border-primary" : ""
                        }`}
                      >
                        <span
                          className={`text-xs pb-0.5 ${
                            isActive
                              ? "text-primary font-medium"
                              : "text-gray-500"
                          }`}
                        >
                          {tab?.title}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="w-full rounded-md overflow-y-scroll no-scrollbar">
                {members?.map((member, index) => {
                  return (
                    <CandidateRow
                      key={index}
                      member={member}
                      navigate={navigate}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="flex flex-col h-full justify-center items-center">
              <img src={FileIcon} alt="File Icon" />
              <p className="font-semibold text-sm text-primary">
                You currently don’t have any contact.
              </p>
              <div className="flex flex-col gap-2 mt-3">
                <p className="font-medium text-xs text-light-black">
                  Click here to invite.
                </p>
                <button
                  onClick={() => setValue("inviteModal", true)}
                  className="flex items-center justify-center border bg-green-600 rounded-lg px-2 py-1 gap-1 text-white font-semibold text-sm"
                >
                  <PlusIcon className={"w-4 h-4"} fill={"white"} />
                  Invite
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {fields?.inviteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-xl font-semibold">Invite Contact</h2>
              <button
                onClick={() =>
                  reset({
                    inviteModal: false,
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined,
                  })
                }
              >
                <CrossBig width={24} height={24} />
              </button>
            </div>
            <div className="bg-[#F9FAFB] px-8 py-3 rounded-2xl">
              <div className="mb-4">
                <CustomInput
                  label="First Name"
                  name="firstName"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  label="Last Name"
                  name="lastName"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  label="Email address"
                  name="email"
                  control={control}
                  errors={errors}
                  rules={{
                    required: "This field is required.",
                  }}
                />
              </div>
              <button
                onClick={handleSubmit(handleFormSubmit)}
                className="text-xl bg-primary text-white py-1.5 px-5 rounded-md flex items-center justify-self-end mt-14 mb-6"
              >
                {fields.isSubmitting && (
                  <LoadingAnimation color={colors.White} />
                )}{" "}
                Invite
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobStatus, JobType, skillsUsed } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SkillInput } from "../../../SearchPage/DetailPage";
import QuillEditorViewOnly from "../../../../components/QuillEditor/QuillEditorViewOnly";
import { timeSincePost } from "../../../../utils/helperFunction";
import { publishUnpublish } from "../../../../endpoints/job.service";
import { setJobDetail } from "../../../../store/actions/job.action";
import { useSnackbar } from "notistack";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../utils/theme";
import ChevronDown from "../../../../assets/svg/ChevronDown";

const tabs = ["Job description", "Skills required"];

const PublishDropdownInput = ({
  jobDetail,
  handlePublishUnpublish,
  publishLoading,
  colors,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const publishOptions = ["Publish", "Unpublish", "Closed"];

  const currentSelection =
    jobDetail?.status === JobStatus.LIVE ? "Unpublish" : "Publish";

  const handleOptionSelect = (option) => {
    if (option !== currentSelection) {
      handlePublishUnpublish();
    }
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative w-max">
      <div
        className={`flex rounded-md border px-4 py-3 cursor-pointer bg-[#FF9800]`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <input
          className="
            bg-transparent 
            text-[13px] 
            font-semibold
            text-white 
            cursor-pointer
          "
          value={currentSelection}
          readOnly
        />
        {publishLoading ? (
          <LoadingAnimation color={colors.White} className="w-4 h-4" />
        ) : (
          <ChevronDown fill={"white"} />
        )}
      </div>
      {isDropdownOpen && (
        <div
          className="
            absolute 
            top-full 
            left-0 
            w-full 
            bg-white 
            rounded-md 
            border 
            border-gray-300 
            z-50 
            shadow-lg 
            max-h-52 
            overflow-y-auto
          "
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          {publishOptions.map((item, index) => (
            <div
              key={index}
              className={`
                px-4 
                py-2 
                cursor-pointer
                ${
                  item === currentSelection
                    ? "bg-gray-100 font-semibold"
                    : "hover:bg-gray-50"
                }
              `}
              onClick={() => handleOptionSelect(item)}
            >
              <span
                className={`
                text-xs 
                ${
                  item === currentSelection
                    ? "text-primary font-semibold"
                    : "text-gray-600"
                }
              `}
              >
                {item}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const RenderBody = ({ activeTab, data }) => {
  switch (activeTab) {
    case 0:
      return (
        <div>
          <QuillEditorViewOnly value={data?.description} />
        </div>
      );

    case 1:
      return (
        <div className="flex flex-col">
          <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
            <span className="font-medium text-xs text-primary">
              Required Skills
            </span>
            <span className="font-medium text-xs text-primary">Rating</span>
            <span className="font-medium text-xs text-primary">Years</span>
            <span className="font-medium text-xs text-primary">When</span>
            <div />
          </div>
          {data?.skills?.map((skill) => {
            // return <span>asfsadf</span>;
            return (
              <div
                key={skill?._id}
                className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2 mt-2"
              >
                <SkillInput readOnly={true} type="skill" value={skill?.title} />
                <SkillInput
                  readOnly={true}
                  type="rating"
                  placeholder="Rating"
                  value={skill?.rating}
                />
                <SkillInput
                  readOnly={true}
                  placeholder="Years"
                  value={skill?.years}
                />
                <SkillInput
                  readOnly={true}
                  type="skillUsed"
                  placeholder="When last used"
                  value={Object.keys(skillsUsed).find(
                    (key) => skillsUsed[key] === skill?.lastUsed
                  )}
                />
              </div>
            );
          })}
        </div>
      );

    default:
      break;
  }
};

export const JobPostSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { jobDetail } = useSelector((state) => state?.job);
  const [activeTab, setActiveTab] = useState(0);
  const [publishLoading, setPublishLoading] = useState(false);

  const job =
    JobType.find(({ value }) => value === jobDetail?.jobType)?.label || "-";

  const detailsColumn = [
    {
      title: "Location",
      value: jobDetail?.location,
    },
    {
      title: "Industry Type",
      value: jobDetail?.industryType,
    },
    {
      title: "Job Type",
      value: JobType?.find((item) => item?.value === jobDetail?.jobType)?.label,
    },
    {
      title: "Salary Range",
      value: `${jobDetail?.currency + jobDetail?.salary?.min} - ${
        jobDetail?.currency + jobDetail?.salary?.max
      }`,
    },
  ];

  const publishedColumn = [
    {
      title: "Published",
      value: timeSincePost(jobDetail?.publishedDate),
    },
    {
      title: "Job post expiration",
      value: "7 Days",
    },
    {
      title: "Referral fee",
      value: "£ 600",
    },
    {
      title: "Views",
      value: "174",
    },
    {
      title: "Applicants",
      value: "24",
    },
  ];

  const handlePublishUnpublish = async () => {
    try {
      setPublishLoading(true);
      const jobStatus = jobDetail?.status === JobStatus.LIVE ? 2 : 1;
      const response = await publishUnpublish(jobDetail?._id, jobStatus);
      const { success, data } = response;
      if (success) {
        dispatch(
          setJobDetail({ ...jobDetail, status: jobStatus === 2 ? 5 : 2 })
        );
        enqueueSnackbar(data?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred", {
        variant: "error",
      });
    } finally {
      setPublishLoading(false);
    }
  };

  return (
    <section className="flex pt-4 relative overflow-y-scroll no-scrollbar gap-4">
      {/* Left section */}
      <div className="flex flex-col flex-1">
        <button
          onClick={() => navigate("/job/create", { state: jobDetail })}
          type="submit"
          className="group flex items-center justify-center font-medium text-xs relative self-end bg-[#F1F5F9] rounded-md py-1.5 px-4 border hover:bg-gray-100"
        >
          <span className="z-50">Edit job post</span>
        </button>
        <div className="flex flex-col bg-[#fafafa] rounded-md py-6 px-5 border-[#F1F5F9] border-2 w-full mt-3">
          <div className="flex items-center justify-between">
            <label className="font-semibold text-primary">
              {jobDetail?.title}
            </label>
            <span className="font-medium text-primary text-xs">
              {jobDetail?.refNo}
            </span>
          </div>
          <div className="flex flex-col gap-3.5 py-4">
            {detailsColumn?.map((item) => {
              return (
                <div key={item?.title} className="flex items-center">
                  <span className="font-medium text-xs text-light-black w-28">
                    {item?.title}:
                  </span>
                  <span className="font-semibold text-primary text-xs">
                    {item?.value}
                  </span>
                </div>
              );
            })}
          </div>
          <span className="text-light-black italic text-xs text-center w-full font-medium">
            Posted {moment(jobDetail?.createdAt).format("DD MMM-YY HH:mm")}
          </span>
          {/* Action buttons */}
          <div className="flex items-center gap-2 mt-7">
            <button className="h-9 w-28 bg-white border rounded-md text-sm font-medium text-primary hover:bg-[#F1F5F9]">
              Apply
            </button>
            <button className="h-9 w-28 bg-white border rounded-md text-sm font-medium text-primary hover:bg-[#F1F5F9]">
              Track
            </button>
            <button className="h-9 w-28 bg-white border rounded-md text-sm font-medium text-primary hover:bg-[#F1F5F9]">
              Refer
            </button>
          </div>
          {/* Tabs section */}
          <div className="flex flex-col mt-10 gap-5">
            <div className="flex items-center gap-8">
              {tabs?.map((tab, tabIndex) => {
                const isActive = activeTab === tabIndex;
                return (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tabIndex)}
                    className={`text-sm font-semibold border-b-2 px-1 pb-px ${
                      isActive
                        ? "border-secondary text-primary"
                        : "border-transparent text-light-grey"
                    }`}
                  >
                    {tab}
                  </button>
                );
              })}
            </div>
            <RenderBody activeTab={activeTab} data={jobDetail} />
          </div>
        </div>
      </div>
      {/* Right section */}
      <div className="flex flex-col w-[30%] mt-11 gap-8 bg-[#F1F5F9] rounded-md py-6 px-5 h-max">
        {[JobStatus.LIVE, JobStatus.DRAFT, JobStatus.UNPUBLISHED]?.includes(
          jobDetail?.status
        ) ? (
          <PublishDropdownInput
            jobDetail={jobDetail}
            handlePublishUnpublish={handlePublishUnpublish}
            publishLoading={publishLoading}
            colors={colors}
          />
        ) : null}
        <div className="flex flex-col gap-5">
          {publishedColumn?.map((item) => {
            return (
              <div key={item?.title} className="flex items-center">
                <span className="font-medium text-xs text-light-black w-36">
                  {item?.title}:
                </span>
                <span className="font-medium text-primary text-xs">
                  {item?.value}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

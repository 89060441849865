import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { SkillInputProps } from "./skillInput.types";
import { searchSkill } from "../../../../endpoints/skill.service";
import { skillsUsed } from "../../../../utils/constants";

const ReusableInput = ({
  type = "text",
  placeholder = "Skill",
  value,
  onChange,
  isValid = true,
  options = [],
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleTextChange = async (text) => {
    onChange(text);
    if (type === "title") {
      try {
        const { success, data } = await searchSkill(text);
        setSuggestions(success ? data : []);
        setShowSuggestions(success && data?.length > 0);
      } catch {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    }
  };

  const renderSuggestions = () => {
    const suggestionTypes = {
      title: () =>
        suggestions?.map((item) => (
          <div
            key={item?._id}
            onClick={() => {
              onChange(item?.title);
              setShowSuggestions(false);
            }}
            className="hover:bg-gray-100 px-4 py-2 cursor-pointer"
          >
            <span className="font-medium text-xs text-primary">
              {item?.title}
            </span>
          </div>
        )),
      lastUsed: () =>
        Object.entries(options)?.map(([key]) => (
          <div
            key={key}
            onClick={() => {
              onChange(key);
              setShowSuggestions(false);
            }}
            className="hover:bg-gray-100 px-4 py-1 cursor-pointer"
          >
            <span className="font-medium text-xs text-primary">{key}</span>
          </div>
        )),
    };

    return suggestionTypes[type] ? (
      <div className="absolute top-full left-0 w-full bg-white rounded-md border border-gray-300 z-50 shadow-lg max-h-52 overflow-y-auto">
        {suggestionTypes[type]()}
      </div>
    ) : null;
  };

  return (
    <div
      className={`flex items-center w-full bg-white rounded-md border border-gray-100 px-3 relative ${
        isValid ? "border-gray-100" : "border-red-500"
      }`}
    >
      <input
        className="bg-transparent w-full text-xs font-medium h-9 outline-none pb-0.5"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => setTimeout(() => setShowSuggestions(false), 300)}
      />

      {(type === "title" || type === "lastUsed") &&
        showSuggestions &&
        // value &&
        renderSuggestions()}

      {type === "rating" && (
        <span className="font-medium text-xs text-primary">/&nbsp;10</span>
      )}
    </div>
  );
};

export const SkillInput: React.FC<SkillInputProps> = ({
  addUpdateSkill,
  data,
  deleteSkill,
  index,
  showEditDeleteButtons,
}) => {
  const {
    control,
    watch,
    reset,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });

  const fields = watch();

  const onSave = (states) => {
    addUpdateSkill({ ...fields, _id: index });
    reset({ ...data });
    trigger();
  };

  return (
    <div className="grid space-x-3 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr_0.5fr] bg-[#FAFAFA]">
      {[
        {
          name: "title",
          type: "title",
          rules: { required: "Skill is required" },
        },
        {
          name: "rating",
          type: "rating",
          placeholder: "8",
          rules: { required: "Rating is required" },
        },
        {
          name: "years",
          placeholder: "years",
          rules: { required: "Experience is required" },
        },
        {
          name: "lastUsed",
          type: "lastUsed",
          placeholder: "Skill last used",
          rules: { required: "Last used is required" },
        },
      ].map(({ name, type = "text", placeholder, rules }) => (
        <div
          key={name}
          className={`flex flex-col py-3 text-sm text-primary ${
            name === "title" && "ml-4"
          }`}
        >
          <Controller
            control={control}
            // @ts-ignore
            name={name}
            rules={rules}
            render={({ field: { value, onChange } }) => (
              <ReusableInput
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                // @ts-ignore
                options={skillsUsed}
                isValid={!errors[type]}
              />
            )}
          />
        </div>
      ))}
      <div className="bg-white h-[100%] justify-center flex min-w-20 gap-4 pl-2">
        {!showEditDeleteButtons ? (
          <button
            className="flex items-center py-2 self-center font-medium px-4 bg-primary text-white text-xs border rounded-md w-max hover:bg-black"
            onClick={handleSubmit(onSave)}
          >
            Add
          </button>
        ) : (
          <div className="flex justify-evenly gap-2 flex-col xl:flex-row">
            <div
              className="flex items-center font-medium text-xs underline cursor-pointer"
              onClick={handleSubmit(onSave)}
            >
              Save
            </div>
            <div
              className="flex items-center font-medium text-xs underline cursor-pointer"
              onClick={() => deleteSkill(index)}
            >
              Delete
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

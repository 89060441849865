import { Controller, useForm } from "react-hook-form";
import SaveIcon from "../../../assets/svg/SaveIcon";
import TrashIcon from "../../../assets/svg/TrashIcon";
import CustomInput from "../../../components/CustomInput/CustomInput";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import CustomSlider from "../../../components/CustomSlider/CustomSlider";
import { useCallback, useEffect, useState } from "react";
import EditIcon from "../../../assets/svg/EditIcon";
import { useDispatch, useSelector } from "react-redux";
import { getSkills as getSkillsFromExperience } from "../../../endpoints/experience.service";
import { getSkills as getSkillsFromSkillService } from "../../../endpoints/skill.service";
import { JobSkills, skillsUsed } from "../../../utils/constants";
import { setUserData } from "../../../store/actions/auth.action";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { colors } from "../../../utils/theme";

export const SkillInput = ({
  data,
  addUpdateSkill,
  index,
  deleteSkill,
  editSkill,
  onEditSkill,
}) => {
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...data,
      lastUsed: data?.lastUsed ?? "current",
    },
  });
  const field = watch();

  const onSave = () => {
    addUpdateSkill(field, index);
  };

  if (data?.isSaved) {
    return (
      <>
        <div className="flex items-center my-2">
          <label
            style={{ width: "20%" }}
            className="flex font-medium text-xs text-primary"
          >
            {data?.title}
          </label>
          <label
            style={{ width: "25%" }}
            className="flex font-medium text-xs text-primary"
          >
            <ProgressBar value={data?.rating} total={10} />
          </label>
          <label
            style={{ width: "40%" }}
            className="flex font-medium text-xs text-primary justify-center"
          >
            {data?.years}
          </label>
          <label
            style={{ width: "15%" }}
            className="flex font-medium text-xs text-primary"
          >
            {Object.keys(skillsUsed).find(
              (key) => skillsUsed[key] === data?.lastUsed
            )}
          </label>
          {data?.isEdit && (
            <div className="flex w-[5%] mr-5 items-end gap-3">
              <div
                className="cursor-pointer"
                onClick={() => onEditSkill(index)}
              >
                <EditIcon className={"w-4 h-4"} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => deleteSkill(index)}
              >
                <TrashIcon className={"w-4 h-4"} />
              </div>
            </div>
          )}
        </div>
        <div className="w-full border-t border-[#DDDDDDDD] my-2"></div>
      </>
    );
  }
  return (
    <div className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg mb-5">
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="skill"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
              placeholder="Javascript"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.skill?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skill?.message}
          </span>
        )}
      </div>
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="level"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              defaultValue={5}
              value={value}
              labelStyle={`text-xs`}
              onChange={onChange}
            />
          )}
        />
        {errors?.level?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.level?.message}
          </span>
        )}
      </div>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name="experience"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
              placeholder="9"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.experience?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.experience?.message}
          </span>
        )}
      </label>
      <div style={{ width: "20%" }} className="flex text-sm text-primary">
        <div className="w-full">
          <CustomInput
            name="lastUsed"
            control={control}
            errors={errors}
            type="dropdown"
            dropdownList={["current", "this year", "last year", "2+ years"]}
            rules={{
              required: "This field is required.",
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-[5%] items-end gap-3">
        <div className="cursor-pointer" onClick={handleSubmit(onSave)}>
          <SaveIcon className={"w-4 h-4"} />
        </div>
        <div className="cursor-pointer" onClick={() => deleteSkill(index)}>
          <TrashIcon className={"w-4 h-4"} />
        </div>
      </div>
    </div>
  );
};

const Skills = ({ isEditing, candidateId }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [skillsState, setSkillsState] = useState([]);

  const { setValue, watch } = useForm({
    defaultValues: {
      skills: [],
    },
  });
  const field = watch();

  useEffect(() => {
    if (isEditing) {
      const tempSkills = field?.skills?.map((x) => ({
        ...x,
      }));
      setValue("skills", [...tempSkills]);
      return;
    } else {
      const tempSkills = field?.skills?.map((x) => ({
        ...x,
      }));
      setValue("skills", [...tempSkills]);
    }
  }, [isEditing]);

  const fetchUserSkills = useCallback(async () => {
    try {
      setLoading(true);
      let response;
      if (candidateId) {
        response = await getSkillsFromSkillService({
          type: 1,
          candidateId: candidateId,
        });
      } else {
        response = await getSkillsFromExperience();

        const { success, data } = response;
        if (success) {
          dispatch(setUserData({ skills: [...data] }));
        }
      }
      const { success, data } = response;
      if (success) {
        setSkillsState(data);
      } else {
        console.error("Failed to fetch skills from skill service.");
      }
    } catch (error) {
      console.error("Error fetching skills:", error);
    } finally {
      setLoading(false);
    }
  }, [candidateId, dispatch]);

  useEffect(() => {
    fetchUserSkills();
  }, [candidateId]);

  const allSkills = user?.skills?.length > 0 ? user.skills : skillsState;

  if (loading) {
    return (
      <div className="flex flex-1  flex-col items-center justify-center">
        <LoadingAnimation color={colors.PrimaryBlack} />
      </div>
    );
  }

  if (!allSkills || allSkills.length === 0) {
    return (
      <div className="text-primary font-semibold flex items-center justify-center h-full">
        No skills added.
      </div>
    );
  }

  return (
    <div>
      <div className="h-full flex flex-1 flex-col pt-7 overflow-y-scroll no-scrollbar pl-16">
        {(allSkills?.length ?? 0) > 0 && (
          <div className="grid flex-1 mt-7 mb-2 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr] pr-64">
            {["Skill", "Rating", "Years", "When", ""].map((label) => (
              <span
                key={label}
                className="text-xs font-semibold text-gray-700 flex-1"
              >
                {label}
              </span>
            ))}
          </div>
        )}
        <div className="overflow-auto h-full pr-64">
          {allSkills?.map((skill) => (
            <div
              key={skill?._id}
              className="grid space-x-3 py-2.5 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr]"
            >
              {[
                skill.title,
                `${skill.rating}/10`,
                skill.years,
                JobSkills.find((j) => j.value === skill.lastUsed)?.label ||
                  skill.lastUsed,
              ].map((value, index) => (
                <div
                  key={index}
                  className="border border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1"
                >
                  {value}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;

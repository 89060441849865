import { useSnackbar } from "notistack";
import { getReferralMatchedJobs } from "../../../../../endpoints/referral.service";
import { useEffect, useState } from "react";
import LoadingAnimation from "../../../../../assets/svg/LoadingAnimation";
import moment from "moment";

const JobMatchingRow = ({ totalMatchPercentage, refNo, title, postedAt }) => {
  return (
    <div className="flex justify-between items-center py-3 px-5 bg-white rounded-sm mb-3">
      <div className="flex gap-6 items-center">
        <div className="text-light-black font-medium italic text-[10px]">
          {totalMatchPercentage}% match
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-primary font-medium text-[10px]">{refNo}</div>
          <div className="text-primary font-medium text-xs">{title}</div>
        </div>
      </div>

      <div className="flex flex-col items-start gap-1">
        <div className="flex items-center text-xs gap-6 w-full">
          <span className="text-primary w-1/2">Posted</span>
          <span className="text-light-black text-right italic w-1/2">
            {moment(postedAt).format("DD/MM/YY")}
          </span>
        </div>
        <div className="flex items-center text-xs gap-6 w-full">
          <span className="text-primary w-1/2">Matched</span>
          <span className="text-light-black text-right italic w-1/2">
            18/12/24
          </span>
        </div>
      </div>
    </div>
  );
};

const MatchesTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [matchedSkills, setMatchedSkills] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = async (id = applicationDetails.candidateId) => {
    try {
      setLoading(true);
      const { data, success } = await getReferralMatchedJobs(id);
      if (success) {
        setMatchedSkills(data);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex gap-6 h-screen overflow-hidden">
      {/* Profile Card */}
      <div className="w-3/5 overflow-y-auto h-full no-scrollbar">
        <div className="bg-[#FAFAFA] py-8 px-5">
          {loading ? (
            <div className="flex flex-1 flex-col items-center justify-center">
              <LoadingAnimation color={"black"} />
            </div>
          ) : matchedSkills?.length === 0 ? (
            <div className="flex justify-center text-sm font-semibold">
              No Skills were matched.
            </div>
          ) : (
            matchedSkills?.map((job, index) => (
              <JobMatchingRow key={index} {...job} />
            ))
          )}
        </div>
      </div>

      <div className="bg-[#F1F5F9] p-4 space-y-3 rounded-lg w-2/5 h-3/5 overflow-y-hidden">
        <div className="flex justify-between">
          {applicationDetails?.profilePic ? (
            <img
              src={applicationDetails?.profilePic}
              className="rounded-full w-14 h-14 object-fill z-10 max-md:h-36 max-md:w-36"
            />
          ) : (
            <div className="font-montserrat text-white font-semibold uppercase w-14 h-14 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails?.firstName?.charAt(0)}
              {applicationDetails?.lastName?.charAt(0)}
            </div>
          )}
          <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded h-max">
            {applicationDetails?.refNo ?? "CA - 123ABC"}
          </span>
        </div>
        <div className="text-primary space-y-2 text-xs">
          <p className="font-bold">
            {applicationDetails?.firstName} {applicationDetails?.lastName}
          </p>
        </div>
        <div className="flex flex-col gap-3.5">
          <span className="font-medium text-primary text-xs flex-1">
            {applicationDetails?.jobTitle ?? "-"}
          </span>
          <div className="flex flex-col gap-3.5 mb-6">
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Location:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.location ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Industry Type:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.candidateDetails?.jobTitle ?? "Finance"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Job Type:</span>
              <span className="font-medium text-primary text-xs flex-1">
                Full Time
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Salary Range:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                £60,000 - £80,000
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchesTab;

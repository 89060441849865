import handleError from "./handle-error";
import api from "../api";
import axios from "../utils/axios.instance";

export const login = async (payload) => {
  try {
    const { data } = await axios.post(api.login, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const resendOtpVerifyEmail = async (email) => {
  try {
    const { data } = await axios.get(api.resendOtpverifyEmail + `/${email}`);

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const verifyEmail = async (payload) => {
  try {
    const { data } = await axios.post(api.verifyEmail, payload);

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const registerEmailVerify = async (payload) => {
  try {
    const { data } = await axios.post(api.registerEmailVerify, payload);

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const signup = async (params) => {
  try {
    const { data } = await axios.post(api.signup, params);
    if (data.success) return data;
    return false;
  } catch (err) {
    return handleError(err);
  }
};

export const createRecruiterProfile = async (params, token) => {
  try {
    const { data } = await axios.post(api.createRecruiterProfile, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.success) return data;
    return false;
  } catch (err) {
    return handleError(err);
  }
};

export const refreshTokenService = async (refreshToken) => {
  try {
    const res = await axios.post(api.token, { refreshToken: refreshToken });
    if (res && res.status === 200) {
      return res.data;
    } else {
      throw new Error("Auth credential validation failed.");
    }
  } catch (err) {
    return handleError(err);
  }
};

export const resetPassword = async (id) => {
  try {
    const { data } = await axios.post(api.resetPassword, { id });
    return data && data.success ? true : false;
  } catch (err) {
    return handleError(err);
  }
};

export const generateUserNames = async (firstName, lastName) => {
  try {
    const { data } = await axios.get(api.generateUserNames, {
      params: {
        firstName,
        lastName,
      },
    });

    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateUserName = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateUserName, payload);
    return data && data.success ? true : false;
  } catch (err) {
    return handleError(err);
  }
};

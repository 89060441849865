import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import regexList from "../../../utils/regexList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tokenValidateSuccess } from "../../../store/actions/auth.action";
import { login } from "../../../endpoints/auth.service";
import { useSnackbar } from "notistack";
import TickOutline from "../../../assets/svg/TickOutline";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";

const LoginAmbassadorPage = () => {
  const { token, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      isLoading: false,
    },
  });
  const states = watch();

  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const handleLogin = async (fields) => {
    try {
      setValue("isLoading", true);
      const { data } = await login(fields);
      dispatch(tokenValidateSuccess(data));
    } catch (error) {
      if (error === "Otp not verified") {
        navigate(`/email-verification`, {
          state: {
            email: fields?.email,
          },
        });
        return;
      }
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setValue("isLoading", false);
    }
  };

  useEffect(() => {
    if (token && user && user?._id) {
      navigate("/network", { replace: true });
    }
  }, [token, user]);

  return (
    <div className="h-full flex">
      {/* Form Container */}
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg py-10 px-20 bg-white relative w-max h-max m-auto">
        <label className="font-semibold text-3xl mb-2">
          Login as a Referral Ambassador
        </label>
        <label className="text-light-black font-medium">
          Welcome back! Please login to access your account
        </label>
        <form
          className="flex flex-col w-full pt-10"
          onSubmit={handleSubmit(handleLogin)}
        >
          <CustomInput
            label={"Email"}
            name="email"
            placeholder={"Enter your Email"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            control={control}
            errors={errors}
          />
          <div className="h-6" />
          <CustomInput
            label={"Password"}
            placeholder={"Enter your Password"}
            name="password"
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.password,
                message: "Please enter a valid password.",
              },
            }}
            control={control}
            errors={errors}
          />

          {/* Checkbox container  */}
          <div className="flex justify-between items-center w-full mt-3 text-light-black">
            <div className="flex items-center gap-2">
              <div
                onClick={() => setKeepMeSignedIn((prev) => !prev)}
                className="border-2 border-[#6F6F6F] h-4 w-4 flex items-center justify-center cursor-pointer"
              >
                {keepMeSignedIn ? (
                  <TickOutline className="w-1.5 h-1.5" fill="#6F6F6F" />
                ) : null}
              </div>
              <span className="font-montserrat font-medium text-light-black text-sm">
                Keep me signed in
              </span>
            </div>
            <span className="font-montserrat font-medium text-light-black text-xs cursor-pointer">
              Forgot password?
            </span>
          </div>

          <button
            type="submit"
            className="bg-primary flex items-center justify-center rounded-md mt-10 py-3"
          >
            {states?.isLoading && <LoadingAnimation />}
            <span className="text-white font-semibold">
              Login referral ambassador account
            </span>
          </button>

          <span className="mx-auto text-sm mt-1 font-medium text-light-black">
            Don’t have an account?{" "}
            <span
              onClick={() => navigate("/register-ambassador")}
              className="text-secondary cursor-pointer"
            >
              Register
            </span>
          </span>
        </form>
      </div>
    </div>
  );
};

export default LoginAmbassadorPage;

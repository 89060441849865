import { useEffect, useRef, useState } from "react";
import {
  jobMessage,
  jobMessageList,
  updateMessageSeen,
} from "../../../endpoints/job.service";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { colors } from "../../../utils/theme";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import DoubleCheck from "../../../assets/svg/DoubleCheck";
import PaperSendIcon from "../../../assets/svg/PaperSendIcon";

const MessagesTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const [isComposing, setIsComposing] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const candidateId =
    applicationDetails.jobMatchesDetails[0].candidateDetails.candidateId;
  const jobId = applicationDetails.jobMatchesDetails[0].jobDetails.jobId;

  const pollingInterval = 3000;

  const messagesContainerRef = useRef(null);
  const lastMessageCountRef = useRef(0);

  const scrollToBottom = (smooth = true) => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };

  const fetchMessages = async (shouldScroll = false) => {
    try {
      const receivedBy = candidateId;
      const { success, data } = await jobMessageList(receivedBy, null, jobId);

      if (success) {
        setMessages(data);

        const currentMessageCount = data[0]?.messageData?.length || 0;
        if (
          shouldScroll ||
          currentMessageCount !== lastMessageCountRef.current
        ) {
          setTimeout(() => scrollToBottom(shouldScroll), 100);
          lastMessageCountRef.current = currentMessageCount;
        }
      }
    } catch (error) {
      enqueueSnackbar(error + " Error fetching messages", { variant: "error" });
    }
  };

  useEffect(() => {
    fetchMessages(true);

    // const intervalId = setInterval(() => {
    //   fetchMessages();
    // }, pollingInterval);

    // return () => clearInterval(intervalId);
  }, [jobId, candidateId]);

  useEffect(() => {
    if (messages.length > 0) {
      markMessagesAsSeen();
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    try {
      setIsLoading(true);
      await jobMessage({
        jobId,
        message,
        receivedBy: candidateId,
      });
      setMessage("");
      setIsComposing(false);
    } catch (error) {
      enqueueSnackbar(error + "Error sending message", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const markMessagesAsSeen = async () => {
    if (!messages[0]?.messageData?.length) return;

    const unseenMessageIds = messages[0].messageData
      .filter((msg) => !msg.seen)
      .map((msg) => msg.messageId);

    if (unseenMessageIds.length === 0) return;

    try {
      await updateMessageSeen({ messagesIds: unseenMessageIds });
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };

  return (
    <div className="flex flex-col h-full w-full relative">
      <div
        ref={messagesContainerRef}
        className="flex-grow overflow-y-auto no-scrollbar pr-2 pb-4"
        style={{ maxHeight: "calc(100% - 100px)" }}
      >
        {messages[0]?.messageData?.length ? (
          messages[0]?.messageData?.map((item, index) => {
            const isSentByCurrentUser = item.createdBy === user._id;
            return (
              <div
                key={index}
                className="flex flex-col gap-2 rounded cursor-pointer text-xs border-b p-4"
              >
                <div
                  className={`rounded text-primary flex justify-between font-bold`}
                >
                  <p className="text-[13px] font-bold text-primary">
                    {item.createdName}
                  </p>
                  <p className="text-primary font-medium italic">
                    {moment(item?.createdAt).format("HH:mm DD/MM/YYYY")}
                  </p>
                </div>
                <div className="flex justify-between items-end">
                  <p
                    className={`text-xs font-medium text-light-black py-3 px-5 rounded`}
                  >
                    {item.message}
                  </p>
                  {isSentByCurrentUser && item.seen && (
                    <p className="text-xss text-green-600">Seen</p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-sm text-center flex h-full items-end justify-center pb-10">
            No messages available.
          </p>
        )}
      </div>

      {/* Fixed message input area */}
      <div className="absolute bottom-0 left-0 right-0 bg-white mx-5 mb-2">
        <div className="flex gap-4">
          <textarea
            className="p-4 w-full bg-white rounded text-xs font-medium border border-[#ECECEC] focus:border-gray-300 focus:outline-none"
            rows={4}
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            className="text-xs text-primary font-medium flex items-center disabled:cursor-not-allowed"
            onClick={handleSendMessage}
            disabled={isLoading || !message.trim()}
          >
            {isLoading ? (
              <LoadingAnimation color="black" className={"w-4 h-4"} />
            ) : (
              <PaperSendIcon fill={message.trim() && "#FF9800"} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessagesTab;

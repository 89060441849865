import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../../../assets/svg/SearchIcon";
import AboutSection from "../../ContactsPage/CompaniesSection/AddCompanyPage/components/AboutSection";
import AddContactSection from "../../ContactsPage/CompaniesSection/AddCompanyPage/components/AddContactSection";
import Spinner from "../../../components/Spinner/Spinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isNullOrEmpty } from "../../../utils/helperFunction";
import { Controller } from "react-hook-form";
import Switch from "../../../components/Switch/Switch";
import Calender from "../../../assets/svg/Calender";
import CloseIcon from "../../../assets/svg/CloseIcon";
import { getCompanyList } from "../../../endpoints/company.service";
import { setCompanies } from "../../../store/actions/company.action";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-[80%] max-w-[55%] relative max-h-[90vh] overflow-y-auto no-scrollbar">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <CloseIcon className="w-5 h-5" />
        </button>
        <div className="p-6 overflow-y-auto flex-1">{children}</div>
      </div>
    </div>
  );
};

const CompanySearch = ({
  search,
  setSearch,
  loading,
  filterdCompanies,
  setValue,
  fields,
  onCreateNewCompany,
  onClose,
}) => {
  return (
    <div className="px-16">
      <span className="text-primary font-semibold text-sm my-14 block">
        Add company to this job requirement
      </span>

      <div className="gap-y-2 flex flex-1 flex-col min-w-[60%] self-center">
        <span
          className="text-[#00BB29] self-end font-medium text-xs px-2 py-1 rounded cursor-pointer"
          onClick={onCreateNewCompany}
        >
          Create new company contact
        </span>
        <div className="flex flex-1 border border-[#dddddd] rounded-lg gap-3 items-center justify-between px-3">
          <input
            className="bg-white flex flex-1 text-xs font-medium text-light-black outline-none px-2 py-3 rounded-lg"
            placeholder="Search by Name, Title or Ref number..."
            value={search}
            onChange={(e) => setSearch(e.target?.value)}
          />
          <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
        </div>
        <div className="flex flex-1 flex-col border border-[#dddddd] rounded-lg gap-y-1 px-2.5 py-2 min-h-40 max-h-40 overflow-y-auto">
          {loading ? (
            <Spinner />
          ) : filterdCompanies?.length > 0 ? (
            filterdCompanies?.map((company) => (
              <span
                key={company?._id}
                className="text-primary font-medium text-xs px-2 py-1 rounded hover:bg-[#f7f7f7] whitespace-pre cursor-pointer"
                onClick={() => setValue("selectedCompany", company)}
              >
                {company?.name}
                {"        "}
                {company?.refNo}
              </span>
            ))
          ) : (
            <div className="flex flex-1 h-screen flex-col items-center justify-center">
              <p className="text-primary font-semibold">No companies created</p>
            </div>
          )}
        </div>
        <div className="flex flex-1 border border-[#dddddd] rounded-lg gap-3 items-center justify-between px-4 py-2.5">
          <span className="text-primary font-semibold text-xs text-center whitespace-pre">
            {fields?.selectedCompany
              ? `${fields?.selectedCompany?.name}        ${fields?.selectedCompany?.refNo}`
              : "Add company to this job requirement"}
          </span>
        </div>

        <button
          type="button"
          className="bg-[#ececec] rounded-md text-xs px-4 py-2 self-end mt-24"
          onClick={onClose}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const CustomDateInput = ({ value, onChange }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div className="relative flex items-center w-full justify-between">
      <span className="px-4 py-3.5 pointer-events-none">
        {value ? formatDate(value) : "Select Date"}
      </span>
      <span className="px-4 py-3.5 border-l border-[#ECECEC]">
        <Calender />
      </span>
      <input
        type="date"
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        className="absolute inset-0 opacity-0 cursor-pointer w-full px-4"
      />
    </div>
  );
};

const Settings = ({
  setCreateNewCompany,
  createNewCompany,
  loading,
  setValue,
  fields,
  control,
  errors,
}) => {
  const { companies } = useSelector((state) => state?.company);
  const [search, setSearch] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalView, setModalView] = useState("search");
  const dispatch = useDispatch();

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setValue("publishDate", getTodayDate());
  }, [setValue]);

  useEffect(() => {
    fetchCompanyList();
  }, [createNewCompany, modalView]);

  const fetchCompanyList = useCallback(() => {
    getCompanyList()
      .then(({ data, success }) => {
        if (success) dispatch(setCompanies(data));
      })
      .finally(() => setValue("companiesLoading", false));
  }, [dispatch, setValue]);

  const onCreateNewCompany = () => {
    setModalView("create");
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setModalView("search");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalView("search");
  };

  const handleRemoveCompany = () => {
    setValue("selectedCompany", undefined);
  };

  const handleTodayClick = () => {
    setValue("publishDate", getTodayDate());
  };

  const filterdCompanies = useMemo(() => {
    if (isNullOrEmpty(search)) {
      return companies;
    }

    return companies.filter(
      (x) =>
        String(x?.name)
          ?.toLowerCase()
          .includes(search?.toLowerCase()?.trim()) ||
        String(x?.refNo)?.toLowerCase().includes(search?.toLowerCase()?.trim())
    );
  }, [search, companies]);

  return (
    <div className="flex flex-col">
      {createNewCompany ? (
        <>
          <AboutSection
            setCompanyDetails={() => {}}
            navigation={() => setCreateNewCompany(false)}
          />
          <AddContactSection />
        </>
      ) : (
        <>
          <div className="flex flex-1 flex-col w-full">
            <div className="flex gap-5">
              <div className="border rounded border-[#F5F5F5] py-7 px-11 flex-1">
                <p className="text-xs text-primary font-bold mb-6">
                  Company Client
                </p>
                <div className="w-[85%]">
                  <div className="flex items-center justify-between mb-4">
                    <span className="text-primary font-medium text-sm">
                      Add company to this job requirement
                    </span>
                    <button
                      onClick={handleAddClick}
                      className="flex items-center font-medium bg-primary text-white text-xs border rounded-md w-max py-1.5 px-5 hover:bg-black"
                    >
                      Add
                    </button>
                  </div>

                  <div className="flex flex-1 bg-[#F9FAFB] border border-[#ECECEC] rounded items-center justify-between px-4 py-3.5 relative">
                    <span className="text-primary font-medium text-xs text-center whitespace-pre">
                      {fields?.selectedCompany
                        ? `${fields?.selectedCompany?.name}        ${fields?.selectedCompany?.refNo}`
                        : "Add company"}
                    </span>
                    <div
                      className="flex items-center font-medium text-xs text-light-black cursor-pointer absolute -right-16"
                      onClick={handleRemoveCompany}
                    >
                      Remove
                    </div>
                  </div>

                  <div className="flex items-center justify-between mt-6">
                    <label className="text-xs font-medium text-primary">
                      Show company on job post
                    </label>
                    <Controller
                      control={control}
                      name="locationProfile"
                      render={({ field: { onChange, value } }) => (
                        <Switch isChecked={value} onToggle={onChange} />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="border rounded border-[#F5F5F5] py-7 px-11 flex-1">
                <p className="text-xs text-primary font-bold mb-7">Referral</p>
                <p className="text-primary font-medium text-sm mb-5">
                  Enter referral fre offered for a successful placement
                </p>

                <div className="flex flex-1 bg-[#F9FAFB] border border-[#ECECEC] rounded items-center justify-between px-4 py-3.5 w-1/2 relative">
                  <span className="text-primary font-bold text-sm text-center whitespace-pre">
                    500
                  </span>
                  <span className="text-primary font-bold text-sm text-center whitespace-pre absolute -left-6">
                    £
                  </span>
                </div>

                <p className="flex items-center justify-between mt-6 text-xs text-primary font-medium">
                  Ambassador members will refer their matched contacts to you.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-4 border border-[#F5F5F5] py-12 rounded mt-6">
              <div className="flex flex-col w-2/5 self-center">
                <span className="text-primary font-bold text-xs mb-4">
                  Publish
                </span>
                <div className="flex flex-1 gap-4">
                  <button
                    type="button"
                    onClick={handleTodayClick}
                    className="text-primary font-medium text-xs text-center bg-[#F9FAFB] px-16 py-3.5 rounded flex items-center justify-center cursor-pointer hover:bg-[#ECECEC]"
                  >
                    Today
                  </button>
                  <p className="text-light-black font-medium text-xs text-center border border-[#ECECEC] rounded flex items-center flex-1 justify-between">
                    <Controller
                      name="publishDate"
                      control={control}
                      defaultValue={getTodayDate()}
                      render={({ field: { value, onChange } }) => (
                        <CustomDateInput value={value} onChange={onChange} />
                      )}
                    />
                  </p>
                </div>
                <p className="flex items-center justify-between mt-6 text-xs text-primary font-medium">
                  Job post will expire in 30 days from published date.
                </p>
              </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
              <div className="flex flex-col min-h-[500px]">
                {modalView === "search" ? (
                  <CompanySearch
                    search={search}
                    setSearch={setSearch}
                    loading={loading}
                    filterdCompanies={filterdCompanies}
                    setValue={setValue}
                    fields={fields}
                    onCreateNewCompany={onCreateNewCompany}
                    onClose={handleModalClose}
                  />
                ) : (
                  <>
                    <AboutSection
                      setCompanyDetails={() => {}}
                      navigation={() => setModalView("search")}
                    />
                    <AddContactSection />
                    <button
                      type="button"
                      className="bg-[#ececec] rounded-md text-xs px-4 py-2 self-end mt-12"
                      onClick={handleModalClose}
                    >
                      Continue
                    </button>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;

import { useNavigate } from "react-router-dom";
import PlusIcon from "../../assets/svg/PlusIcon";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJobDetail, setJobs } from "../../store/actions/job.action";
import { getJobs, updateJob } from "../../endpoints/job.service";
import { datePostedType, JobStatus, JobType } from "../../utils/constants";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import { isNullOrEmpty } from "../../utils/helperFunction";
import moment from "moment";
import { useSnackbar } from "notistack";

const tabs = [
  {
    title: "Published",
    value: 0,
  },
  {
    title: "Expired",
    value: 1,
  },
  {
    title: "Draft",
    value: 2,
  },
  {
    title: "Unpublished",
    value: 3,
  },
  {
    title: "Closed",
    value: 4,
  },
];

const JobCard = ({ data, handleCloseJob, activeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        dispatch(setJobDetail(data));
        navigate(
          data.status === 2 || data.status === 3 || data.status === 5
            ? `/job/${data?._id}/applicants`
            : "/job/create",
          { state: data }
        );
      }}
      className="flex items-center justify-between bg-white border border-[#F5F5F5] rounded px-6 py-3 hover:bg-gray-50 cursor-pointer"
    >
      <div className="flex items-center gap-10 w-1/2">
        <div className="flex flex-col gap-1 w-1/4">
          <span className="text-xs text-primary font-medium bg-[#FAFAFA] py-2 px-5 text-center">
            {data?.refNo}
          </span>
          <div className="flex items-center justify-center rounded-sm bg-[#F1F5F9] border py-2 px-5 text-xs font-medium text-primary">
            {data?.status === JobStatus.LIVE && "Live"}
            {data?.status === JobStatus.DRAFT && "Draft"}
            {data?.status === JobStatus.UNPUBLISHED && "Unpublished"}
            {data?.status === JobStatus.CLOSED && "Closed"}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xs text-primary font-bold py-2 px-5">
            {data.title}
          </span>
          <div className="flex items-center rounded-sm py-2 px-5 text-xs text-light-black">
            Client : {data?.companyDetails ? data?.companyDetails?.name : "N/A"}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="flex flex-col gap-1">
          <span className="text-xs text-primary py-2">Published</span>
          <div className="py-2 text-xs text-primary">Expiring</div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xs text-primary py-2">
            {moment(data?.createdAt).format("h:mm a DD/MM/YY")}
          </span>
          <div className="py-2 text-xs text-primary">1:00pm 16/03/25</div>
        </div>
      </div>
      <div className="flex flex-col gap-1 items-center">
        <div className="flex items-center justify-center rounded bg-[#F1F5F9] py-2 px-5 text-xs font-medium text-primary">
          Applicants
        </div>
        <div className="text-xs text-primary font-medium border rounded-full w-8 h-8 flex items-center justify-center relative">
          {data.totalApplicants}{" "}
          {data.unViewedApplicants > 0 && (
            <p className="h-3 w-3 bg-[#23BD33] rounded-full absolute -top-1 -right-3 flex items-center justify-center text-white text-xss">
              {data.unViewedApplicants}
            </p>
          )}
        </div>
      </div>
      {activeTab === 0 && (
        <div
          className="text-white text-xs font-medium bg-red-500 py-1.5 px-1 rounded"
          onClick={(event) => {
            event.stopPropagation();
            handleCloseJob(data._id, 3);
          }}
        >
          Close Job
        </div>
      )}
    </div>
  );
};

const JobPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasFetched = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { jobs } = useSelector((state) => state?.job);
  const [loading, setLoading] = useState(jobs?.length === 0);
  const [activeTab, setActiveTab] = useState(0);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      search: undefined,
      searchBy: "Search by",
    },
  });
  const fields = watch();

  const jobList = jobs?.filter((item) => {
    if (activeTab === 0) {
      return item?.status === JobStatus.LIVE;
    } else if (activeTab === 1) {
      return item?.status === JobStatus.EXPIRED;
    } else if (activeTab === 2) {
      return item?.status === JobStatus.DRAFT;
    } else if (activeTab === 3) {
      return item?.status === JobStatus.UNPUBLISHED;
    } else if (activeTab === 4) {
      return item?.status === JobStatus.CLOSED;
    }
  });

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchJobs();
    }
  }, [fields?.search]);

  const fetchJobs = () => {
    getJobs(
      !isNullOrEmpty(fields?.search) && {
        filter: {
          ...(fields?.searchBy === "Search by" && {
            searchText: fields.search,
          }),
          ...(fields?.searchBy === "Industry Type" && {
            industryType: fields.search,
          }),
          ...(fields?.searchBy === "Job Type" && {
            jobType: JobType.find((x) => x.label === fields.search)?.value,
          }),
          ...(fields?.searchBy === "Date Posted" && {
            datePosted: datePostedType.find((x) => x.label === fields.search)
              ?.value,
          }),
        },
      }
    )
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch(setJobs(data));
        }
      })
      .finally(() => setLoading(false));
  };

  const handleCloseJob = async (jobId, status) => {
    try {
      const { success } = await updateJob({ jobId, status });
      if (success) {
        fetchJobs();
        enqueueSnackbar("Job Closed Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      {/* Search and filter section */}
      <div className="flex  items-center justify-between w-full gap-8 bg-[#F1F5F9] rounded-md px-3 py-1">
        <div className="flex flex-1 w-full items-center gap-8">
          {/* Input container */}
          <div className="flex w-[50%] rounded-lg gap-3 items-center justify-between bg-light-white-500 py-1.5">
            {/* <div className="min-w-[28%]">
              <CustomInput
                name="searchBy"
                control={control}
                errors={errors}
                type="dropdown"
                dropdownList={[
                  "Search by",
                  "Industry Type",
                  "Job Type",
                  "Date Posted",
                ]}
              />
            </div> */}
            <CustomInput
              key={fields.searchBy}
              name="search"
              control={control}
              className="border-none"
              errors={errors}
              // placeholder={
              //   (fields.searchBy === "Search by" &&
              //     "Enter value for Search by") ||
              //   (fields.searchBy === "Industry Type" &&
              //     "Enter value for Industry Type")
              // }
              placeholder="Search"
              type={
                fields.searchBy === "Search by" ||
                fields.searchBy === "Industry Type"
                  ? "text"
                  : "dropdown"
              }
              dropdownList={
                fields.searchBy === "Job Type"
                  ? JobType.map((x) => x.label)
                  : datePostedType?.map((x) => x.label)
              }
            />
          </div>
        </div>

        <button
          onClick={() => navigate("create")}
          className="flex items-center justify-center border bg-white rounded-md px-4 py-2 gap-1 text-gray-500 font-medium text-xs"
        >
          Create a job ad
        </button>
      </div>

      <div className="flex items-center border-b border-[#DDDDDD] gap-16 mt-5 pb-2 pl-4">
        {tabs?.map((tab) => {
          const isActive = tab?.value === activeTab;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className={`flex items-center ${
                isActive ? "border-b border-primary" : ""
              }`}
            >
              <span
                className={`text-xs pb-0.5 ${
                  isActive ? "text-primary font-medium" : "text-gray-500"
                }`}
              >
                {tab?.title}
              </span>
            </button>
          );
        })}
      </div>

      {/* List container */}
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : jobs?.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">No Job created</p>
          <p className="mt-2 mb-5">Create a Job now!</p>
          <button
            onClick={() => navigate("create")}
            className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
          >
            <PlusIcon className={"w-4 h-4"} fill={"white"} />
            Create Job Post
          </button>
        </div>
      ) : (
        <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar">
          {jobList?.length ? (
            jobList?.map((job) => {
              return (
                <JobCard
                  key={job?._id}
                  data={job}
                  handleCloseJob={handleCloseJob}
                  activeTab={activeTab}
                />
              );
            })
          ) : (
            <div className="flex-grow">
              <span className="m-auto font-medium text-xs">No jobs found.</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JobPage;

import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import Details from "./components/Details";
import About from "./components/About";
import Education from "./components/Education";
import Contacts from "./components/Contacts";
import { useNavigate } from "react-router-dom";
import {
  getCandidateDetails,
  updateCandidate,
} from "../../endpoints/candidate.service";
import { useDispatch, useSelector } from "react-redux";
import { updateDisableStatus } from "../../endpoints/user.service";
import { setUserData } from "../../store/actions/auth.action";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";
import { profileImage, uploadUrl } from "../../endpoints/images.service";
import { useSnackbar } from "notistack";
import Experience from "../ProfilePage/Components/experience/experience";
import Skills from "../ProfilePage/Components/Skills";

const tabs = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "About",
    value: "about",
  },
  {
    label: "Experience",
    value: "experience",
  },
  {
    label: "Skills",
    value: "skills",
  },
  {
    label: "Education & Certifications",
    value: "educationAndCertificate",
  },
  {
    label: "Contact Details",
    value: "contactDetails",
  },
];

const RenderBody = forwardRef((props, ref) => {
  const {
    data,
    setData,
    activeTab,
    handleNext,
    handleEditClose,
    candidateId,
    editingTab,
    setEditingTab,
  } = props;

  return (
    <>
      {activeTab === "details" && (
        <Details
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
        />
      )}
      {activeTab === "about" && (
        <About
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          isEditing={data?.about?.isEditing}
          handleNext={handleNext}
        />
      )}
      {activeTab === "experience" && (
        <Experience
          ref={ref}
          isEditing={editingTab}
          setEditingTab={setEditingTab}
          handleEditClose={handleEditClose}
          candidateId={candidateId}
        />
      )}
      {activeTab === "skills" && <Skills candidateId={candidateId} />}
      {activeTab === "educationAndCertificate" && (
        <Education
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
        />
      )}
      {activeTab === "contactDetails" && (
        <Contacts
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
        />
      )}
    </>
  );
});

const CreateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const imageContainerRef = useRef(null);
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState("details");
  const [editingTab, setEditingTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    details: { isCompleted: false, isEditing: true, isFilled: false },
    about: { isCompleted: false, isEditing: true, isFilled: false },
    experience: { isCompleted: false, isEditing: true, isFilled: false },
    skills: { isCompleted: false, isEditing: true, isFilled: false },
    educationAndCertificate: {
      isCompleted: false,
      isEditing: true,
      isFilled: false,
    },
    contactDetails: { isCompleted: false, isEditing: true, isFilled: false },
  });

  const currentIndex = tabs.findIndex((tab) => tab.value === activeTab);
  const isFilled = data[tabs[currentIndex]?.value]?.isFilled;

  const nextStep = () => {
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex].value);
  };

  const handleNextClick = (data) => {
    if (activeTab === "contactDetails") {
      navigate("/dashboard");
    }
    if (formRef.current) {
      formRef.current();
    }
  };

  const disableStatus = useCallback(async () => {
    try {
      const { success } = await updateDisableStatus();
      if (success) {
        dispatch(setUserData({ enableDefaultProfileOption: false }));
      }
    } catch (error) {
      console.error("error in disable the profile option", error);
    }
  }, []);

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  useEffect(() => {
    if (
      (user?.enableDefaultProfileOption &&
        user?.experience &&
        user?.experience?.length > 0) ||
      (user?.enableDefaultProfileOption &&
        user?.educations &&
        user?.educations?.length > 0) ||
      (user?.enableDefaultProfileOption &&
        user?.certificates &&
        user?.certificates?.length > 0)
    ) {
      disableStatus();
    }
  }, [user]);

  const fetchCandidateDetails = useCallback(async (payload) => {
    try {
      const { success, data } = await getCandidateDetails();
      if (success) {
        dispatch(setUserData(data));
      }
    } catch (error) {
      throw error?.message;
    }
  }, []);

  const updateProfile = useCallback(
    async (payload) => {
      try {
        setIsLoading(true);
        if (activeTab === "experience") {
          setData((prev) => {
            return {
              ...prev,
              experience: {
                ...prev?.experience,
                isCompleted: true,
                isEditing: false,
              },
            };
          });
          nextStep();
          return;
        } else if (activeTab === "educationAndCertificate") {
          setData((prev) => {
            return {
              ...prev,
              educationAndCertificate: {
                ...prev?.educationAndCertificate,
                isCompleted: true,
                isEditing: false,
              },
            };
          });
          nextStep();
          return;
        }
        if (payload?.profileImage) {
          const { data } = await profileImage();
          if (!data?.data || !data?.data.uploadURL) {
            throw new Error("Failed to get upload URL");
          }
          const formData = new FormData();
          const base64Data = payload?.profileImage?.split(";base64,").pop();
          const byteArray = Uint8Array.from(atob(base64Data), (c) =>
            c.charCodeAt(0)
          );
          const blob = new Blob([byteArray], { type: payload?.file?.type });
          formData.append("file", blob, payload?.file?.name);
          const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
          delete payload["file"];
          delete payload["profileImage"];
          delete payload["image"];
          const picURl = fileResponse?.variants?.find(
            (x) => x.split("/").slice(-1)[0] === "public"
          );
          payload["profilePic"] = picURl;
        }
        const { success, data: userApiData } = await updateCandidate(payload);
        if (success) {
          dispatch(setUserData(userApiData));

          setData((prev) => {
            return {
              ...prev,
              [activeTab]: {
                ...prev?.[activeTab],
                isCompleted: true,
                isEditing: false,
              },
            };
          });

          if (user?.enableDefaultProfileOption) {
            await disableStatus();
            nextStep();
            return;
          }
          nextStep();
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    },
    [user?.enableDefaultProfileOption, activeTab, data]
  );

  return (
    <div className="flex flex-col rounded-lg bg-white p-4 flex-grow w-full">
      {/* Top container */}
      <div className="flex items-center bg-[#FAFAFA] py-3.5 pl-3.5 pr-[18px] rounded-xl border border-[#F7F7F7] mb-1 relative">
        {/* Image container */}
        <div
          ref={imageContainerRef}
          className="absolute top-0 flex rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center"
        >
          {user?.profilePic ? (
            <img
              src={user?.profilePic}
              className="rounded-full h-40 w-40 object-fill"
            />
          ) : (
            <span className="font-montserrat text-white font-semibold text-6xl">
              {user?.firstName?.charAt(0)}
              {user?.lastName?.charAt(0)}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between flex-1 gap-1 pl-44">
          <span className="font-montserrat text-primary font-semibold text-xl w-max">
            {user?.firstName} {user?.lastName}
          </span>
          <span className="font-montserrat text-light-black font-medium text-[11px] max-[900px]:w-min italic">
            {user?.refNo}
          </span>
        </div>
      </div>
      <div className="flex items-center pl-48 py-7">
        {tabs?.map((tab, tabIndex) => {
          const isSelected = tab?.value === activeTab;
          const isCompleted = data[tab?.value]?.isCompleted;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className="flex items-center"
            >
              {tabIndex !== 0 ? (
                <div className="h-px w-10 border-t border border-dashed mx-3" />
              ) : null}
              <span
                className={`font-medium text-xs ${
                  isSelected
                    ? "text-secondary"
                    : isCompleted
                    ? "text-green-700"
                    : "text-light-grey"
                }`}
              >
                {tab?.label}
              </span>
            </button>
          );
        })}
      </div>
      <button
        disabled={isLoading}
        onClick={() => {
          if (
            (isFilled &&
              !["educationAndCertificate", "experience"].includes(activeTab)) ||
            activeTab === "contactDetails"
          ) {
            handleNextClick();
          } else {
            nextStep();
          }
        }}
        className="flex font-medium rounded-md border w-max self-end px-4 py-1 text-sm mr-3 text-light-black hover:bg-grey-100"
      >
        {/* Skip */}
        {isLoading && <LoadingAnimation color={colors.PrimaryBlack} />}
        {activeTab === "contactDetails"
          ? "Submit"
          : (activeTab === "experience" && user?.experience?.length > 0) ||
            (activeTab === "skills" &&
              user?.experience?.some((exp) => exp?.skills?.length > 0)) ||
            (isFilled && !["experience", "skills"].includes(activeTab))
          ? "Next"
          : "Skip"}
      </button>

      {/* Body container */}
      <div className="flex-1 overflow-y-auto no-scrollbar pl-48 mt-5 flex flex-col">
        {activeTab === "experience" && user?.experience?.length !== 0 && (
          <div
            className="flex self-end h-9 items-center justify-center rounded border gap-[10px] px-2 py-1.5 mt-5 mr-3 cursor-pointer hover:bg-gray-50"
            onClick={() => {
              setIsEditing(!isEditing);
              setEditingTab(activeTab);
            }}
            disabled={isLoading}
          >
            <span className="font-montserrat text-primary font-medium text-xs">
              Edit
            </span>
          </div>
        )}
        <RenderBody
          ref={formRef}
          data={data}
          setData={setData}
          activeTab={activeTab}
          editingTab={editingTab}
          setEditingTab={setEditingTab}
          handleNext={updateProfile}
          handleEditClose={() => setEditingTab(false)}
          candidateId={user._id}
        />
      </div>
    </div>
  );
};

export default CreateProfile;

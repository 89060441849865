import { useEffect, useState, useRef } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  jobMessage,
  jobMessageList,
  updateMessageSeen,
} from "../../../endpoints/job.service";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import PaperSendIcon from "../../../assets/svg/PaperSendIcon";
import { socket } from "../../../App";
import { userTypes } from "../../../utils/constants";

const MessageView = () => {
  const { id } = useParams();
  const context = useOutletContext();
  const {
    selectedContact,
    isRecruiter,
    user,
    userName,
    isComposing,
    setIsComposing,
  } = context || {};
  const [messages, setMessages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const pollingInterval = 3000;

  const messagesContainerRef = useRef(null);
  const hasScrolledToBottom = useRef(false);
  const lastMessageCountRef = useRef(0);

  const scrollToBottom = (smooth = true) => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };

  const fetchMessages = async (shouldScroll = false) => {
    try {
      hasScrolledToBottom.current = false;
      let receiverId, jobApplicationId, jobId;

      if (user.userType === 3 && selectedContact.userDetails.userType === 4) {
        receiverId = selectedContact?.userDetails?._id;
        jobId = id;
      } else if (user.userType === 4) {
        receiverId = selectedContact?.userDetails?._id;
        jobId = id;
      } else {
        receiverId = isRecruiter ? user._id : selectedContact?.userDetails?._id;
        jobApplicationId = id;
      }

      if (!receiverId || (!jobApplicationId && !jobId)) return;

      const { success, data } = await jobMessageList(
        receiverId,
        jobApplicationId,
        jobId
      );

      if (success) {
        setMessages(data);

        const currentMessageCount = data[0]?.messageData?.length || 0;
        if (
          shouldScroll ||
          currentMessageCount !== lastMessageCountRef.current
        ) {
          setTimeout(() => scrollToBottom(shouldScroll), 100);
          lastMessageCountRef.current = currentMessageCount;
        }
      }
    } catch (error) {
      enqueueSnackbar(error + " Error fetching messages", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;
    fetchMessages(true);
    // interval = setInterval(() => fetchMessages(false), pollingInterval);
    // return () => clearInterval(interval);
  }, [selectedContact, user, pollingInterval]);

  useEffect(() => {
    if (messages.length > 0) {
      markMessagesAsSeen();
    }
  }, [messages]);

  useEffect(() => {
    // Remove any existing listeners for this event
    socket?.off("newMessage");

    // Add the listener once
    socket?.on("newMessage", (messageData) => {
      hasScrolledToBottom.current = false;
      console.log("New Message", messageData);
      if (
        messageData?.jobApplicationId === id &&
        messageData?.createdBy ===
          (selectedContact?.userDetails?.userType === userTypes.RECRUITER
            ? selectedContact?.userDetails?._id
            : selectedContact?.userDetails?.userId)
      ) {
        setMessages((prev) => {
          const newMessage = {
            messageId: messageData?._id,
            message: messageData?.message,
            createdName:
              selectedContact?.userDetails?.firstName +
              " " +
              selectedContact?.userDetails?.lastName,
            createdAt: messageData?.createdAt,
            seen: messageData?.seen,
            createdBy: messageData?.createdBy,
          };

          return prev?.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                messageData: [...item?.messageData, newMessage],
              };
            } else {
              return item;
            }
          });
        });
        if (!hasScrolledToBottom.current) {
          setTimeout(scrollToBottom, 100);
          hasScrolledToBottom.current = true;
        }
      }
      // Handle your message here
    });

    // Cleanup on component unmount
    return () => {
      socket?.off("newMessage");
    };
  }, []);

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedContact) return;
    try {
      setIsLoading(true);
      let payload = {};

      if (user.userType === 3 && selectedContact.userDetails.userType === 4) {
        payload = {
          jobId: id,
          message,
          receivedBy: selectedContact.userDetails._id,
        };
      } else if (user.userType === 4) {
        payload = {
          jobId: id,
          message,
          receivedBy: selectedContact.userDetails._id,
        };
      } else {
        payload = {
          jobApplicationId: id,
          message,
          receivedBy: isRecruiter
            ? selectedContact.userDetails._id
            : selectedContact.userDetails._id,
        };
      }

      const response = await jobMessage(payload);
      setMessage("");
      setIsComposing(false);

      fetchMessages(true);
      setTimeout(scrollToBottom, 100);
    } catch (error) {
      enqueueSnackbar(error + "Error sending message", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
    setMessage("");
    setIsComposing(false);
  };

  const markMessagesAsSeen = async () => {
    if (!messages[0]?.messageData?.length) return;

    const unseenMessageIds = messages[0].messageData
      .filter((msg) => !msg.seen)
      .map((msg) => msg.messageId);

    if (unseenMessageIds.length === 0) return;

    try {
      await updateMessageSeen({ messagesIds: unseenMessageIds });
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };

  if (!selectedContact || loading) {
    return (
      <div className="flex justify-center mt-40">
        <LoadingAnimation color="black" />
      </div>
    );
  }

  console.log("selected contact", selectedContact);

  return (
    <div className="flex flex-col h-full w-full relative">
      <div
        ref={messagesContainerRef}
        className="flex-grow overflow-y-auto no-scrollbar pr-2 pb-4"
        style={{ maxHeight: "calc(100% - 100px)" }}
      >
        {messages[0]?.messageData?.length ? (
          messages[0]?.messageData?.map((item, index) => {
            const isSentByCurrentUser = item.createdBy === user._id;
            const isSentByCandidate =
              item.createdBy === selectedContact.userDetails._id;
            return (
              <div
                key={index}
                className="flex flex-col gap-2 rounded cursor-pointer text-xs border-b p-4"
              >
                <div
                  className={`rounded text-primary flex justify-between font-bold`}
                >
                  <p className="text-[13px] font-bold text-primary">
                    {item.createdName}
                  </p>
                  <p className="text-primary font-medium italic">
                    {moment(item?.createdAt).format("HH:mm DD/MM/YYYY")}
                  </p>
                </div>
                <div className="flex justify-between items-end">
                  <p
                    className={`text-xs font-medium text-light-black py-3 px-5 rounded`}
                  >
                    {item.message}
                  </p>
                  {(isSentByCurrentUser || isSentByCandidate) && item.seen && (
                    <p className="text-xss text-green-600">Seen</p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-sm text-center flex h-full items-end justify-center pb-10">
            No messages available.
          </p>
        )}
      </div>

      {/* Fixed message input area */}
      <div className="absolute bottom-0 left-0 right-0 bg-white mx-5 mb-2">
        <div className="flex gap-4">
          <textarea
            className="p-4 w-full bg-white rounded text-xs font-medium border border-[#ECECEC] focus:border-gray-300 focus:outline-none"
            rows={4}
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            className="text-xs text-primary font-medium flex items-center disabled:cursor-not-allowed"
            onClick={handleSendMessage}
            disabled={isLoading || !message.trim() || !selectedContact}
          >
            {isLoading ? (
              <LoadingAnimation color="black" className={"w-4 h-4"} />
            ) : (
              <PaperSendIcon fill={message.trim() && "#FF9800"} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageView;

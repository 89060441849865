import "./App.css";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useRoutes from "./router/index.tsx";
import {
  refreshToken,
  setAuthLoading,
  setUserData,
} from "./store/actions/auth.action";
import { useEffect, useRef, useState } from "react";
import { getCandidateDetails } from "./endpoints/candidate.service";
import { setSearchData } from "./store/actions/search.action";
import { io } from "socket.io-client";
import env from "./env";

function IRouter() {
  const { router } = useRoutes();
  return <RouterProvider router={router} />;
}

let socket = null;

function App() {
  const dispatch = useDispatch();
  const { logged_in, loading, authenticated, user } = useSelector(
    (state) => state.auth
  );
  const [initialized, setInitialized] = useState(false);
  const initiating = useRef(false);

  // Handle socket connection
  const setupSocket = (token) => {
    if (!socket) {
      socket = io(env.apiLink, {
        autoConnect: false,
        auth: {
          token,
        },
        transports: ["websocket"],
      });
    }

    if (!socket.connected && token) {
      socket.connect();

      socket.on("connect", () => {
        console.log("Socket connected with authentication");
      });

      socket.on("newMessage", (messageData) => {
        console.log("New Message", messageData);
      });

      socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });
    }
  };

  // Clean up socket connection
  const cleanupSocket = () => {
    if (socket) {
      socket.off("connect");
      socket.off("connect_error");
      socket.disconnect();
    }
  };

  const loadInitialData = async (payload) => {
    // load initial data here
    if (payload?.userType === 3) {
      const timer = setTimeout(async () => {
        const { success, data } = await getCandidateDetails();
        if (success) {
          dispatch(setUserData(data));
        }
        clearTimeout(timer);
      }, 200);
    }
  };

  const initiateApp = async () => {
    initiating.current = true;
    const token = localStorage.getItem("refreshToken");

    if (!logged_in && token && !authenticated && initiating.current) {
      refreshToken(token)(dispatch)
        .then((data) => {
          if (data) {
            loadInitialData(data?.user);
            // Remove socket setup from here
            setTimeout(() => {
              setInitialized(true);
            }, 100);
          } else {
            window.location.replace("/");
          }
        })
        .finally(() => {
          initiating.current = false;
        });
    } else {
      dispatch(setAuthLoading(false));
      setTimeout(() => {
        setInitialized(true);
      }, 100);
      initiating.current = false;
    }
  };

  const feedSearchData = async () => {
    const searchData = JSON.parse(localStorage.getItem("searchData"));
    dispatch(setSearchData({ ...searchData }));
  };

  useEffect(() => {
    if (!initiating.current) {
      initiateApp();
    }
  }, []);

  useEffect(() => {
    if (logged_in) {
      feedSearchData();
    }
  }, [logged_in]);

  // Watch for token changes
  useEffect(() => {
    if (user?.token) {
      setupSocket(user.token || "");
    } else {
      cleanupSocket();
    }
  }, [user?.token]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupSocket();
    };
  }, []);

  // Add this new useEffect to handle page reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleBeforeUnload(); // Also cleanup when component unmounts
    };
  }, []);

  return !loading && initialized ? <IRouter /> : "Loading";
}

export { socket };
export default App;

import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const getJobs = async (payload) => {
  try {
    const { data } = await axios.post(api.getJobList, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getJobById = async (jobId) => {
  try {
    const { data } = await axios.get(api.getJobDetails + `/${jobId}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const shareJob = async (payload) => {
  try {
    const { data } = await axios.post(api.shareJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const verifyJob = async ({ email, otp }) => {
  try {
    const { data } = await axios.get(api.verifyJob + `/${email}/${otp}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const verifyJobStatus = async ({ email }) => {
  try {
    const { data } = await axios.get(api.verifyJobStatus + `/${email}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const addJob = async (payload) => {
  try {
    const { data } = await axios.post(api.addJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateJob = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteJob = async (jobId) => {
  try {
    const { data } = await axios.delete(api.deleteJob + jobId);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const publishUnpublish = async (jobId, status) => {
  try {
    const { data } = await axios.patch(
      api.publishUnpublish + "/" + jobId + "/" + status
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const applyJob = async (payload) => {
  try {
    const { data } = await axios.post(api.applyJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const applicantList = async (jobId) => {
  try {
    const { data } = await axios.get(api.applicantList + `/${jobId}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobMessageList = async (
  receiverId,
  jobApplicationId,
  jobId,
  createdAt
) => {
  try {
    const params = {};
    if (jobId) params.jobId = jobId;
    if (jobApplicationId) params.jobApplicationId = jobApplicationId;
    if (createdAt) params.createdAt = createdAt;

    const { data } = await axios.get(`/job/message/lists/${receiverId}/`, {
      params: Object.keys(params).length ? params : undefined,
    });

    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobMessage = async (payload) => {
  try {
    const { data } = await axios.post(api.jobMessage, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const candidateAppliedJobs = async () => {
  try {
    const { data } = await axios.get(api.getCandidateAppliedJobs);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobMembersProfile = async () => {
  try {
    const { data } = await axios.get(api.jobMembersProfile);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobAddTracker = async (payload) => {
  try {
    const { data } = await axios.post(api.jobAddTracker, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobGetTracker = async (jobId) => {
  try {
    const { data } = await axios.get(api.jobGetTracker + `/${jobId}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobUpdateApplicant = async (payload) => {
  try {
    const { data } = await axios.patch(api.jobUpdateApplicant, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const unseenMessageCount = async () => {
  try {
    const { data } = await axios.get(api.unseenMessageCount);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateMessageSeen = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateMessageSeen, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

import { Controller } from "react-hook-form";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";

const Description = ({ errors, control }) => {
  return (
    <div className="w-[80%] self-end">
      <div style={{ position: "relative" }} className="w-[80%] mt-5">
        <Controller
          label="Job Description"
          name="jobDescription"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
          render={({ field: { value, onChange } }) => (
            <QuillEditor
              label="Job Description"
              required
              value={value}
              onChange={onChange}
              errors={errors}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Description;

import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useSelector } from "react-redux";
import { isNullOrEmpty } from "../../../../utils/helperFunction";

const AssessmentSetupCard = ({ item, onClickAssessment }) => {
  return (
    <div
      onClick={() => onClickAssessment && onClickAssessment(item)}
      className={`flex items-center justify-between bg-[#fafafa] rounded-md pl-6 pr-4 py-4 hover:bg-gray-100 cursor-pointer ${
        !item.viewed && "border-2"
      }`}
    >
      <div className="flex items-center gap-5 h-full w-3/4">
        <div className="w-12 h-12 rounded-full justify-center items-center flex bg-[#E1E0E0] shadow-md">
          {item?.candidateDetails?.profilePic ? (
            <img
              src={item?.candidateDetails?.profilePic}
              className="h-full w-full object-cover rounded-full"
              alt="profileImg"
            />
          ) : (
            <span className="font-montserrat text-white font-semibold text-xl">
              {item?.candidateDetails?.firstName?.charAt(0).toUpperCase()}
              {item?.candidateDetails?.lastName?.charAt(0).toUpperCase()}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-3 w-2/5">
          <span className="text-xs text-light-black font-medium">
            Applied {moment(item?.createdAt).format("h:mm a DD MMMM YYYY")}
          </span>
          <div className="flex items-center justify-center rounded-sm bg-[#F1F5F9] border py-1">
            <span className="text-xs font-medium text-primary">
              {item?.candidateDetails?.firstName}{" "}
              {item?.candidateDetails?.lastName}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3 justify-end h-full">
          <div className="flex items-center rounded-sm bg-white py-1 px-4 w-64">
            <span className="text-xs font-medium text-primary">
              {isNullOrEmpty(item?.candidateDetails?.jobTitle)
                ? "N/A"
                : item?.candidateDetails?.jobTitle}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-end h-full">
        <div
          className={`flex flex-col gap-4 mr-6 ${
            !item.viewed && "self-center"
          }`}
        >
          {!item.viewed && (
            <div className="flex text-xs items-center gap-2">
              <p className="w-2 h-2 bg-[#23BD33] rounded-full"></p>
              <p>Unviewed</p>
            </div>
          )}
          <div className="rounded-md text-xs font-medium">
            {item?.candidateDetails?.refNo}
          </div>
        </div>
        <div className="flex items-center justify-center h-8 w-8 border-black border rounded-full bg-white font-bold self-start">
          R
        </div>
      </div>
    </div>
  );
};

const ApplicantSection = () => {
  const navigate = useNavigate();
  const { jobDetail } = useSelector((state) => state?.job);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      search: undefined,
      searchBy: "Search by",
    },
  });
  const fields = watch();

  const onClickAssessment = useCallback(
    (assessment) => {
      navigate("/job-applicant-detail", {
        state: {
          item: assessment,
        },
      });
    },
    [navigate]
  );

  if (jobDetail.applicantData.length === 0) {
    return (
      <div className="text-primary font-medium flex justify-center items-center h-full">
        No job applicants yet.
      </div>
    );
  }

  return (
    <>
      <div className="flex  items-center justify-between w-full gap-8 bg-[#F1F5F9] rounded-md px-3 py-1 mt-3">
        <div className="flex flex-1 w-full items-center gap-8">
          {/* Input container */}
          <div className="flex w-[50%] rounded-lg gap-3 items-center justify-between bg-light-white-500 py-1.5">
            <CustomInput
              key={fields.searchBy}
              name="search"
              control={control}
              className="border-none"
              errors={errors}
              placeholder="Search"
              type={"text"}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar py-5 gap-2">
        {jobDetail.applicantData.map((item) => {
          return (
            <AssessmentSetupCard
              key={item._id}
              item={item}
              onClickAssessment={onClickAssessment}
            />
          );
        })}
      </div>
    </>
  );
};

export default ApplicantSection;

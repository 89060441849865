import { combineReducers } from "redux";

// reducers
import auth from "./auth.reducer";
import company from "./company.reducer";
import job from "./job.reducer";
import assessment from "./assessment.reducer";
import candidate from "./candidate.reducer";
import search from "./search.reducer";
import messageReducer from "./message.reducer";

export default combineReducers({
  auth,
  company,
  job,
  assessment,
  candidate,
  search,
  messageReducer,
});

import { useEffect, useRef, useState } from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { changePassword } from "../../endpoints/user.service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import regexList from "../../utils/regexList";
import { updateCandidate } from "../../endpoints/candidate.service";
import { updateUserName } from "../../endpoints/auth.service";
import { setUserData } from "../../store/actions/auth.action";

const SettingsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("account");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState("personal");

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNo: user.phoneNo ?? "N/A",
      userName: user.userName,
      referralName:
        user?.referralDetail?.firstName + " " + user?.referralDetail?.lastName,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNo: user.phoneNo ?? "N/A",
      userName: user.userName,
      referralName:
        user?.referralDetail?.firstName + " " + user?.referralDetail?.lastName,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [user]);

  useEffect(() => {
    if (activeTab === "account") {
      setActiveSidebar("personal");
    } else if (activeTab === "security") {
      setActiveSidebar("changePassword");
    }
  }, [activeTab]);

  const toggleEdit = () => {
    if (isEditing) {
      handleSubmit(onSubmit)();
    } else {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNo: user.phoneNo ?? "N/A",
        userName: user.userName,
        referralName:
          user?.referralDetail?.firstName +
          " " +
          user?.referralDetail?.lastName,
      });
    }
    setIsEditing(!isEditing);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const phoneNoToSend = data.phoneNo === "N/A" ? undefined : data.phoneNo;
      // Update first name and last name
      const { success } = await updateCandidate({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNo: phoneNoToSend,
      });

      if (success) {
        setValue("firstName", data.firstName);
        setValue("lastName", data.lastName);
        setValue("email", data.email);
        setValue("phoneNo", phoneNoToSend);
        setValue("referralName", data.referralName);

        dispatch(
          setUserData({
            ...user,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNo: phoneNoToSend,
          })
        );

        enqueueSnackbar("Personal details updated successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error || "Failed to update personal details", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Copied to clipboard", { variant: "success" });
  };

  const handleUpdatePassword = async (data) => {
    setIsLoading(true);
    const payload = data;
    payload.id = user?.userId;
    delete payload.confirmPassword;
    try {
      const { success, data } = await changePassword(payload);
      if (success) {
        enqueueSnackbar("Password changed successfully.", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const toggleUsernameEdit = () => {
    if (isEditingUsername) {
      trigger("userName").then((isValid) => {
        if (isValid) {
          handleUpdateUsername();
        }
      });
    } else {
      setIsEditingUsername(true);
    }
  };

  const handleUpdateUsername = async () => {
    try {
      setIsLoading(true);
      const newUsername = watch("userName");

      const success = await updateUserNameApi(newUsername);

      if (success) {
        setValue("userName", newUsername);

        dispatch(
          setUserData({
            ...user,
            userName: newUsername,
          })
        );
      }
    } catch (error) {
      console.error("Error updating username:", error);
    } finally {
      setIsLoading(false);
      setIsEditingUsername(false);
    }
  };

  const updateUserNameApi = async (userName) => {
    try {
      setIsLoading(true);
      await updateUserName({ userName: userName });

      enqueueSnackbar("Username updated successfully", {
        variant: "success",
      });

      return true;
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSidebarChange = (item) => {
    setActiveSidebar(item);
  };

  const usernameValidationRules = {
    required: "Username is required",
    minLength: {
      value: 5,
      message: "Username must be at least 5 characters long",
    },
    pattern: {
      value: /^[a-zA-Z0-9_]+$/,
      message: "Username can only contain letters, numbers, and underscores",
    },
  };

  return (
    <div className="flex flex-col flex-1 h-full rounded-lg bg-white w-full flex-grow p-6 overflow-hidden">
      <div className="border-b border-gray-200">
        <div className="flex space-x-32 text-xs px-48">
          <p
            onClick={() => handleTabChange("account")}
            className={`cursor-pointer ${
              activeTab === "account"
                ? "text-primary font-medium border-b-2 border-primary"
                : "text-gray-500 hover:text-gray-700"
            } pb-4 px-1 -mb-px`}
          >
            Account
          </p>
          <p
            onClick={() => handleTabChange("security")}
            className={`cursor-pointer ${
              activeTab === "security"
                ? "text-primary font-medium border-b-2 border-primary"
                : "text-gray-500 hover:text-gray-700"
            } pb-4 px-1 -mb-px`}
          >
            Password and Security
          </p>
        </div>
      </div>

      {activeTab === "account" && (
        <div className="flex flex-col md:flex-row mt-6 gap-6 h-full">
          {/* Sidebar */}
          <div className="w-full md:w-64 shrink-0 border-r pr-1">
            <div className="rounded-md text-xs space-y-2">
              <div
                onClick={() => handleSidebarChange("personal")}
                className={`px-4 py-3 cursor-pointer rounded-md ${
                  activeSidebar === "personal"
                    ? "bg-gray-100 font-medium"
                    : "text-gray-700 hover:bg-gray-50"
                }`}
              >
                Personal Details
              </div>
              <div
                onClick={() => handleSidebarChange("username")}
                className={`px-4 py-3 cursor-pointer rounded-md ${
                  activeSidebar === "username"
                    ? "bg-gray-100 font-medium"
                    : "text-gray-700 hover:bg-gray-50"
                }`}
              >
                Username
              </div>
              <div
                onClick={() => handleSidebarChange("referral")}
                className={`px-4 py-3 cursor-pointer rounded-md ${
                  activeSidebar === "referral"
                    ? "bg-gray-100 font-medium"
                    : "text-gray-700 hover:bg-gray-50"
                }`}
              >
                Referral Member
              </div>
            </div>
          </div>

          {/* Personal Details Content */}
          {activeSidebar === "personal" && (
            <div className="flex-1 bg-white px-6 py-10 rounded-md border border-gray-200 text-xs h-fit">
              <div className="flex justify-between items-center mb-6">
                <h2 className="font-medium">Personal Details</h2>
                <button
                  onClick={toggleEdit}
                  className="text-xs text-primary"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <LoadingAnimation color={"black"} />
                  ) : isEditing ? (
                    "Save"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div className="mb-4">
                  <CustomInput
                    label="First name"
                    name="firstName"
                    control={control}
                    errors={errors}
                    disabled={!isEditing || isLoading}
                    rules={
                      isEditing && {
                        required: "This field is required.",
                      }
                    }
                  />
                </div>

                <div className="mb-4">
                  <CustomInput
                    label="Last name"
                    name="lastName"
                    control={control}
                    errors={errors}
                    disabled={!isEditing || isLoading}
                    rules={
                      isEditing && {
                        required: "This field is required.",
                      }
                    }
                  />
                </div>

                <div className="mb-4">
                  <CustomInput
                    label="Email Address"
                    name="email"
                    control={control}
                    errors={errors}
                    disabled={true}
                  />
                </div>

                <div className="mb-4">
                  <CustomInput
                    label="Contact Number"
                    name="phoneNo"
                    control={control}
                    errors={errors}
                    disabled={!isEditing || isLoading}
                    rules={
                      isEditing && {
                        required: "This field is required.",
                      }
                    }
                  />
                </div>
              </form>
            </div>
          )}

          {/* Username Content */}
          {activeSidebar === "username" && (
            <div className="flex-1 bg-white px-6 py-14 rounded-md border border-gray-200 text-xs h-fit">
              <p className="text-xs font-semibold mb-1">Username</p>
              <div className="mb-8 flex items-center">
                <div className="flex-grow">
                  <CustomInput
                    name="userName"
                    rules={usernameValidationRules}
                    control={control}
                    errors={errors}
                    disabled={!isEditingUsername || isLoading}
                  />
                </div>
                <button
                  className="text-xs text-primary ml-5 h-9 flex items-center w-1/12"
                  onClick={toggleUsernameEdit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <LoadingAnimation color={"black"} />
                  ) : isEditingUsername ? (
                    "Update"
                  ) : (
                    "Change"
                  )}
                </button>
              </div>
              <div className="flex items-center">
                <div className="py-2.5 px-3 rounded-lg bg-light-white-700 border border-lightgray font-montserrat font-medium text-xs flex-grow">
                  {`https://comtracked/profile/${watch("userName")}`}
                </div>
                <button
                  className="text-xs text-primary ml-5 h-9 flex items-center w-1/12"
                  onClick={() =>
                    copyToClipboard(
                      `https://comtracked/profile/${watch("userName")}`
                    )
                  }
                >
                  Copy
                </button>
              </div>
            </div>
          )}

          {/* Referral Content */}
          {activeSidebar === "referral" && (
            <div className="flex-1 bg-white px-6 py-14 rounded-md border border-gray-200 text-xs h-fit">
              <p className="text-xs mb-1">Your Referral Member</p>
              <div className="mb-8 flex items-center">
                <div className="flex-grow">
                  <CustomInput
                    name="referralName"
                    control={control}
                    errors={errors}
                    disabled={!isEditing}
                    rules={{
                      required: "This field is required.",
                    }}
                  />
                </div>
                <button
                  className="text-xs text-primary ml-5 h-9 flex items-center"
                  // onClick={() => setIsEditing(true)}
                >
                  {isEditing ? "Update" : "Change"}
                </button>
              </div>
              <div className="py-2.5 px-3 rounded-lg bg-light-white-700 border border-lightgray font-montserrat font-medium text-xs">
                {`https://comtracked/profile/${user?.referralDetail?.userName}`}
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === "security" && (
        <div className="flex flex-col md:flex-row mt-6 gap-6 h-full">
          <div className="w-full md:w-64 shrink-0 border-r pr-1">
            <div className="rounded-md text-xs">
              <div
                onClick={() => handleSidebarChange("changePassword")}
                className={`px-4 py-3 cursor-pointer ${
                  activeSidebar === "changePassword"
                    ? "bg-gray-100 font-medium rounded-md"
                    : "text-gray-700 hover:bg-gray-50"
                }`}
              >
                Change Password
              </div>
              <div
                onClick={() => handleSidebarChange("loggedIn")}
                className={`px-4 py-3 cursor-pointer ${
                  activeSidebar === "loggedIn"
                    ? "bg-gray-100 font-medium rounded-md"
                    : "text-gray-700 hover:bg-gray-50"
                }`}
              >
                Where you're logged in
              </div>
            </div>
          </div>

          {activeSidebar === "changePassword" && (
            <div className="flex-1 bg-white p-6 rounded-md border border-gray-200 text-xs h-fit">
              <form
                onSubmit={handleSubmit(handleUpdatePassword)}
                className="flex flex-col gap-5 mt-5"
              >
                <div className="flex flex-col gap-3 w-80 mx-auto mt-12">
                  <CustomInput
                    label={"Current Password"}
                    name="oldPassword"
                    placeholder={"Enter your Current Password"}
                    rules={{
                      required: "This field is required.",
                      pattern: {
                        value: regexList.password,
                        message: "Please enter a valid password.",
                      },
                    }}
                    control={control}
                    errors={errors}
                  />

                  <CustomInput
                    label={"New Password"}
                    name="newPassword"
                    placeholder={"Enter New Password"}
                    rules={{
                      required: "This field is required.",
                      pattern: {
                        value: regexList.password,
                        message: "Please enter a valid password.",
                      },
                      validate: (value) =>
                        value !== watch("oldPassword") ||
                        "New password should not match with old password.",
                    }}
                    control={control}
                    errors={errors}
                  />

                  <CustomInput
                    label={"Confirm New Password"}
                    name="confirmPassword"
                    placeholder={"Enter New Password"}
                    rules={{
                      required: "This field is required.",
                      validate: (value) =>
                        value === watch("newPassword") ||
                        "Confirm Password do not match.",
                    }}
                    control={control}
                    errors={errors}
                  />

                  <div className="flex justify-center mt-14">
                    <button
                      type="submit"
                      disabled={!isDirty}
                      className={`flex gap-3 bg-primary text-white rounded-md px-3 py-2 text-sm font-medium ${
                        isDirty ? "opacity-100" : "opacity-50"
                      }`}
                    >
                      Change Password
                      {isLoading && <LoadingAnimation color={"black"} />}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {activeSidebar === "loggedIn" && (
            <div className="flex-1 bg-white p-6 rounded-md border border-gray-200 text-xs h-fit">
              <p>You are logged in</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SettingsPage;

import CustomInput from "../../components/CustomInput/CustomInput";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "../../assets/svg/SearchIcon";
import DownArrow from "../../assets/svg/DownArrow";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { contactMatchesJob } from "../../endpoints/referral.service";
import moment from "moment";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { isNullOrEmpty } from "../../utils/helperFunction";

const MatchesPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [matchesList, setMatchesList] = useState();
  const hasFetched = useRef(false);
  const {
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchMatches();
    }
  }, []);

  const fetchMatches = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await contactMatchesJob();
      if (success) {
        setMatchesList(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const statusCounts = matchesList?.reduce(
    (acc, item) => {
      const status = item?.candidateDetails?.status?.toLowerCase();
      if (status === "open") acc.open++;
      else if (status === "available") acc.available++;
      else if (status === "unavailable") acc.unavailable++;
      return acc;
    },
    { open: 0, available: 0, unavailable: 0 }
  );

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-9 h-full">
      {isLoading ? (
        <div className="flex flex-1 flex-col items-center justify-center">
          <LoadingAnimation color={"black"} />
        </div>
      ) : isNullOrEmpty(matchesList) ? (
        <div className="flex flex-1 flex-col items-center justify-center font-semibold">
          No Matches found.
        </div>
      ) : (
        <div className="flex flex-1 flex-col max-h-fit overflow-y-hidden justify-center items-center h-full">
          <div className="flex items-stretch w-full">
            <div className="flex rounded mb-5 cursor-pointer">
              <p className="border border-[#ECECEC] bg-[#F1F5F9] px-5 py-2 text-xs rounded text-light-black font-semibold">
                Possible matches
              </p>
              <p className="border border-[#ECECEC] bg-white px-8 py-2 text-xs">
                {statusCounts?.open}{" "}
                <span className="text-secondary">Open</span>
              </p>
              <p className="border border-[#ECECEC] bg-white px-8 py-2 text-xs rounded-l">
                {statusCounts?.available}{" "}
                <span className="text-green-700">Available</span>
              </p>
              <p className="border border-[#ECECEC] bg-white px-8 py-2 text-xs rounded-r">
                {statusCounts?.unavailable}{" "}
                <span className="text-light-black">Unavailable</span>
              </p>
            </div>
          </div>

          <div className="flex justify-between w-full">
            <div className="grid grid-cols-[2fr,0.3fr,2fr,0.5fr] items-stretch w-full bg-[#F1F5F9] p-3 rounded">
              <div className="flex gap-1.5 items-center">
                <CustomInput
                  name="search"
                  control={control}
                  className="rounded"
                  errors={errors}
                  placeholder="Search"
                  renderRight={<SearchIcon fill="#6f6f6f" />}
                />
                <CustomInput
                  name="search"
                  control={control}
                  className="rounded placeholder:text-[10px]"
                  errors={errors}
                  placeholder="Availability filter"
                  renderRight={<DownArrow fill="#6f6f6f" />}
                />
              </div>

              <div></div>

              <Controller
                name="search"
                control={control}
                render={() => (
                  <div className="relative w-1/4 mr-1 flex justify-self-end h-full">
                    <input
                      type="text"
                      className="w-full h-full border rounded-md p-2 pr-8 placeholder:text-[10px] text-[10px]"
                      placeholder="Sort by"
                    />
                    <DownArrow
                      fill="#6f6f6f"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm cursor-pointer"
                    />
                  </div>
                )}
              />

              <Controller
                name="search"
                control={control}
                render={() => (
                  <div className="relative w-[85%] flex justify-self-center h-full">
                    <input
                      type="text"
                      className="w-full h-full border rounded-md pr-8 placeholder:text-center placeholder:text-[10px] text-[10px]"
                      placeholder="Filter"
                    />
                    <DownArrow
                      fill="#6f6f6f"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm cursor-pointer"
                    />
                  </div>
                )}
              />
            </div>
          </div>

          {/* List */}
          <div className="flex flex-col overflow-auto mt-8 no-scrollbar w-full">
            {matchesList?.map((item, index) => (
              <div
                key={index}
                className="group grid grid-cols-[2fr,2fr,0.5fr] items-stretch w-full bg-[#FAFAFA] gap-2 relative cursor-pointer"
                onClick={() =>
                  navigate(
                    `/matches/${item?.candidateDetails?.candidateId}/${item?.jobDetails?.jobId}`
                  )
                }
              >
                <div className="flex border border-[#F7F7F7] py-3.5 px-3 bg-white gap-4 flex-1 justify-between group-hover:bg-gray-100">
                  <div className="flex items-center gap-4">
                    <div className="h-12 w-12 rounded-full justify-center items-center flex bg-[#E1E0E0] bg-nwhited shadow-xl">
                      {item?.candidateDetails?.profilePic ? (
                        <img
                          src={item?.candidateDetails?.profilePic}
                          className="h-full w-full object-cover rounded-full"
                          alt="profileImg"
                        />
                      ) : (
                        <span className="font-montserrat text-white font-semibold text-xl">
                          {item?.candidateDetails?.name?.charAt(0)}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-3 justify-between">
                      <span className="font-semibold text-primary text-xs">
                        {item?.candidateDetails.name ?? "N/A"}
                      </span>
                      <span className="font-medium text-primary text-xs">
                        {item?.candidateDetails.jobTitle ?? "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-between gap-3 mr-10">
                    <p className="font-medium text-green-700 text-xs">
                      {item?.candidateDetails?.status
                        ? item.candidateDetails.status.charAt(0).toUpperCase() +
                          item.candidateDetails.status.slice(1).toLowerCase()
                        : "N/A"}
                    </p>
                    <p className="text-[9px] text-primary">
                      {item?.candidateDetails.refNo ?? "N/A"}
                    </p>
                  </div>
                </div>

                <div className="text-xs border-b font-medium italic text-light-black bg-[#F1F5F9] rounded flex items-center justify-center px-5 py-1.5 absolute top-[30%] right-[52.5%]">
                  {item?.totalMatchPercentage}%
                </div>

                <div className="flex border border-[#F7F7F7] py-3.5 px-3 bg-white gap-4 flex-1 justify-between group-hover:bg-gray-100">
                  <div className="flex flex-col justify-between gap-3 ml-10">
                    <span className="font-medium text-light-black text-[10px]">
                      {item.jobDetails.refNo}
                    </span>
                    <span className="font-semibold text-primary text-xs">
                      {item.jobDetails.title}
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <div className="flex flex-col justify-between gap-3 text-[10px]">
                      <span className="text-primary">Post</span>
                      <span className="text-primary">Matched</span>
                    </div>
                    <div className="flex flex-col justify-between gap-3 text-[10px]">
                      <span className="text-light-black">
                        {" "}
                        {moment(item.createdAt).format("DD/MM/YY")}
                      </span>
                      <span className="text-light-black">18/12/24</span>
                    </div>
                  </div>
                </div>

                <div className="border border-[#F7F7F7] bg-white flex justify-center min-w-24 px-2.5 py-1 group-hover:bg-gray-100">
                  <div
                    className={`py-2 px-3 text-xs flex flex-col text-primary items-center ${
                      !item.appliedJobCreatedAt
                        ? "justify-center"
                        : "justify-between"
                    }`}
                  >
                    <p className="font-medium text-[10px] py-1 px-5 rounded bg-[#F1F5F9] text-center">
                      {item.appliedJobStatus}
                    </p>
                    {item.appliedJobCreatedAt && (
                      <div className="flex gap-2.5 text-light-black italic font-medium text-[10px]">
                        <p>
                          {moment(item.appliedJobCreatedAt).format("HH:mm")}
                        </p>
                        <p>
                          {moment(item.appliedJobCreatedAt).format("DD/MM/YY")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchesPage;

import LocationIcon from "../../../../assets/svg/LocationIcon";
import MailIcon from "../../../../assets/svg/MailIcon";
import PhoneOutline from "../../../../assets/svg/PhoneOutline";
import profilePic1 from "../../../../assets/profilePic1.png";

const ApplicationTab = ({ applicationDetails }) => {
  return (
    <div className="w-full flex gap-6 h-screen overflow-hidden">
      {/* Profile Card */}
      <div className="w-3/5  overflow-y-auto h-full no-scrollbar">
        <div className="bg-white border border-[#ECECEC] rounded-lg py-6 px-16 mb-8 space-y-4">
          <h2 className="text-2xl font-semibold">
            {applicationDetails?.candidateDetails?.firstName +
              " " +
              applicationDetails?.candidateDetails?.lastName ?? "-"}
          </h2>
          <div className="space-y-2">
            <div className="flex items-center text-primary gap-3">
              <LocationIcon />
              <span className="text-xs">
                {applicationDetails?.candidateDetails?.location ?? "-"}
              </span>
            </div>
            <div className="flex items-center text-primary gap-3">
              <MailIcon />
              <span className="text-xs">
                {applicationDetails?.candidateDetails?.email ?? "-"}
              </span>
            </div>
            <div className="flex items-center text-primary gap-3">
              <PhoneOutline />
              <span className="text-xs">
                {applicationDetails?.candidateDetails?.phoneNo ?? "-"}
              </span>
            </div>
          </div>
        </div>

        <div className="mb-24 ml-16">
          <h3 className="font-bold mb-10 text-sm">Documents included</h3>
          <div className="ml-8">
            <div className="space-y-11">
              <div className="space-y-2">
                <p className="text-[13px] font-bold text-primary">Resume</p>
                <p className="text-[13px] font-medium text-primary">
                  No resume uploaded
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-[13px] font-bold text-primary">
                  Cover Letter
                </p>
                <p className="text-[13px] font-medium text-primary">
                  No cover letter uploaded
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-[13px] font-bold text-primary">
                  Your COMTRACKED profile
                </p>
                <p className="text-[13px] font-medium text-primary">
                  When you apply on COMTRACKED, your COMTRACKED Profile
                  including any verified credentials will be accessed by the
                  recruitment agent and employer.
                </p>
              </div>
            </div>
            <p className="bg-[#F1F5F9] py-3 px-5 mt-4 text-xs text-primary font-medium rounded-lg flex justify-self-start cursor-pointer">
              https://comtracked.com/profile/SarahCole
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#F1F5F9] p-4 space-y-3 rounded-lg w-2/5 h-3/5 overflow-y-hidden">
        <div className="flex justify-between">
          {applicationDetails?.candidateDetails?.profilePic ? (
            <img
              src={applicationDetails?.candidateDetails?.profilePic}
              className="rounded-full w-14 h-14 object-fill z-10 max-md:h-36 max-md:w-36"
            />
          ) : (
            <div className="font-montserrat text-white font-semibold uppercase w-14 h-14 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails?.candidateDetails?.firstName?.charAt(0)}
              {applicationDetails?.candidateDetails?.lastName?.charAt(0)}
            </div>
          )}
          <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded h-max">
            {applicationDetails?.candidateDetails?.refNo ?? "-"}
          </span>
        </div>
        <div className="text-primary space-y-2 text-xs">
          <p className="font-bold">
            {" "}
            {applicationDetails?.candidateDetails?.firstName +
              " " +
              applicationDetails?.candidateDetails?.lastName ?? "-"}
          </p>
        </div>
        <div className="flex flex-col gap-3.5">
          <span className="font-medium text-primary text-xs flex-1">
            {applicationDetails?.candidateDetails?.jobTitle ?? "-"}
          </span>
          <div className="flex flex-col gap-3.5 mb-6">
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Location:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.candidateDetails?.location ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Industry Type:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.candidateDetails?.jobTitle ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Job Type:</span>
              <span className="font-medium text-primary text-xs flex-1">
                Contract
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Salary Range:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                £{applicationDetails?.jobDetails?.salary?.min} - £
                {applicationDetails?.jobDetails?.salary?.max}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationTab;

import { useEffect, useState } from "react";
import { getSkills } from "../../../../../endpoints/candidate.service";
import { useSnackbar } from "notistack";
import { JobSkills } from "../../../../../utils/constants";
import LoadingAnimation from "../../../../../assets/svg/LoadingAnimation";

const SkillsMatchTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [skills, setSkills] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = async (id = applicationDetails.candidateId) => {
    try {
      setLoading(true);
      const { data, success } = await getSkills(id);
      if (success) {
        setSkills(data);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex gap-6 h-screen overflow-hidden">
      {/* Profile Card */}
      <div className="w-3/5 overflow-y-auto h-full no-scrollbar">
        <div className="bg-[#FAFAFA] py-8 px-5">
          {loading ? (
            <div className="flex flex-1 flex-col items-center justify-center">
              <LoadingAnimation color={"black"} />
            </div>
          ) : skills?.length === 0 ? (
            <div className="flex justify-center text-sm font-semibold">
              No Skills were added.
            </div>
          ) : (
            <>
              <div className="grid flex-1 mb-2 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr]">
                {["Skill", "Rating", "Years", "When", ""].map((label) => (
                  <span
                    key={label}
                    className="text-xs font-semibold text-gray-700 flex-1"
                  >
                    {label}
                  </span>
                ))}
              </div>
              <div className="overflow-auto h-full">
                {skills?.map((skill) => {
                  return (
                    <div
                      key={skill?._id}
                      className="grid space-x-3 py-2 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr]"
                    >
                      {[
                        skill.title,
                        `${skill.rating}/10`,
                        skill.years,
                        JobSkills.find((j) => j.value === skill.lastUsed)
                          ?.label || skill.lastUsed,
                      ].map((value, index) => (
                        <div
                          key={index}
                          className="border border-[#FAFAFA] bg-white px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1"
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bg-[#F1F5F9] p-4 space-y-3 rounded-lg w-2/5 h-3/5 overflow-y-hidden">
        <div className="flex justify-between">
          {applicationDetails?.profilePic ? (
            <img
              src={applicationDetails?.profilePic}
              className="rounded-full w-14 h-14 object-fill z-10 max-md:h-36 max-md:w-36"
            />
          ) : (
            <div className="font-montserrat text-white font-semibold uppercase w-14 h-14 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails?.firstName?.charAt(0)}
              {applicationDetails?.lastName?.charAt(0)}
            </div>
          )}
          <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded h-max">
            {applicationDetails?.refNo ?? "CA - 123ABC"}
          </span>
        </div>
        <div className="text-primary space-y-2 text-xs">
          <p className="font-bold">
            {applicationDetails?.firstName} {applicationDetails?.lastName}
          </p>
        </div>
        <div className="flex flex-col gap-3.5">
          <span className="font-medium text-primary text-xs flex-1">
            {applicationDetails?.jobTitle ?? "-"}
          </span>
          <div className="flex flex-col gap-3.5 mb-6">
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Location:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.location ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Industry Type:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.candidateDetails?.jobTitle ?? "Finance"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Job Type:</span>
              <span className="font-medium text-primary text-xs flex-1">
                Full Time
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Salary Range:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                £60,000 - £80,000
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsMatchTab;

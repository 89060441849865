import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import ResetIcon from "../../assets/svg/ResetIcon";
import { useCallback, useEffect, useState, useMemo } from "react";
import {
  generateUserNames,
  updateUserName,
} from "../../endpoints/auth.service";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { userTypes } from "../../utils/constants";
import { setUserData } from "../../store/actions/auth.action";

const GenerateUserNamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const {
    email,
    isSignup,
    firstName,
    lastName,
    userType,
    user: paramUser,
  } = location.state || {};
  const [userNameSuggestions, setUserNameSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const type = localStorage.getItem("userType");

  const isReferrealAmbassador =
    (Number(type) || user?.userType) === userTypes.REFERRAL_AMBASSADOR;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      username: "",
    },
  });

  const fields = watch();
  const firstNameToUse = useMemo(
    () => user?.firstName || firstName,
    [user, firstName]
  );
  const lastNameToUse = useMemo(
    () => user?.lastName || lastName,
    [user, lastName]
  );

  const fetchUserNames = useCallback(async () => {
    if (firstNameToUse && lastNameToUse) {
      try {
        const { data, success } = await generateUserNames(
          firstNameToUse,
          lastNameToUse
        );
        if (success) {
          setUserNameSuggestions(data);
        } else {
          enqueueSnackbar("Failed to fetch username suggestions", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(error.message || "Error fetching usernames", {
          variant: "error",
        });
      }
    }
  }, [firstNameToUse, lastNameToUse, enqueueSnackbar]);

  useEffect(() => {
    fetchUserNames();
  }, [fetchUserNames]);

  const updateUserNameApi = async (userName) => {
    try {
      setIsLoading(true);
      await updateUserName({ userName: userName });

      dispatch(setUserData({ userName, ...user }));
      enqueueSnackbar("Username updated successfully", {
        variant: "success",
      });

      return true;
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleUsernameSelect = (username) => {
    setValue("username", username);
  };

  const handleNext = async () => {
    if (isLoading) return;

    const isSuccess = await updateUserNameApi(fields.username);

    if (!isSuccess) {
      return enqueueSnackbar("Something went wrong. Please try again", {
        variant: "error",
      });
    }

    if (type) {
      localStorage.removeItem("userType");
    }

    const redirectTo =
      user?.userType === userTypes.CANDIDATE
        ? "/job-search"
        : user?.userType === userTypes.REFERRAL_AMBASSADOR
        ? "/network"
        : "/search";

    if (user) {
      navigate(redirectTo, { replace: true });
    } else {
      navigate("/email-verification", {
        state: { email, isSignup, userType, user: paramUser },
        relative: true,
      });
    }
  };

  const usernameValidationRules = {
    required: "Username is required",
    minLength: {
      value: 5,
      message: "Username must be at least 5 characters long",
    },
    pattern: {
      value: /^[a-zA-Z0-9_]+$/,
      message: "Username can only contain letters, numbers, and underscores",
    },
  };

  return (
    <div className="flex flex-col self-center justify-center h-full w-6/12">
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg w-full py-10 px-20 relative bg-white self-center justify-self-center">
        <label className="font-semibold text-3xl mb-2">Select username</label>
        <label className="text-light-black font-medium px-10 text-center">
          Pick a username for your account. You can always change it later.
        </label>
        <form
          className="flex flex-col w-full pt-10 overflow-y-auto no-scrollbar h-max gap-1"
          onSubmit={handleSubmit(handleNext)}
        >
          <div className="flex flex-col items-start gap-2">
            <CustomInput
              placeholder={"@username"}
              name="username"
              rules={usernameValidationRules}
              control={control}
              errors={errors}
              renderRight={<ResetIcon />}
              className={`${
                fields?.username ? "border-black" : "border-light-grey"
              }`}
            />
            <div className="flex gap-2 flex-wrap">
              {userNameSuggestions.map((item, index) => (
                <p
                  key={index}
                  className="border border-light-grey rounded-[20px] bg-[#F7F7F7] py-1.5 px-4 text-xs cursor-pointer"
                  onClick={() => handleUsernameSelect(item)}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`${
              isReferrealAmbassador ? "bg-primary" : "bg-secondary"
            } flex items-center justify-center rounded-full mt-10 py-3`}
          >
            {isLoading && <LoadingAnimation />}
            <span className="text-white font-medium">Next</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default GenerateUserNamePage;

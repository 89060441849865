import { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import SkillsMatchTab from "./components/SkillsMatchTab";
import ProfileTab from "./components/ProfileTab";
import MessagesTab from "./components/MessagesTab";
import TrackerTab from "./components/TrackerTab";
import { contactMatchesSkills } from "../../endpoints/referral.service";

const tabs = [
  {
    title: "Skills Match",
    value: 0,
  },
  // {
  //   title: "View profile",
  //   value: 1,
  // },
  {
    title: "Messages",
    value: 2,
  },
  // {
  //   title: "Tracker",
  //   value: 3,
  // },
];

const MatchesDetailsPage = () => {
  const navigate = useNavigate();
  const { candidateId, jobId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [matchSkills, setMatchSkills] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchContactMatcheSkills(candidateId, jobId);
    }
  }, []);

  const fetchContactMatcheSkills = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await contactMatchesSkills(candidateId, jobId);
      if (success) {
        setMatchSkills(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <SkillsMatchTab
            applicationDetails={matchSkills}
            isLoading={isLoading}
          />
        );
      case 1:
        return <ProfileTab profileDetails={matchSkills} />;
      case 2:
        return <MessagesTab applicationDetails={matchSkills} />;
      case 3:
        return <TrackerTab applicationDetails={matchSkills} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow px-6 py-2 overflow-hidden">
      <button
        onClick={() => navigate(`/matches`)}
        className="flex items-center self-start my-3"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back
        </label>
      </button>

      <button className="py-2 px-5 text-xs text-light-black font-medium bg-[#F1F5F9] border border-[#ECECEC] rounded self-center">
        Submit referral to agent
      </button>

      {/* Tabs container */}
      <div className="flex items-center border-b border-gray-300 gap-16 mt-5 pb-2 pl-4">
        {tabs?.map((tab) => {
          const isActive = tab?.value === activeTab;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className={`flex items-center ${
                isActive ? "border-b border-primary" : ""
              }`}
            >
              <span
                className={`text-xs pb-0.5 ${
                  isActive ? "text-primary font-medium" : "text-gray-500"
                }`}
              >
                {tab?.title}
              </span>
            </button>
          );
        })}
      </div>

      <div className="flex flex-col flex-1 mt-8 w-full overflow-y-auto no-scrollbar">
        {renderContent()}
      </div>
    </div>
  );
};

export default MatchesDetailsPage;

import { JobType } from "../../../../../utils/constants";

const TrackerTab = ({ progress, applicationDetails }) => {
  const job = JobType.find(
    (x) => x.value === applicationDetails?.jobDetails?.jobType
  )?.label;

  return (
    <div className="flex gap-6 h-screen overflow-hidden">
      <div className="w-3/5 overflow-y-auto h-full no-scrollbar">
        {progress.map((list, index) => (
          <div className="bg-[#FAFAFA] rounded-lg p-4 mb-3 border border-[#DDDDDD] flex gap-12 cursor-pointer hover:bg-gray-100 max-2xl:gap-3 max-2xl:px-2">
            <div className="flex flex-col gap-3.5 w-1/2">
              <div className="flex flex-shrink-0 font-medium text-xs text-light-black gap-4">
                <p>{list.time}</p>
                <p>{list.date}</p>
              </div>
              <div
                className={`py-1.5 rounded text-center text-xs font-medium ${
                  list.title === "Offer Accepted"
                    ? "bg-light-black text-white"
                    : "bg-[#F1F5F9] border border-[#DDDDDD] text-primary"
                }`}
              >
                {list.title}
              </div>
            </div>
            <p className="bg-white text-light-black font-medium text-xs py-1.5 px-8 self-end rounded w-1/2 text-center h-7">
              Via external email - 21/03/25
            </p>
          </div>
        ))}
      </div>

      <div className="bg-[#F1F5F9] p-4 space-y-3 rounded-lg w-2/5 h-3/5 overflow-y-hidden">
        <div className="flex justify-between">
          {applicationDetails?.profilePic ? (
            <img
              src={applicationDetails?.profilePic}
              className="rounded-full w-14 h-14 object-fill z-10 max-md:h-36 max-md:w-36"
            />
          ) : (
            <div className="font-montserrat text-white font-semibold uppercase w-14 h-14 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails?.firstName?.charAt(0)}
              {applicationDetails?.lastName?.charAt(0)}
            </div>
          )}
          <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded h-max">
            {applicationDetails?.refNo ?? "CA - 123ABC"}
          </span>
        </div>
        <div className="text-primary space-y-2 text-xs">
          <p className="font-bold">
            {applicationDetails?.firstName} {applicationDetails?.lastName}
          </p>
        </div>
        <div className="flex flex-col gap-3.5">
          <span className="font-medium text-primary text-xs flex-1">
            {applicationDetails?.jobTitle ?? "-"}
          </span>
          <div className="flex flex-col gap-3.5 mb-6">
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Location:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.location ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Industry Type:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.candidateDetails?.jobTitle ?? "Finance"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Job Type:</span>
              <span className="font-medium text-primary text-xs flex-1">
                Full Time
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Salary Range:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                £60,000 - £80,000
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackerTab;

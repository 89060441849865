import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { candidateStatus } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const availabilityStatus = Object.entries(candidateStatus).map(
  ([key, value]) => ({
    title: value,
    id: key,
  })
);

const Details = forwardRef(({ data, setData, handleNext }, ref) => {
  const filePickerRef = useRef();
  const { user } = useSelector((state) => state.auth);

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      jobTitle: user?.jobTitle,
      status:
        availabilityStatus?.find((x) => x.id === user?.status)?.id ??
        availabilityStatus?.find((x) => x.title === "Available")?.id,
      location: user?.location,
      image: undefined,
    },
  });

  const fields = watch();

  useEffect(() => {
    if (user) {
      setValue("jobTitle", user?.jobTitle);
      setValue("location", user?.location);
      setValue(
        "status",
        availabilityStatus?.find((x) => x.id === user?.status)?.id ??
          availabilityStatus?.find((x) => x.title === "Available")?.id
      );
    }
  }, [user]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const cropperRef = useRef(null);

  // Handle file selection
  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
          setIsModalOpen(true);
          trigger("image");
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  // Get the cropped image
  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profileImage", croppedDataUrl);
        setIsModalOpen(false);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setValue("image", undefined);
    setValue("file", undefined);
    setValue("profileImage", undefined);
  };

  useEffect(() => {
    if (Object?.keys(dirtyFields)?.length > 0) {
      setData((prev) => {
        return { ...prev, details: { ...prev?.details, isFilled: true } };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          details: {
            ...prev?.details,
            isEditing: true,
            isFilled: false,
            isCompleted: user?.jobTitle || user?.location ? true : false,
          },
        };
      });
    }
  }, [Object?.keys(dirtyFields)?.length]);

  useImperativeHandle(ref, () => handleSubmit(handleNext));

  return (
    <div className="flex flex-col w-2/3">
      <form className="flex flex-col w-full">
        <div className="flex flex-col">
          <button
            type="button"
            onClick={() => filePickerRef?.current?.click()}
            className="flex items-center border rounded-lg overflow-hidden pr-2"
          >
            <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
              PNG/JPG
            </span>
            <span className="text-xs flex font-medium px-3 text-ngray flex-1 text-left whitespace-nowrap">
              {fields?.file
                ? fields?.file?.name
                : user?.profilePic
                ? user?.profilePic
                : "Upload your profile picture"}
            </span>
          </button>
          <input
            ref={filePickerRef}
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />

          {isModalOpen && (
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
              onClick={closeModal} // Close modal when clicking outside the image
            >
              <div
                className="bg-white p-4 rounded-lg relative w-11/12 max-w-3xl"
                onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
              >
                <button
                  onClick={closeModal}
                  className="absolute top-0 right-0 text-black pt-3 pr-5 text-2xl font-semibold"
                >
                  X
                </button>
                <div className="flex flex-col mt-7">
                  {fields?.image && (
                    <Cropper
                      src={fields?.image}
                      ref={cropperRef}
                      style={{ height: 400, width: "100%" }}
                      aspectRatio={1}
                      viewMode={2}
                      guides={false}
                      minCropBoxWidth={50} // Minimum width for the crop area
                      minCropBoxHeight={50} // Minimum height for the crop area
                    />
                  )}
                  <div className="mt-10 flex justify-end">
                    <button
                      onClick={(e) => getCroppedImage(e)}
                      className="bg-primary text-white px-7 py-2.5 text-xl rounded-md"
                    >
                      Crop Image
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <style>
            {`
          /* Ensure the cropper's crop box is circular */
          .cropper-face,
          .cropper-view-box,
          .cropper-crop-box {
            border-radius: 50% !important; /* Make crop area circular */
            border: 2px solid white !important; /* Optional: Set the color of the circular crop lines */
          }

          .cropper-face {
            background-color: rgba(0, 0, 0, 0.3) !important; /* Optional: Set the background color of the circular crop area */
          }

          .cropper-view-box {
            border-radius: 50% !important; /* Make sure the selection area is circular */
          }

          .cropper-crop-box {
            border-radius: 50% !important; /* Ensure the cropping boundary is circular */
          }
        `}
          </style>
        </div>
        <div className="h-6" />
        <CustomInput
          label={"Job Title"}
          name="jobTitle"
          placeholder={"Ex: Project Manager"}
          control={control}
          errors={errors}
        />
        <div className="h-6" />
        <Controller
          name="status"
          control={control}
          render={({ field }) => {
            return (
              <div className={`flex flex-col w-full`}>
                <span className="font-montserrat text-primary font-medium text-xs mb-2">
                  Work Status
                </span>
                <div
                  className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border`}
                >
                  <select
                    {...field}
                    className="w-full outline-none bg-transparent focus:outline-none focus:border-blue-500 text-primary"
                    name={"status"}
                    id={"status"}
                  >
                    <option value="" disabled hidden className={""}>
                      Are you available for job?
                    </option>
                    {availabilityStatus?.map((item) => {
                      return (
                        <option
                          className="text-black"
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors["status"]?.message && (
                  <span className="whitespace-pre text-xs mt-2 text-orange-700">
                    {errors["status"]?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
        <div className="h-6" />
        <CustomInput
          label={"Location"}
          name="location"
          placeholder={"Ex: United Kingdom"}
          control={control}
          errors={errors}
        />
      </form>
    </div>
  );
});

export default Details;

import { useState } from "react";

import { JobSkills } from "../../../../../utils/constants";
import { SkillInput } from "../../skill/skillInput";
import { SkillData } from "../../skill/skillInput.types";
import EditIcon from "../../../../../assets/svg/EditIcon";

interface SkillItemProps {
  skillData: SkillData;
  index: number;
  showEditDeleteButtons?: boolean;
  handleAddSkill?: (data: SkillData) => void;
  handleDeleteSkill?: (_id?: number) => void;
}

const SkillItem = ({
  handleAddSkill,
  skillData,
  index,
  showEditDeleteButtons,
  handleDeleteSkill,
}: SkillItemProps) => {
  const { title, rating, lastUsed, years } = skillData;
  const [editable, setEditable] = useState<boolean>(false);

  const onAddAndUpdate = (props: any) => {
    setEditable(false);
    handleAddSkill && handleAddSkill(props);
  };

  return (
    <>
      {!editable ? (
        <div className="grid space-x-3 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr_0.5fr]">
          {[
            title,
            `${rating}/10`,
            years,
            //@ts-ignore
            JobSkills.find((j) => j.value === lastUsed)?.label || lastUsed,
          ].map((value, index) => (
            <div
              key={index}
              className={`border min-h-9 border-gray-100 bg-[#FAFAFA] px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1 ${
                index === 0 && "ml-4"
              }`}
            >
              {value}
            </div>
          ))}
          {showEditDeleteButtons && (
            <div className="cursor-pointer" onClick={() => setEditable(true)}>
              <EditIcon className={"w-4 h-4"} />
            </div>
          )}
        </div>
      ) : (
        <SkillInput
          data={{
            ...skillData,
            lastUsed: JobSkills.find((j) =>
              typeof lastUsed === "string"
                ? JobSkills.find(
                    (j) =>
                      j.label?.toLowerCase() === String(lastUsed)?.toLowerCase()
                  )?.value
                : lastUsed
            )?.label,
          }}
          deleteSkill={handleDeleteSkill}
          addUpdateSkill={onAddAndUpdate}
          index={index}
          showEditDeleteButtons={showEditDeleteButtons}
        />
      )}
    </>
  );
};

export default SkillItem;

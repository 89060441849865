import { useEffect, useState } from "react";
import { colors } from "../../utils/theme";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import ChooseDocument from "./components/ChooseDocument";
import UpdateProfile from "./components/UpdateProfile";
import ReviewSubmit from "./components/ReviewSubmit";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getJobById } from "../../endpoints/job.service";

const JobApplyPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { logged_in } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    name: "Sarah Cole",
    email: "sarahcole_123@gmail.com",
    phone: "+1 234-777-2399",
    location: "London, UK",
    resumeOption: "select",
    coverLetterOption: "write",
  });

  useEffect(() => {
    if (id && logged_in) {
      setLoading(true);
      getJobById(id)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setJobDetails(data);
          }
        })
        .finally(() => setLoading(false));
    } else if (id) {
      navigate(`/job-detail/${id}`);
    }
  }, [id]);

  const tabs = [
    "Choose documents",
    "Update COMTRACKED Profile",
    "Review and Submit",
  ];

  const handleContinue = () => {
    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center rounded-lg bg-white w-full h-full p-6">
        <LoadingAnimation color={"black"} />
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-lg bg-white w-full h-full p-6">
      <div className="flex justify-between items-center w-full px-[75px]">
        <button
          type="button"
          className="text-[10px] text-primary flex items-center gap-1 py-2"
          onClick={() =>
            activeTab === 0
              ? navigate("/job-search")
              : activeTab === 1
              ? setActiveTab(0)
              : activeTab === 2
              ? setActiveTab(1)
              : null
          }
        >
          <ChevronLeftIcon className="w-3 h-3" fill="#4d4d4d" />
          Back
        </button>

        {activeTab === 2 || (
          <button
            type="button"
            className="bg-[#F1F5F9] flex items-center text-primary rounded-md text-xs px-5 py-2 self-end"
            onClick={handleContinue}
          >
            {false && (
              <LoadingAnimation color={colors.White} className="w-4 h-4" />
            )}
            Continue
          </button>
        )}
      </div>
      {/* Tab Navigation */}
      <div className="flex justify-between items-center mb-6 mt-3 bg-[#FAFAFA] rounded-lg py-2 px-[75px] max-xl:px-20">
        {tabs.map((tab, index) => (
          <>
            <div
              key={index}
              className={`py-2 px-6 rounded text-xs ${
                index === activeTab
                  ? "text-primary bg-[#F1F5F9]"
                  : "text-light-black bg-white"
              }`}
              // onClick={() => setActiveTab(index)}
            >
              {tab}
            </div>
            {index !== tabs.length - 1 && (
              <div className="h-px w-32 bg-light-grey mx-0.5 max-2xl:w-20 max-xl:w-10"></div>
            )}
          </>
        ))}
      </div>
      <div className="overflow-y-scroll no-scrollbar w-full flex flex-col">
        {activeTab === 0 && (
          <ChooseDocument
            formData={formData}
            setFormData={setFormData}
            jobDetails={jobDetails}
          />
        )}
        {activeTab === 1 && <UpdateProfile />}
        {activeTab === 2 && (
          <ReviewSubmit setFormData={setFormData} jobDetails={jobDetails} />
        )}
      </div>
    </div>
  );
};

export default JobApplyPage;

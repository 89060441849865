import { Link } from "react-router-dom";
import { Input } from "../../MatchDetailPage/MatchDetailPage";
import moment from "moment";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";

const SkillsMatchTab = ({ applicationDetails, isLoading }) => {
  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <LoadingAnimation color={"black"} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2.5 overflow-auto no-scrollbar w-full">
      {/* Top Header */}
      <div className="flex items-center w-full gap-5">
        <Link
          to={"#"}
          className="flex py-2 px-4 bg-[#F1F5F9] gap-4 flex-1 justify-between h-14 rounded"
        >
          <div className="flex items-center gap-4">
            <div className="w-10 h-10 rounded-full justify-center items-center flex bg-[#E1E0E0] shadow-md">
              {applicationDetails?.jobMatchesDetails[0].candidateDetails
                ?.profilePic ? (
                <img
                  src={
                    applicationDetails?.jobMatchesDetails[0].candidateDetails
                      ?.profilePic
                  }
                  className="h-full w-full object-cover rounded-full"
                  alt="profileImg"
                />
              ) : (
                <span className="font-montserrat text-white font-semibold text-xl">
                  {applicationDetails?.jobMatchesDetails[0].candidateDetails?.name?.charAt(
                    0
                  )}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-between gap-1.5">
              <span className="font-medium text-primary text-xs capitalize">
                {
                  applicationDetails?.jobMatchesDetails[0].candidateDetails
                    ?.name
                }
              </span>
              <span className="text-primary text-xs">
                {applicationDetails?.jobMatchesDetails[0].candidateDetails
                  ?.jobTitle ?? "N/A"}
              </span>
            </div>
          </div>
          <div className="flex flex-col justify-between gap-1.5">
            <span className="font-medium text-green-700 text-xs">
              {applicationDetails?.jobMatchesDetails[0].candidateDetails
                ?.status ?? "N/A"}
            </span>
            <span className="text-primary text-[10px]">
              {" "}
              {applicationDetails?.jobMatchesDetails[0].candidateDetails
                ?.refNo ?? "N/A"}
            </span>
          </div>
        </Link>

        <span className="text-xs border-b pb-2 font-medium italic text-light-black">
          {applicationDetails?.jobMatchesDetails[0]?.totalMatchPercentage}%
          matched
        </span>

        <div className="flex py-2 px-4 bg-[#F1F5F9] gap-4 flex-1 justify-between h-[3.4rem] rounded">
          <div className="flex flex-col justify-between">
            <span className="font-medium text-light-black text-[10.5px]">
              {applicationDetails?.jobMatchesDetails[0].jobDetails?.refNo}
            </span>
            <span className="font-medium text-primary text-xs">
              {applicationDetails?.jobMatchesDetails[0].jobDetails?.title}
            </span>
          </div>
          <div className="flex justify-between gap-6 pr-8">
            <div className="flex flex-col justify-between">
              <span className="font-medium text-primary text-[10px]">Post</span>
              <span className="font-medium text-primary text-[10px]">
                Matched
              </span>
            </div>
            <div className="flex flex-col justify-between">
              <span className="font-medium text-[10px] italic text-light-black">
                {moment(
                  applicationDetails?.jobMatchesDetails[0].jobDetails?.createdAt
                ).format("DD/MM/YY")}
              </span>
              <span className="font-medium text-[10px] italic text-light-black">
                21/03/25
              </span>
            </div>
          </div>
        </div>
      </div>

      {applicationDetails?.skillsMatchesDetails?.length === 0 ? (
        <div className="flex items-center justify-center py-5">
          <span className="text-sm text-gray-500 italic">
            No matching skills found.
          </span>
        </div>
      ) : (
        <div className="flex flex-col mt-9">
          <div className="flex mt-3 gap-32">
            <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2 font-bold text-xs text-primary">
              <span>Skill</span>
              <span>Rating</span>
              <span>Years</span>
              <span>When</span>
              <div />
            </div>
            <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2 font-bold text-xs text-primary">
              <span>Skill</span>
              <span>Rating</span>
              <span>Years</span>
              <span>When</span>
              <div />
            </div>
          </div>
          {/* Skills List */}
          <div className="flex flex-col gap-2.5">
            {applicationDetails?.skillsMatchesDetails?.map((skill, index) => {
              const showMatchingPercentage =
                skill.candidateSkill && skill.jobSkill;

              return (
                <div className="flex items-center" key={index}>
                  {/* Candidate Skills Column */}
                  <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                    {skill.candidateSkill ? (
                      <>
                        <Input
                          readOnly={true}
                          type="skill"
                          value={skill.candidateSkill.title}
                        />
                        <Input
                          readOnly={true}
                          type="rating"
                          placeholder="Rating"
                          value={skill.candidateSkill.rating}
                        />
                        <Input
                          readOnly={true}
                          placeholder="Years"
                          value={skill.candidateSkill.years}
                        />
                        <Input
                          readOnly={true}
                          type="skillUsed"
                          placeholder="When last used"
                          value={skill.candidateSkill.lastUsed}
                        />
                      </>
                    ) : (
                      <>
                        <div className="h-10"></div>
                        <div className="h-10"></div>
                        <div className="h-10"></div>
                        <div className="h-10"></div>
                      </>
                    )}
                  </div>

                  {/* Matching Percentage Column */}
                  <div className="w-72 flex items-center justify-center">
                    {showMatchingPercentage && (
                      <span className="text-xs border-b pb-2 font-medium italic text-light-black">
                        {Math.round(skill.matchingPercentage)}% matched
                      </span>
                    )}
                  </div>

                  {/* Job Skills Column */}
                  <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                    {skill.jobSkill ? (
                      <>
                        <Input
                          readOnly={true}
                          type="skill"
                          value={skill.jobSkill.title}
                        />
                        <Input
                          readOnly={true}
                          type="rating"
                          placeholder="Rating"
                          value={skill.jobSkill.rating}
                        />
                        <Input
                          readOnly={true}
                          placeholder="Years"
                          value={skill.jobSkill.years}
                        />
                        <Input
                          readOnly={true}
                          type="skillUsed"
                          placeholder="When last used"
                          value={skill.jobSkill.lastUsed}
                        />
                      </>
                    ) : (
                      <>
                        <div className="h-10"></div>
                        <div className="h-10"></div>
                        <div className="h-10"></div>
                        <div className="h-10"></div>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SkillsMatchTab;

// auth token, logged
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const LOGIN_TOKEN = "LOGIN_TOKEN";
export const LOGIN_USER = "LOGIN_USER";
export const AUTH_USER_UPDATE = "AUTH_USER_UPDATE";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const TOKEN_REFRESHED = "TOKEN_REFRESHED";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const SET_JOB_DATA = "SET_JOB_DATA";
export const SET_JOB_DETAIL_DATA = "SET_JOB_DETAIL_DATA";
export const SET_ASSESSMENT_DATA = "SET_ASSESSMENT_DATA";
export const SET_CANDIDATES_DATA = "SET_CANDIDATES_DATA";
export const SET_CANDIDATES_ASSESSMENT_DATA = "SET_CANDIDATES_ASSESSMENT_DATA";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const RESET_SEARCH_DATA = "RESET_SEARCH_DATA";
export const SET_UNSEEN_MESSAGE_COUNT = "SET_UNSEEN_MESSAGE_COUNT";
export const RESET_UNSEEN_MESSAGE_COUNT = "RESET_UNSEEN_MESSAGE_COUNT";

import { Controller } from "react-hook-form";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import QuillEditor from "../../../../../components/CreateQuestion/Question/QuillEditor";
import moment from "moment";
import { AddEditExperienceProps } from "./experienceBlock.types";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import SkillItem from "./skillItem";
import { SkillInput } from "../../skill/skillInput";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
const months = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

const AddEditExperience: React.FC<AddEditExperienceProps> = ({
  control,
  fields,
  errors,
  setValue,
  handleAddSkill,
  setExperiences,
  handleDeleteSkill,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (fields.startDate) {
      const start = moment(fields.startDate);
      setValue("startYear", start.year());
      setValue("startMonth", start.month() + 1);
    }

    if (fields.endDate && !fields.isCurrent) {
      const end = moment(fields.endDate);
      setValue("endYear", end.year());
      setValue("endMonth", end.month() + 1);
    } else {
      setValue("endYear", undefined);
      setValue("endMonth", undefined);
    }
  }, [fields.startDate, fields.endDate, fields.isCurrent, setValue]);

  const handleStartDateChange = (year?: number, month?: number) => {
    if (year && month) {
      const newStartDate = moment(`${year}-${month}-01`).format("YYYY-MM-DD");
      //@ts-ignore
      setValue("startDate", newStartDate);
    }
  };

  const handleEndDateChange = (year?: number, month?: number) => {
    if (year && month && !fields.isCurrent) {
      const newEndDate = moment(`${year}-${month}-01`).format("YYYY-MM-DD");
      //@ts-ignore
      setValue("endDate", newEndDate);
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4 mb-3">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={fields?.isCurrent}
            className="accent-[#EFBF04] size-4 rounded text-emerald-500"
            onChange={(e) => {
              if (
                fields?.startDate &&
                moment(fields.startDate).isSameOrAfter(moment(), "date")
              ) {
                enqueueSnackbar("Start date should be ahead of current date", {
                  variant: "error",
                });
                return;
              }
              setValue("isCurrent", e?.target?.checked);
            }}
          />
          <label className="text-primary text-xs font-medium">
            Currently working for this role
          </label>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-3">
            <div>
              <label className="text-primary font-medium text-xs mb-2">
                Start Date
              </label>
              <div className="flex gap-2">
                <Controller
                  name="startMonth"
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="border rounded-lg px-3 py-2.5 w-full text-xs"
                    >
                      <option value="">Month</option>
                      {months
                        .filter(
                          (m) =>
                            !(
                              fields.startYear == currentYear &&
                              m.value > currentMonth
                            )
                        )
                        .map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.name}
                          </option>
                        ))}
                    </select>
                  )}
                />

                {/* Start Year */}
                <Controller
                  name="startYear"
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="border rounded-lg px-3 py-2.5 w-full text-xs cursor-pointer"
                      onChange={(e) => {
                        field.onChange(Number(e.target.value));
                        handleStartDateChange(
                          Number(e.target.value),
                          fields.startMonth
                        );
                      }}
                    >
                      <option value="">Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
            </div>
            <CustomInput
              type="text"
              label={"Job Title"}
              name={"jobTitle"}
              placeholder={"Enter your Job Title"}
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
            <CustomInput
              label="Location"
              name="location"
              rules={{
                required: "This field is required.",
              }}
              placeholder={"Ex: London"}
              control={control}
              errors={errors}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div
              className={`${fields?.isCurrent ? "opacity-50" : "opacity-100"}`}
            >
              <label className="text-primary font-medium text-xs mb-2">
                End Date
              </label>
              <div className="flex gap-2">
                <Controller
                  name="endMonth"
                  control={control}
                  rules={{
                    required: fields?.isCurrent ? false : "Month is required",
                  }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="border rounded-lg px-3 py-2.5 w-full text-xs"
                      disabled={fields?.isCurrent}
                    >
                      <option value="">Month</option>
                      {months
                        .filter(
                          (m) =>
                            !(
                              fields.endYear == currentYear &&
                              m.value > currentMonth
                            )
                        )
                        .map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.name}
                          </option>
                        ))}
                    </select>
                  )}
                />

                {/* End Year */}
                <Controller
                  name="endYear"
                  control={control}
                  rules={{
                    required: fields?.isCurrent ? false : "Year is required",
                  }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="border rounded-lg px-3 py-2.5 w-full text-xs cursor-pointer"
                      disabled={fields?.isCurrent}
                      onChange={(e) => {
                        field.onChange(Number(e.target.value));
                        handleEndDateChange(
                          Number(e.target.value),
                          fields.endMonth
                        );
                      }}
                    >
                      <option value="">Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
            </div>

            <CustomInput
              type="text"
              label={"Company Name"}
              name={"companyName"}
              placeholder={"Enter your Company Name"}
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className="mr-4">
          <Controller
            name={"jobDescription"}
            control={control}
            rules={{
              required: "This field is required.",
            }}
            render={({ field }) => {
              return (
                <div className="flex flex-col gap-1">
                  <span className="font-montserrat text-primary font-medium text-xs mb-2">
                    Job Description{" "}
                    <span className="font-montserrat font-medium text-xs text-red-500">
                      *
                    </span>
                  </span>
                  <QuillEditor
                    onChange={field.onChange}
                    value={field.value}
                    label=""
                    required={true}
                  />
                  {errors["jobDescription"]?.message && (
                    <span className="whitespace-pre text-xs mt-2 text-orange-700">
                      {errors["jobDescription"]?.message}
                    </span>
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="flex flex-col px-16 gap-3">
        <div className="grid flex-1 mt-7 mb-2 px-4 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr_0.5fr]">
          {["Skill", "Rating", "Years", "When", ""].map((label) => (
            <span
              key={label}
              className="text-xs font-semibold text-gray-700 flex-1"
            >
              {label}
            </span>
          ))}
        </div>
        <SkillInput
          data={{
            lastUsed: "",
            rating: "",
            title: "",
            years: "",
            _id: undefined,
            isEdit: true,
            isSaved: false,
          }}
          addUpdateSkill={handleAddSkill}
          index={undefined}
          deleteSkill={handleDeleteSkill}
        />
        {fields?.skills?.length > 0 && (
          <div className="flex flex-col gap-3">
            {fields?.skills.map((skill: any, index: number) => (
              <SkillItem
                key={skill?._id}
                skillData={skill}
                index={index}
                showEditDeleteButtons
                handleAddSkill={handleAddSkill}
                handleDeleteSkill={handleDeleteSkill}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditExperience;

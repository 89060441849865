import * as React from "react";
const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={6}
    fill="none"
    {...props}
  >
    <path
      fill="#1A1A1B"
      d="M9.013 1.682 5.06 5.647a.41.41 0 0 1-.153.1.517.517 0 0 1-.177.03.517.517 0 0 1-.176-.03.41.41 0 0 1-.153-.1L.436 1.682a.559.559 0 0 1-.165-.411c0-.165.06-.306.177-.424A.564.564 0 0 1 .86.671c.157 0 .294.058.411.176l3.46 3.459L8.188.847a.551.551 0 0 1 .406-.165c.16 0 .3.06.418.177a.564.564 0 0 1 .176.412.564.564 0 0 1-.176.411Z"
    />
  </svg>
);
export default DownArrow;

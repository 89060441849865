import * as React from "react";
const PaperSendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={24}
    height={24}
    viewBox="0 0 60.083 60.083"
    {...props}
  >
    <path d="m60.049 10.871-.001-.007a.953.953 0 0 0-.132-.343l-.001-.004-.004-.005a.98.98 0 0 0-.232-.253c-.019-.015-.039-.026-.059-.04a.947.947 0 0 0-.239-.117c-.055-.019-.111-.025-.168-.034-.044-.006-.083-.026-.129-.026h-.004c-.039-.001-.066.003-.094.006l-.026.001c-.009.001-.019 0-.029.002a1.027 1.027 0 0 0-.08.014L.798 22.062a1 1 0 0 0-.265 1.864l16.632 8.773 2.917 16.187c-.002.012.001.025 0 .037a.94.94 0 0 0 .019.354c.023.095.06.184.11.268.01.016.01.035.021.051.003.005.008.009.012.013.013.019.031.034.046.053.047.058.096.111.152.156.009.007.015.018.025.025.015.011.032.014.047.024.061.04.124.073.191.099.027.01.052.022.08.03.09.026.183.044.277.044h.003l.012-.002a.993.993 0 0 0 .763-.313l11.079-7.386 11.6 7.54a1.005 1.005 0 0 0 .86.11 1 1 0 0 0 .623-.604L59.938 11.56a.991.991 0 0 0 .146-.521c-.001-.058-.025-.111-.035-.168zm-11.585 6.723L24.471 35.236c-.039.029-.07.065-.104.099-.013.012-.026.022-.037.035a1.01 1.01 0 0 0-.059.071c-.018.024-.032.049-.048.074-.037.06-.068.122-.092.188-.005.013-.013.023-.017.036-.001.004-.005.006-.006.01l-2.75 8.937-2.179-12.091 29.285-15.001zM22.908 46.61l2.726-9.004 4.244 2.759 1.214.789-4.124 2.749-4.06 2.707z" />
  </svg>
);
export default PaperSendIcon;

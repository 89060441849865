import { useState } from "react";
import LocationIcon from "../../../assets/svg/LocationIcon";
import MailIcon from "../../../assets/svg/MailIcon";
import PhoneOutline from "../../../assets/svg/PhoneOutline";
import SubmitIcon from "../../../assets/svg/SubmitIcon";
import { useNavigate } from "react-router-dom";
import applicationImg from "../../../assets/images/application.png";
import { JobType } from "../../../utils/constants";
import { applyJob } from "../../../endpoints/job.service";
import { useSnackbar } from "notistack";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { colors } from "../../../utils/theme";
import { useSelector } from "react-redux";

const ReviewSubmit = ({ jobDetails }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const job = JobType.find((x) => x.value === jobDetails?.jobType)?.label;

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await applyJob({ jobId: jobDetails._id, cv: "", coverLetter: "" });
      enqueueSnackbar("Application submitted successfully", {
        variant: "success",
      });
      setIsModalOpen(true);
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleModalButtonClick = () => {
    setIsModalOpen(false);
    navigate("/job-search");
  };

  return (
    <div className="w-full flex gap-6 px-[75px] h-screen overflow-hidden">
      {/* Profile Card */}
      <div className="w-3/5  overflow-y-auto h-full no-scrollbar">
        <div className="bg-white border border-[#ECECEC] rounded-lg py-6 px-16 mb-8 space-y-4">
          <h2 className="text-2xl font-semibold">
            {user.firstName} {user.lastName}
          </h2>
          <div className="space-y-2">
            <div className="flex items-center text-primary gap-3">
              <LocationIcon />
              <span className="text-xs">{user.location}</span>
            </div>
            <div className="flex items-center text-primary gap-3">
              <MailIcon />
              <span className="text-xs">{user.email}</span>
            </div>
            <div className="flex items-center text-primary gap-3">
              <PhoneOutline />
              <span className="text-xs">{user.phoneNo}</span>
            </div>
          </div>
          <button
            className="text-primary text-xs py-1.5 px-6 bg-white border border-[#DDDDDD] rounded"
            onClick={() => navigate("/settings")}
          >
            Edit
          </button>
        </div>

        <div className="mb-24 ml-16">
          <h3 className="font-bold mb-10 text-sm">Documents included</h3>
          <div className="ml-8">
            <div className="space-y-11">
              <div className="space-y-2">
                <p className="text-[13px] font-bold text-primary">Resume</p>
                <p className="text-[13px] font-medium text-primary">
                  No resume uploaded
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-[13px] font-bold text-primary">
                  Cover Letter
                </p>
                <p className="text-[13px] font-medium text-primary">
                  No cover letter uploaded
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-[13px] font-bold text-primary">
                  Your COMTRACKED profile
                </p>
                <p className="text-[13px] font-medium text-primary">
                  When you apply on COMTRACKED, your COMTRACKED Profile
                  including any verified credentials will be accessed by the
                  recruitment agent and employer.
                </p>
              </div>
            </div>
            <p className="bg-[#F1F5F9] py-3 px-5 mt-4 text-xs text-primary font-medium rounded-lg flex justify-self-start cursor-pointer">
              https://comtracked.com/profile/SarahCole
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#F1F5F9] py-6 px-5 space-y-8 rounded-lg w-2/5 h-fit overflow-y-hidden">
        <div className="text-primary space-y-2.5 text-sm">
          <p>Applying for</p>
          <p className="font-semibold">{jobDetails?.title}</p>
          <p className="underline text-xs cursor-pointer">
            View job description
          </p>
        </div>
        <div className="flex flex-col gap-3.5 mb-9">
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">Location:</span>
            <span className="font-medium text-primary text-xs flex-1">
              {jobDetails?.location}
            </span>
          </div>
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">
              Industry Type:
            </span>
            <span className="font-medium text-primary text-xs flex-1">
              {jobDetails?.industryType}
            </span>
          </div>
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">Job Type:</span>
            <span className="font-medium text-primary text-xs flex-1">
              {job}
            </span>
          </div>
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">Salary Range:</span>
            <span className="font-medium text-primary text-xs flex-1">
              £{jobDetails?.salary.min} - £{jobDetails?.salary.max}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <p className="text-[11px]">
            By applying, you agree to the{" "}
            <span className="underline cursor-pointer">Collection Notice</span>{" "}
            and
            <span className="underline cursor-pointer"> Privacy Policy</span>.
          </p>
          <button
            className="text-secondary text-[14px] items-center font-medium py-2.5 bg-white border border-[#DDDDDD] w-full rounded-lg flex gap-3 justify-center"
            onClick={handleSubmit}
          >
            {loading ? (
              <LoadingAnimation
                color={colors.SecondaryOrange}
                className="w-6 h-w-6"
              />
            ) : (
              <SubmitIcon />
            )}
            <p>Submit Application</p>
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg px-12 py-10 w-1/3 flex flex-col items-center">
            <img src={applicationImg} alt="application" className="mb-7" />
            <h3 className="text-3xl font-semibold mb-5 text-primary text-center">
              Your job application has been submitted!
            </h3>
            <p className="mb-20 text-center flex items-center justify-start flex-wrap">
              <span>
                You will get an email confirmation at{" "}
                <span className="font-semibold">{user.email}</span>
              </span>
            </p>
            <button
              className="text-primary py-2.5 w-5/6 rounded-lg border border-primary"
              onClick={handleModalButtonClick}
            >
              Back to job description
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewSubmit;

import moment from "moment";
import { JobType } from "../../../../utils/constants";
import { jobGetTracker } from "../../../../endpoints/job.service";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { isNullOrEmpty } from "../../../../utils/helperFunction";

const TrackerTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [trackerList, setTrackerList] = useState();
  const [loading, setLoading] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchTracker();
    }
  }, []);

  const fetchTracker = async () => {
    try {
      setLoading(true);
      const { data, success } = await jobGetTracker(applicationDetails?._id);
      if (success && data) {
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setTrackerList(sortedData);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex gap-6 h-screen overflow-hidden">
      <div className="w-3/5 overflow-y-auto h-full no-scrollbar">
        {loading ? (
          <div className="flex justify-center mt-40 h-full">
            <LoadingAnimation color="black" />
          </div>
        ) : isNullOrEmpty(trackerList) ? (
          <div className="flex justify-center mt-40 h-full font-medium">
            No data tracked!
          </div>
        ) : (
          <>
            {trackerList?.map((list, index) => (
              <div className="bg-[#FAFAFA] rounded-lg p-4 mb-3 border border-[#DDDDDD] flex gap-12 cursor-pointer hover:bg-gray-100">
                <div className="flex flex-col gap-3.5 w-1/2">
                  <div className="flex flex-shrink-0 font-medium text-xs text-light-black gap-4">
                    {moment(list?.createdAt).format("h:mm a DD MMMM YYYY")}
                  </div>
                  <div
                    className={`py-1.5 rounded text-center text-xs font-medium ${
                      list.title === "Offer Accepted"
                        ? "bg-light-black text-white"
                        : "bg-[#F1F5F9] border border-[#DDDDDD] text-primary"
                    }`}
                  >
                    {list.status === "Submit Offer"
                      ? "Offer received"
                      : list.status === "Decline"
                      ? "Application declined"
                      : list.status === "Shortlist"
                      ? "Shortlisted"
                      : list.status}
                  </div>
                </div>
                <p className="bg-white text-light-black font-medium text-xs py-1.5 px-8 self-end rounded w-1/2 text-center h-7">
                  {moment(list?.createdAt).format("DD/MM/YY")}
                </p>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="bg-[#F1F5F9] p-4 space-y-3 rounded-lg w-2/5 h-3/5 overflow-y-hidden">
        <div className="flex justify-between">
          {applicationDetails?.job?.user?.profilePic ? (
            <img
              src={applicationDetails?.job?.user?.profilePic}
              className="rounded-full w-14 h-14 object-fill z-10 max-md:h-36 max-md:w-36"
            />
          ) : (
            <div className="font-montserrat text-white font-semibold uppercase w-14 h-14 rounded-full bg-gray-400 flex items-center justify-center text-xl">
              {applicationDetails?.job?.user?.firstName?.charAt(0)}
              {applicationDetails?.job?.user?.lastName?.charAt(0)}
            </div>
          )}
          <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded h-max">
            {applicationDetails?.job?.refNo ?? "-"}
          </span>
        </div>
        <div className="text-primary space-y-2 text-xs">
          <p className="font-bold">
            {" "}
            {applicationDetails?.job?.user?.firstName +
              " " +
              applicationDetails?.job?.user?.lastName ?? "-"}
          </p>
        </div>
        <div className="flex flex-col gap-3.5">
          <span className="font-medium text-primary text-xs flex-1">
            {applicationDetails?.job?.title ?? "-"}
          </span>
          <div className="flex flex-col gap-3.5 mb-6">
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Location:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.job?.location ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Industry Type:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                {applicationDetails?.job?.industryType ?? "-"}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Job Type:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {
                  JobType.find(
                    (x) => x.value === applicationDetails?.job?.jobType
                  )?.label
                }
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Salary Range:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                £{applicationDetails?.job?.salary?.min} - £
                {applicationDetails?.job?.salary?.max}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackerTab;

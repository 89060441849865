import { useSnackbar } from "notistack";
import { jobMembersProfile } from "../../endpoints/job.service";
import { useCallback, useEffect, useRef, useState } from "react";
import DownArrow from "../../assets/svg/DownArrow";
import FilterIcon from "../../assets/svg/FilterIcon";
import moment from "moment";
import { useSelector } from "react-redux";
import { userTypes } from "../../utils/constants";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { isNullOrEmpty } from "../../utils/helperFunction";

const truncateMessage = (message, wordLimit = 4) => {
  if (!message) return "No recent messages";

  const words = message.split(" ");
  if (words.length <= wordLimit) return message;

  return words.slice(0, wordLimit).join(" ") + "...";
};

const MembersProfilePage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const unseenCount = useSelector(
    (state) => state.messageReducer?.unseenCount || 0
  );
  const { user } = useSelector((state) => state.auth);
  const [membersProfile, setMembersProfile] = useState();
  const [isComposing, setIsComposing] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasFetched = useRef(false);

  const isRecruiter = user?.userType === userTypes.RECRUITER;
  const isReferral = user.userType === userTypes.REFERRAL_AMBASSADOR;
  const userName = user.firstName + " " + user.lastName;

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchMembersProfile();
    }
  }, []);

  useEffect(() => {
    fetchMembersProfile();
  }, [unseenCount]);

  const fetchMembersProfile = useCallback(async () => {
    try {
      const { data, success } = await jobMembersProfile();
      if (success) {
        const sortedProfiles = data.sort((a, b) => {
          const latestMessageA = a.appliedJobs.reduce((latest, job) => {
            return job.lastMessageCreatedAt &&
              (!latest ||
                moment(job.lastMessageCreatedAt).isAfter(moment(latest)))
              ? job.lastMessageCreatedAt
              : latest;
          }, null);

          const latestMessageB = b.appliedJobs.reduce((latest, job) => {
            return job.lastMessageCreatedAt &&
              (!latest ||
                moment(job.lastMessageCreatedAt).isAfter(moment(latest)))
              ? job.lastMessageCreatedAt
              : latest;
          }, null);

          if (latestMessageA && latestMessageB) {
            return moment(latestMessageB).diff(moment(latestMessageA));
          }

          if (latestMessageA) return -1;
          if (latestMessageB) return 1;

          return 0;
        });

        setMembersProfile(sortedProfiles);

        if (!isNullOrEmpty(id)) {
          setSelectedContact(
            sortedProfiles.find((item) =>
              item.appliedJobs.some(
                (job) => job.job?._id === id || job.jobApplicationId === id
              )
            ) || null
          );
        } else {
          setSelectedContact(sortedProfiles[0]);
        }
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [id, enqueueSnackbar]);

  const handleContactSelect = (user) => {
    setSelectedContact(user);
    navigate("/messages");
  };

  const handleJobClick = (job) => {
    navigate(
      `/messages/${
        isReferral ||
        selectedContact.userDetails.userType === userTypes.REFERRAL_AMBASSADOR
          ? job.job._id
          : job.jobApplicationId
      }`
    );
  };

  if (loading) {
    return (
      <div className="flex h-full rounded-lg bg-white w-full items-center justify-center">
        <LoadingAnimation color="black" className={"w-4 h-4"} />
      </div>
    );
  }

  if (isNullOrEmpty(membersProfile)) {
    return (
      <div className="flex flex-col gap-3 h-full rounded-lg bg-white w-full items-center justify-center font-semibold text-2xl">
        Inbox empty
      </div>
    );
  }

  const outletContext = {
    selectedContact,
    isRecruiter,
    isReferral,
    isComposing,
    user,
    userName,
    setIsComposing,
  };

  return (
    <div className="flex flex-col flex-1 h-full rounded-lg bg-white w-full flex-grow overflow-hidden">
      <div className="flex flex-1 overflow-hidden">
        <div className="border-r border-[#ECECEC] w-4/12 flex flex-col">
          <div className="border-b-2 border-[#ECECEC] text-xs flex justify-between py-5 px-4 text-primary font-semibold">
            <p className="flex items-center gap-1.5">
              All Messages{" "}
              <span>
                <DownArrow />
              </span>
            </p>
            <p>
              <FilterIcon />
            </p>
          </div>
          <div className="overflow-y-auto flex-grow no-scrollbar">
            {membersProfile?.map((item, index) => {
              const latestJob = item.appliedJobs.reduce((latest, job) => {
                if (!job.lastMessageCreatedAt) return latest;

                return !latest ||
                  moment(job.lastMessageCreatedAt).isAfter(
                    moment(latest.lastMessageCreatedAt)
                  )
                  ? job
                  : latest;
              }, null);

              const isSelected =
                selectedContact?.userDetails?._id === item?.userDetails?._id;

              return (
                <div
                  className={`flex justify-between py-3 px-4 border-b border-[#ECECEC] cursor-pointer hover:bg-gray-50 ${
                    isSelected ? "bg-gray-50" : ""
                  }`}
                  key={index}
                  onClick={() => handleContactSelect(item)}
                >
                  <div className="flex items-center gap-3">
                    <div className="h-9 w-9 rounded-full self-start flex bg-[#E1E0E0] bg-nwhited shadow-xl">
                      {item?.userDetails?.profilePic ? (
                        <img
                          src={item?.userDetails?.profilePic}
                          className="h-full w-full object-cover rounded-full"
                          alt="profileImg"
                        />
                      ) : (
                        <div className="font-montserrat text-white font-semibold uppercase w-9 h-9 rounded-full bg-gray-400 flex items-center justify-center text-xl">
                          {item?.userDetails?.firstName?.charAt(0)}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col gap-1 text-[11px]">
                      <p className="text-primary font-bold">
                        {item?.userDetails.firstName}{" "}
                        {item?.userDetails.lastName}
                      </p>
                      <p className="text-primary font-medium">
                        {item?.userDetails.companyName}
                      </p>
                      <p className="text-primary font-bold">
                        Ref:{" "}
                        {latestJob ? `${latestJob.job.title}` : "No messages"}
                      </p>
                      <p className="text-primary font-medium italic">
                        {latestJob?.message?.createdBy === user._id
                          ? "You :"
                          : `${item?.userDetails.firstName} :`}{" "}
                        {truncateMessage(latestJob?.lastMessage)}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col items-end justify-between text-[11px]">
                    <p className="text-light-black font-medium">
                      {item?.userDetails.refNo}
                    </p>
                    {item?.userDetails.unseenMessagesCount > 0 && (
                      <div className="text-[10px] font-medium bg-secondary text-white rounded-full h-4 w-4 text-center">
                        {item?.userDetails.unseenMessagesCount}
                      </div>
                    )}
                    <p className="text-light-black font-medium italic">
                      {latestJob
                        ? moment(latestJob.lastMessageCreatedAt).format(
                            "HH:mm DD/MM/YYYY"
                          )
                        : "-"}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col flex-1 h-full">
          {selectedContact && (
            <>
              <div className="flex items-start justify-between gap-4 py-3 pl-4 pr-8 bg-gray-50 my-4">
                <div className="flex gap-3 items-center">
                  <div className="h-9 w-9 rounded-full flex justify-center items-center bg-gray-400 shadow-lg">
                    {selectedContact?.userDetails?.profilePic ? (
                      <img
                        src={selectedContact?.userDetails.profilePic}
                        className="h-full w-full object-cover rounded-full"
                        alt="Selected Profile"
                      />
                    ) : (
                      <div className="text-white font-semibold uppercase text-lg">
                        {selectedContact?.userDetails?.firstName?.charAt(0)}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col gap-1">
                    <p className="text-primary font-bold text-xs">
                      {selectedContact?.userDetails.firstName}{" "}
                      {selectedContact?.userDetails.lastName}
                    </p>
                    <p className="text-primary font-medium text-xs">
                      Agency name
                    </p>
                    {/* <p className="text-light-black font-medium text-[11px]">
                      {selectedContact?.userDetails.refNo}
                    </p> */}
                  </div>
                </div>
                <p className="text-light-black font-medium text-[11px]">
                  {selectedContact?.userDetails.refNo}
                </p>
              </div>

              <div className="flex flex-col flex-1 h-full overflow-y-scroll no-scrollbar border-t-2">
                {id ? (
                  <Outlet context={outletContext} />
                ) : (
                  selectedContact?.appliedJobs.map((job, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-col gap-2 rounded cursor-pointer text-xs border-b pr-4 pl-16 py-4"
                        onClick={() => handleJobClick(job)}
                      >
                        <div
                          className={`rounded text-primary flex justify-between ${
                            job.unseenMessagesCount > 0
                              ? "font-bold"
                              : "font-normal"
                          }`}
                        >
                          <p className="font-bold">Ref: {job.job.title}</p>
                          {job.unseenMessagesCount > 0 && (
                            <p className="text-[10px] font-medium bg-secondary text-white rounded-full h-4 w-4 text-center">
                              {job.unseenMessagesCount}
                            </p>
                          )}
                        </div>
                        <div className="flex justify-between">
                          <p className="text-primary font-medium italic">
                            {job.lastMessage ?? "-"}
                          </p>
                          <p className="text-primary font-medium italic">
                            {moment(job?.lastMessageCreatedAt).format(
                              "HH:mm DD/MM/YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembersProfilePage;

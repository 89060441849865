import MailIcon from "../../../assets/svg/MailIcon";
import LocationIcon from "../../../assets/svg/LocationIcon";
import PhoneOutline from "../../../assets/svg/PhoneOutline";
import { JobType } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChooseDocument = ({ formData, setFormData, jobDetails }) => {
  const job = JobType.find((x) => x.value === jobDetails?.jobType)?.label;
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <div className="w-full flex gap-6 px-[75px] h-screen overflow-hidden">
      <div className="w-3/5 overflow-y-auto h-full no-scrollbar">
        {/* Profile Card */}
        <div className="bg-white border border-[#ECECEC] rounded-lg py-6 px-16 mb-8 space-y-4">
          <h2 className="text-2xl font-semibold">
            {user.firstName} {user.lastName}
          </h2>
          <div className="space-y-2">
            <div className="flex items-center text-primary gap-3">
              <LocationIcon />
              <span className="text-xs">{user.location ?? "N/A"}</span>
            </div>
            <div className="flex items-center text-primary gap-3">
              <MailIcon />
              <span className="text-xs">{user.email ?? "N/A"}</span>
            </div>
            <div className="flex items-center text-primary gap-3">
              <PhoneOutline />
              <span className="text-xs">{user.phoneNo ?? "N/A"}</span>
            </div>
          </div>
          <button
            className="text-primary text-xs py-1.5 px-6 bg-white border border-[#DDDDDD] rounded"
            onClick={() => navigate("/settings")}
          >
            Edit
          </button>
        </div>

        {/* Resume Section */}
        <div className="mb-8 ml-16">
          <h3 className="font-bold mb-5">Resume</h3>
          <div className="space-y-3">
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="resume"
                value="upload"
                checked={formData.resumeOption === "upload"}
                onChange={(e) =>
                  setFormData({ ...formData, resumeOption: e.target.value })
                }
                className="mr-2"
                style={{ accentColor: "#2E2E2E" }}
              />
              Upload a resume
            </label>
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="resume"
                value="select"
                checked={formData.resumeOption === "select"}
                onChange={(e) =>
                  setFormData({ ...formData, resumeOption: e.target.value })
                }
                className="mr-2 custom-radio"
                style={{ accentColor: "#2E2E2E" }}
              />
              Select a resume
            </label>
            {formData.resumeOption === "select" && (
              <select className="w-5/6 p-2 border border-[#DDDDDD] rounded text-light-grey text-xs font-medium ml-5">
                <option value="">Select resume</option>
              </select>
            )}
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="resume"
                value="none"
                checked={formData.resumeOption === "none"}
                onChange={(e) =>
                  setFormData({ ...formData, resumeOption: e.target.value })
                }
                className="mr-2 custom-radio"
                style={{ accentColor: "#2E2E2E" }}
              />
              Don't send resume
            </label>
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="comtracked"
                className="mr-2"
                style={{ accentColor: "#2E2E2E" }}
              />
              Don't send COMTRACKED profile
            </label>
          </div>
        </div>

        {/* Cover Letter Section */}
        <div className="ml-16">
          <h3 className="font-bold mb-5">Cover letter</h3>
          <div className="space-y-3">
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="coverLetter"
                value="upload"
                checked={formData.coverLetterOption === "upload"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    coverLetterOption: e.target.value,
                  })
                }
                className="mr-2 custom-radio"
                style={{ accentColor: "#2E2E2E" }}
              />
              Upload a cover letter
            </label>
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="coverLetter"
                value="write"
                checked={formData.coverLetterOption === "write"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    coverLetterOption: e.target.value,
                  })
                }
                className="mr-2 custom-radio"
                style={{ accentColor: "#2E2E2E" }}
              />
              Write a cover letter
            </label>
            <label className="flex items-center text-primary text-xs font-medium">
              <input
                type="radio"
                name="coverLetter"
                value="none"
                checked={formData.coverLetterOption === "none"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    coverLetterOption: e.target.value,
                  })
                }
                className="mr-2 custom-radio"
                style={{ accentColor: "#2E2E2E" }}
              />
              Don't include a cover letter
            </label>
          </div>
        </div>
      </div>
      <div className="bg-[#F1F5F9] py-6 px-5 space-y-8 rounded-lg w-2/5 h-3/5 overflow-y-hidden">
        <div className="text-primary space-y-2.5 text-sm">
          <p className="">Applying for</p>
          <p className="font-semibold">{jobDetails?.title}</p>
          <p className="underline text-xs cursor-pointer">
            View job description
          </p>
        </div>
        <div className="flex flex-col gap-3.5 mb-6">
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">Location:</span>
            <span className="font-medium text-primary text-xs flex-1">
              {jobDetails?.location}
            </span>
          </div>
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">
              Industry Type:
            </span>
            <span className="font-medium text-primary text-xs flex-1">
              {jobDetails?.industryType}
            </span>
          </div>
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">Job Type:</span>
            <span className="font-medium text-primary text-xs flex-1">
              {job}
            </span>
          </div>
          <div className="flex gap-5">
            <span className="text-xs text-light-black w-28">Salary Range:</span>
            <span className="font-medium text-primary text-xs flex-1">
              £{jobDetails?.salary.min} - £{jobDetails?.salary.max}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseDocument;

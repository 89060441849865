import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../assets/svg/DashboardIcon";
import AssessmentIcon from "../../assets/svg/AssessmentIcon";
import TransactionIcon from "../../assets/svg/TransactionIcon";
import ContactsIcon from "../../assets/svg/ContactsIcon";
import CalenderIcon from "../../assets/svg/CalenderIcon";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ProfileIcon from "../../assets/svg/ProfileIcon";
import MyContactsIcon from "../../assets/svg/MyContactsIcon";
import AnswersIcon from "../../assets/svg/AnswersIcon";
import Divider from "../Divider/Divider";
import CareerIcon from "../../assets/svg/CareerIcon";
import { isNullOrEmpty } from "../../utils/helperFunction";
import JobTrackIcon from "../../assets/svg/JobTrackIcon";
import MembersIcon from "../../assets/svg/MembersIcon";
import SearchIcon from "../../assets/svg/SearchIcon";
import { userTypes } from "../../utils/constants";
import NetworkIcon from "../../assets/svg/Network";
import AccountSettingIcon from "../../assets/svg/AccountSettingIcon";
import PaymentIcon from "../../assets/svg/PaymentIcon";
import MatchesIcon from "../../assets/svg/MatchesIcon";

const Sidebar = () => {
  const pathname = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const noCreateProfile = pathname !== "/create-profile";

  console.log('====================================');
  console.log('self user',user);
  console.log('====================================');

  const recruiterRoutes = [
    // {
    //   label: "Dashboard",
    //   route: "/dashboard",
    //   icon: <DashboardIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    // },
    {
      label: "Search",
      route: "/search",
      icon: <SearchIcon className={"w-5 h-5"} fill="#4d4d4d" />,
    },
    {
      label: "Jobs",
      route: "/job",
      icon: <CareerIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    // {
    //   label: "Profile Page",
    //   route: "/profile",
    //   icon: <ProfileIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    // },
    {
      label: "Screening",
      route: "/my-assessments",
      icon: <AssessmentIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Contacts",
      route: "/contacts",
      icon: <ContactsIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Calendar",
      route: "/calendar",
      icon: <CalenderIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Account",
      route: "/account",
      icon: <MembersIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Transactions",
      route: "/transactions",
      icon: <TransactionIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
  ];

  const candidateRoutes = [
    {
      label: "Job Search",
      route: "/job-search",
      icon: <DashboardIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Job Track",
      route: "/job-track",
      icon: <JobTrackIcon className={"w-3.8 h-3.8"} fill="#4d4d4d" />,
    },
    {
      label: "My Assessment",
      route: "/my-assessments",
      icon: <AssessmentIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "My Profile",
      route: "/my-profile",
      icon: <ProfileIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "My Contacts",
      route: "/my-contacts",
      icon: <MyContactsIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Transactions",
      route: "/transactions",
      icon: <TransactionIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Answers",
      route: "/answers",
      icon: <AnswersIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Settings",
      route: "/settings",
      icon: <AccountSettingIcon fill="#4d4d4d" />,
    },
  ];

  const referralAmbassadorRoutes = [
    {
      label: "Network",
      route: "/network",
      icon: <NetworkIcon fill="#4d4d4d" />,
    },
    {
      label: "Matches",
      route: "/matches",
      icon: <MatchesIcon fill="#4d4d4d" />,
    },
    {
      label: "Payment",
      route: "/payment",
      icon: <PaymentIcon fill="#4d4d4d" />,
    },
    // {
    //   label: "Matches 2",
    //   route: "/matches-2",
    //   icon: <MatchesIcon fill="#4d4d4d" />,
    // },
    {
      label: "Account Settings",
      route: "/account",
      icon: <AccountSettingIcon fill="#4d4d4d" />,
    },
  ];

  const routes =
    user?.userType === userTypes.RECRUITER
      ? recruiterRoutes
      : user?.userType === userTypes.CANDIDATE
      ? candidateRoutes
      : referralAmbassadorRoutes;

  const showProfileToolTips = useMemo(() => {
    if (isNullOrEmpty(user)) {
      return false;
    }
    return ["jobTitle", "location", "about", "phoneNo", "address"].every(
      (key) => {
        return isNullOrEmpty(user[key]);
      }
    );
  }, [user]);

  return (
    <>
      {/* Mobile menu button */}
      <button
        className="xl:hidden fixed top-4 left-4 z-20"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? "Close" : "Menu"}
      </button>

      {/* Sidebar */}
      <div
        className={`xl:static top-0 left-0 h-full bg-white z-10 transition-all duration-300 ease-in-out
                    ${isOpen ? "w-48" : "w-16 xl:w-48"}`}
      >
        <div
          className={`flex-col ${noCreateProfile ? "flex" : "hidden"} ${
            isOpen ? "w-48" : "w-16 pl-4 xl:w-48"
          } gap-3 pt-10 pb-5`}
        >
          {routes?.map((route) => {
            // const isActive = pathname.includes(route?.route);
            const isActive =
              typeof pathname === "string" &&
              typeof route?.route === "string" &&
              (pathname === route.route ||
                pathname.startsWith(`${route.route}/`));

            return (
              <>
                {((user?.userType === 3 && route?.label === "Answers") ||
                  (user?.userType === 2 && route?.label === "Account") ||
                  (user?.userType === 4 &&
                    route?.label === "Account Settings")) && (
                  <Divider className="bg-[#DDDDDD] " />
                )}
                <Link
                  to={route?.route}
                  key={route.label}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className={`flex items-center pr-4 xl:pr-0 xl:justify-start relative gap-2 rounded-lg ${
                    isActive ? "bg-[#F2F2F2]" : ""
                  } py-2.5 pl-2`}
                >
                  <div className="w-3.5">{route?.icon}</div>
                  <label className="font-medium text-xs cursor-pointer text-[#4D4D4D] hidden xl:block">
                    {route?.label}
                  </label>
                  <div
                    className={`w-2 h-full right-0 absolute ${
                      isActive ? "bg-secondary" : "white"
                    } rounded-l`}
                  />
                  {showProfileToolTips && route?.route === "/my-profile" && (
                    <div className="absolute top-[-2px] left-7 xl:left-[60%] transform mb-2 whitespace-pre bg-gray-700 font-montserrat font-medium text-xs text-grey-100 rounded px-2 py-1 shadow-lg z-10">
                      Complete your profile to{"\n"}see more relevant jobs.
                    </div>
                  )}
                </Link>
              </>
            );
          })}
        </div>
      </div>

      {/* Overlay for mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-5 xl:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Sidebar;

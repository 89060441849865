import * as React from "react";
const FilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#2E2E2E"
      d="M16.667 4.167a.833.833 0 0 1 0 1.666H3.333a.833.833 0 1 1 0-1.666h13.334Zm0 3.333a.833.833 0 0 1 0 1.667h-10a.833.833 0 1 1 0-1.667h10Zm.833 4.167a.833.833 0 0 0-.833-.834H10a.833.833 0 0 0 0 1.667h6.667a.833.833 0 0 0 .833-.833Zm-.833 2.5a.833.833 0 0 1 0 1.666h-3.334a.833.833 0 0 1 0-1.666h3.334Z"
    />
  </svg>
);
export default FilterIcon;

import { useForm } from "react-hook-form";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import CustomInput from "../../components/CustomInput/CustomInput";
import EmailSuccess from "../../assets/svg/EmailSuccess";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  inviteNewReferral,
  verifyReferralInvite,
} from "../../endpoints/referral.service";
import { useNavigate } from "react-router-dom";

const AddReferralPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const emailValue = watch("email");

  useEffect(() => {
    if (hasSearched) {
      setHasSearched(false);
      setUserData(userData);
    }
  }, [emailValue]);

  const onSubmit = async (formData) => {
    if (hasSearched && !userData) {
      await sendInvite(false);
      return;
    }

    if (userData) {
      // If userData exists, send invite for existing user
      await sendInvite(true);
      return;
    }

    setLoading(true);
    setHasSearched(true);

    try {
      const { data, success } = await verifyReferralInvite(formData.email);
      if (success && data && data.length > 0) {
        setUserData(data);
      } else {
        setUserData(null);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        await sendInvite(false);
      }
    } catch (error) {
      setUserData(null);
    } finally {
      setLoading(false);
    }
  };

  const sendInvite = async (referralAlreadyExists) => {
    setLoading(true);
    try {
      let params = {};

      if (referralAlreadyExists && userData && userData[0]) {
        params = {
          invitedBy: userData[0]._id,
          referralAlreadyExists: true,
        };
      } else {
        params = {
          referralEmail: getValues("email"),
          referralAlreadyExists: false,
        };
      }

      const success = await inviteNewReferral(params);

      if (success) {
        setInviteSent(true);
        if (userData) navigate("/login");
      } else {
        enqueueSnackbar("Failed to send invitation", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getButtonText = () => {
    if (userData) return "Confirm & Next";
    if (hasSearched && !userData) return "Send Invite";
    return "Next";
  };

  if (inviteSent) {
    return (
      <div className="flex flex-col self-center justify-center h-full w-6/12">
        <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg w-full py-10 px-20 relative bg-white self-center justify-self-center">
          <h3 className="text-primary font-semibold text-4xl">
            Invitation Sent!
          </h3>
          <EmailSuccess className={"w-14 h-14 my-4"} />
          <p className="text-primary font-medium">
            The invitation has been sent to the email address
          </p>
          <p className="text-primary font-semibold">{getValues("email")}</p>

          <button
            className="bg-secondary flex items-center justify-center rounded-full mt-10 py-3 w-full"
            onClick={() => navigate("/login")}
          >
            <span className="text-white font-medium">
              Login to your account
            </span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col self-center justify-center h-full w-6/12">
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg w-full py-10 px-20 relative bg-white self-center justify-self-center">
        <label className="font-semibold text-3xl mb-2">Add Referral</label>
        <label className="text-light-black font-medium px-10 text-center">
          Enter email address of your referral member.
        </label>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full pt-10 overflow-y-auto no-scrollbar h-max gap-5"
        >
          {!userData && (
            <div className="flex flex-col items-start gap-2">
              <CustomInput
                placeholder={"Enter email address"}
                name="email"
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                control={control}
                errors={errors}
              />
            </div>
          )}

          {userData ? (
            <div className="flex flex-col items-center">
              <div className="w-28 h-28 rounded-full justify-center items-center flex bg-[#E1E0E0] shadow-md mb-5">
                {userData[0].profilePic ? (
                  <img
                    src={userData[0].profilePic}
                    alt="profile img"
                    className="h-full w-full object-cover rounded-full"
                  />
                ) : (
                  <span className="font-montserrat text-white font-semibold text-xl">
                    {userData[0]?.firstName?.charAt(0)}
                    {userData[0]?.lastName?.charAt(0)}
                  </span>
                )}
              </div>

              <p className="text-primary font-bold text-xl mb-1.5">
                {userData[0].firstName} {userData[0].lastName}
              </p>
              <p className="text-primary font-semibold">{userData[0].email}</p>
            </div>
          ) : (
            hasSearched &&
            !loading && (
              <div className="flex flex-col items-center gap-2.5 mt-5">
                <p className="text-[#4D4D4D] font-bold text-xs">
                  No matches found!
                </p>
                <p className="text-[#4D4D4D] text-xs">
                  You can send the invite to the referral member.
                </p>
              </div>
            )
          )}

          <div className="flex gap-5">
            <button
              type="button"
              className="border-secondary border flex items-center justify-center rounded-full mt-10 py-3 w-full"
              onClick={() => navigate("/login", { replace: true })}
            >
              <span className="text-secondary font-medium">Skip</span>
            </button>
            <button
              type="submit"
              className={`flex items-center justify-center rounded-full mt-10 py-3 w-full ${
                isValid || userData
                  ? "bg-secondary"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              disabled={(!isValid && !userData) || loading}
            >
              {loading ? (
                <LoadingAnimation className="w-6 h-6 mr-0" />
              ) : (
                <span className="text-white font-medium">
                  {getButtonText()}
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReferralPage;

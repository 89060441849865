import { Link } from "react-router-dom";
import { Input } from "../../../MatchDetailPage/MatchDetailPage";
import moment from "moment";

const SkillsMatchTab = ({ applicationDetails }) => {
  return (
    <div className="flex flex-col gap-2.5 overflow-auto no-scrollbar w-full">
      {/* Top Header */}
      <div className="flex items-center w-full gap-5 cursor-pointer">
        <Link
          to={"#"}
          className="flex py-2 px-4 bg-[#F1F5F9] gap-4 flex-1 justify-between h-14 rounded"
        >
          <div className="flex items-center gap-4">
            <div className="w-10 h-10 rounded-full justify-center items-center flex bg-[#E1E0E0] shadow-md">
              {applicationDetails?.candidateDetails?.profilePic ? (
                <img
                  src={applicationDetails?.candidateDetails?.profilePic}
                  className="h-full w-full object-cover rounded-full"
                  alt="profileImg"
                />
              ) : (
                <span className="font-montserrat text-white font-semibold text-xl">
                  {applicationDetails?.candidateDetails?.firstName?.charAt(0)}
                  {applicationDetails?.candidateDetails?.lastName?.charAt(0)}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-between gap-1.5">
              <span className="font-medium text-primary text-xs capitalize">
                {applicationDetails.candidateDetails.firstName}{" "}
                {applicationDetails.candidateDetails.lastName}
              </span>
              <span className="text-primary text-xs">
                {applicationDetails.candidateDetails.jobTitle}
              </span>
            </div>
          </div>
          <span className="font-medium text-green-700 text-xs pr-12">
            {applicationDetails.candidateDetails.status}
          </span>
        </Link>
        <span className="text-xs border-b pb-2 font-medium italic text-light-black">
          100% matched
        </span>
        <div className="flex py-2 px-4 bg-[#F1F5F9] gap-4 flex-1 justify-between h-[3.4rem] rounded">
          <div className="flex flex-col justify-between">
            <span className="font-medium text-light-black text-[10.5px]">
              {applicationDetails.jobDetails.refNo}
            </span>
            <span className="font-medium text-primary text-xs">
              {applicationDetails.jobDetails.title}
            </span>
          </div>
          <div className="flex items-center gap-6 pr-8">
            <div className="flex flex-col justify-between">
              <span className="font-medium text-primary text-xs">Post</span>
              <span className="font-medium text-primary text-xs">Matched</span>
            </div>
            <div className="flex flex-col justify-between">
              <span className="font-medium text-xs italic text-light-black">
                {moment(applicationDetails.jobDetails.publishedDate).format(
                  "DD/MM/YY"
                )}
              </span>
              <span className="font-medium text-xs italic text-light-black">
                21/03/25
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-9">
        <div className="flex items-center w-full gap-32">
          <label className="font-semibold text-sm text-light-black flex-1">
            Candidate Skills
          </label>
          <label className="font-semibold text-sm text-light-black flex-1">
            Matched Skills
          </label>
        </div>

        <div className="flex mt-3 gap-32">
          <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
            <span className="font-semibold text-xs text-primary">Skill</span>
            <span className="font-semibold text-xs text-primary">Rating</span>
            <span className="font-semibold text-xs text-primary">Years</span>
            <span className="font-semibold text-xs text-primary">When</span>
            <div />
          </div>
          <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
            <span className="font-semibold text-xs text-primary">Skill</span>
            <span className="font-semibold text-xs text-primary">Rating</span>
            <span className="font-semibold text-xs text-primary">Years</span>
            <span className="font-semibold text-xs text-primary">When</span>
            <div />
          </div>
        </div>
        {/* Skills List */}
        <div className="flex flex-col gap-2.5">
          {[1, 2, 3, 4, 5, 6, 7].map((skill) => {
            return (
              <div className="flex items-center">
                <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                  <Input readOnly={true} type="skill" value="Javascript" />
                  <Input
                    readOnly={true}
                    type="rating"
                    placeholder="Rating"
                    value="5"
                  />
                  <Input readOnly={true} placeholder="Years" value="5" />
                  <Input
                    readOnly={true}
                    type="skillUsed"
                    placeholder="When last used"
                    value="current"
                  />
                </div>
                <div className="w-72 flex items-center justify-center">
                  <span className="text-xs border-b pb-2 font-medium italic text-light-black">
                    100% matched
                  </span>
                </div>
                <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                  <Input readOnly={true} type="skill" value="Javascript" />
                  <Input
                    readOnly={true}
                    type="rating"
                    placeholder="Rating"
                    value="5"
                  />
                  <Input readOnly={true} placeholder="Years" value="5" />
                  <Input
                    readOnly={true}
                    type="skillUsed"
                    placeholder="When last used"
                    value="current"
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* Non matched skills */}
        {/* {[1, 2, 3, 4, 5, 6, 7].length ? (
          <div className="flex flex-col w-[43.5%] border-t-2 ml-auto mt-6 pt-4">
            <label className="font-semibold text-sm text-light-black flex-1">
              Non-matched Skills
            </label>
            <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2 mt-3">
              <span className="font-semibold text-xs text-primary">Skill</span>
              <span className="font-semibold text-xs text-primary">Rating</span>
              <span className="font-semibold text-xs text-primary">Years</span>
              <span className="font-semibold text-xs text-primary">When</span>
              <div />
            </div>
            {[1, 2, 3, 4, 5, 6, 7].map((skill) => {
              return (
                <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                  <Input readOnly={true} type="skill" value="Javascript" />
                  <Input
                    readOnly={true}
                    type="rating"
                    placeholder="Rating"
                    value="5"
                  />
                  <Input readOnly={true} placeholder="Years" value="5" />
                  <Input
                    readOnly={true}
                    type="skillUsed"
                    placeholder="When last used"
                    value="current"
                  />
                </div>
              );
            })}
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default SkillsMatchTab;
